/**
 * # Button Burger
 */

/**
 * ## Imports
 */
import React from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './ButtonBurger.module.scss';

/**
 * ## Types / Interfaces
 */
type ButtonBurgerProps = {
    onClick: () => void;
    opened: boolean;
}

/**
 * ## Component
 */
const ButtonBurger: React.FunctionComponent<ButtonBurgerProps> = function ButtonBurger(
    {
        onClick,
        opened,
    },
) {
    /**
     * ### Click Handler
     */
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        onClick();
        event.stopPropagation();
    };

    const buttonClassName: ClassValue = clsx(
        styles['burger'],
        {
            [styles['burger--open']]: opened,
        },
    );

    /**
     * ### JSX
     */
    return (
        <button
            className={buttonClassName}
            onClick={handleClick}
            type="button"
        >
            <span className={styles['burger__body']}>
                {!opened ? 'Open Menu' : 'Close Menu'}
            </span>
        </button>
    );
};

export default ButtonBurger;
