/**
 * # Get Privacy Content
 */
/**
 * ## Imports
 */
import { ClientName } from 'client/clientConfig';

/**
 * ## Output
 */
// eslint-disable-next-line
const GetPrivacyContent = () => {
    const isSonicClient = ClientName === 'sonic';
    const isAdblockClient = ClientName === 'adblock';

    const browserName = isSonicClient ? 'Sonic' : 'Adblock';

    return (isSonicClient || isAdblockClient) ? (
        <>
            <p className="policy-intro-text">We respect your privacy and are committed to protecting it through our compliance with this
                privacy policy (“Policy”). This Policy describes the types of information we may collect from you
                or that you may provide (“Personal Information”) on the {browserName} Browser web browser (“{browserName}
                Browser”, or “Service”) and any and all related information, resources, tools, products, and
                services (including the www.sonic-browser.com website, collectively, “Services”), and our
                practices for collecting, using, maintaining, protecting, and disclosing that Personal Information.
                It also describes the choices available to you regarding our use of your Personal Information and
                how you can access and update it.
            </p>

            <p className="policy-intro-text">This Policy is a legally binding agreement between you (“User”, “you” or “your”) and {browserName}
                Browser LLC (“{browserName} Browser”, “we”, “us” or “our”). If you are entering into this agreement on
                behalf of a business or other legal entity, you represent that you have the authority to bind such
                entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity.
                If you do not have such authority, or if you do not agree with the terms of this agreement, you
                must not accept this agreement and may not access and use the Services. By accessing and using
                the Services, you acknowledge that you have read, understood, and agree to be bound by the terms
                of this Policy. This Policy does not apply to the practices of companies that we do not own or
                control, or to individuals that we do not employ or manage.
            </p>

            <h3 className="headline-exception-secondary">Information We Collect Automatically</h3>

            <p>When you open the Services, our servers automatically record information that your browser
                sends. This data may include information such as your device&#x2019;s IP address, browser type, and
                version, operating system type and version, language preferences or the webpage you were visiting
                before you came to the Services, pages of the website and Services that you visit, the time spent
                on those pages, information you search for on the Services, access times and dates, and other
                statistics.
            </p>

            <p>Information collected automatically is used only to identify potential cases of abuse and establish
                statistical information regarding the usage and traffic of the Services. This statistical information
                is not otherwise aggregated in such a way that would identify any particular user of the system.
            </p>

            <h3 className="headline-exception-secondary">Personal Information We Collect</h3>

            <p>None of your Personal Information is needed to use our Services. You can access and use the
                Services without telling us who you are or revealing any information by which someone could
                identify you as a specific, identifiable individual.
            </p>

            <p>If, however, you wish to use some of the features that may be offered on the Services, you may be
                asked to provide certain Personal Information that may include the following:
            </p>
            <ul className="copy-list">
                <li className="copy-list__item">Account details (such as user name, unique user ID, password, etc.)</li>
                <li className="copy-list__item">Contact information (such as email address, phone number, etc.)</li>
                <li className="copy-list__item">Basic personal information (such as name, country of residence, etc.)</li>
                <li className="copy-list__item">Proof of identity (such as a photocopy of a government ID)</li>
                <li className="copy-list__item">Any other materials you willingly submit to us (such as articles, images, feedback, etc.)</li>
            </ul>

            <p>Some of the information we collect is directly from you via the Services. However, we may also
                collect Personal Information about you from other sources such as public databases, social media
                platforms, third-party data providers, and our joint marketing partners. Personal Information we
                collect from other sources may include demographic information, such as age and gender, device
                information, such as IP addresses, location, such as city and state, and online behavioral data, such
                as information about your use of social media websites, page view information and search results
                and links.
            </p>

            <p>You can choose not to provide us with your Personal Information, but then you may not be able to
                take advantage of some of the Services features. If you are uncertain about what information is
                mandatory, you are welcome to contact us.
            </p>

            <h3 className="headline-exception-secondary">Search Providers</h3>
            <p>The Services allow you to customize the search engine used in the browser address bar. In most
                cases Google is the default provider, but other providers (such as Bing) might be set as default
                based on which version of the Services you are using. You can always change your default provider
                in the settings menu. We do not share personal data with search engine providers to enable searches
                via the Services.
            </p>

            <p>Like any other third-party website you might access, use of any third-party website search engine
                is subject to the terms of service and privacy policy offered by the specific search engine provider.
                For information on the web search services data collection, please visit the search provider&apos;s
                privacy policy.
            </p>

            <h3 className="headline-exception-secondary">Services Provided by Third Parties</h3>
            <p>Some of the web services provided herein are provided by third parties. For further information on data collection please visit the search provider’s privacy policy, as applicable. </p>
            <ul className="copy-list">
                <li className="copy-list__item">
                    <a href="https://system1.com/terms/privacy-policy" target="_blank" rel="noopener noreferrer">https://system1.com/terms/privacy-policy</a>
                </li>
                <li className="copy-list__item">
                    <a href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank" rel="noopener noreferrer">https://privacy.microsoft.com/en-us/privacystatement</a>
                </li>
                <li className="copy-list__item">
                    <a href="https://www.ironsrc.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.ironsrc.com/privacy-policy</a>
                </li>
                <li className="copy-list__item">
                    <a href="https://yandex.com/company/privacy" target="_blank" rel="noopener noreferrer">https://yandex.com/company/privacy</a>
                </li>
                <li className="copy-list__item">
                    <a href="https://www.veve.com/privacy-policy.php" target="_blank" rel="noopener noreferrer">https://www.veve.com/privacy-policy.php </a>
                </li>
                <li className="copy-list__item">
                    <a href="https://shopnomix.com/advertiser-self-service/" target="_blank" rel="noopener noreferrer">https://shopnomix.com/advertiser-self-service/ </a>
                </li>
                <li className="copy-list__item">
                    <a href="https://couponfollow.com/partners/privacy-policy" target="_blank" rel="noopener noreferrer">https://couponfollow.com/partners/privacy-policy </a>
                </li>
            </ul>

            <h3 className="headline-exception-secondary">Information Provided by Third Parties</h3>
            <p>We may receive information about you and your Internet activities if you have provided it to a
                third party (each, a “Third Party”) and we subsequently acquire the information from the Third Party.
                Such information may include, but is not limited to, your personal and online interests, and Internet browsing habits.
                These Third Parties may have collected such information from you directly (such as when you registered with their websites)
                or by placing Cookies on your device, using web beacons, gathering IP addresses and log data, and so forth.
            </p>
            <p>The purpose of this practice, which is sometimes called “retargeting,” is to deliver content and advertising that is specific
                to your interests.  For example, if you look at a particular Product on the Website, you may later see that same Product advertised
                to you on Facebook. You can better learn about such Third-Party data collection and advertising practices, including how to opt-out
                of retargeting, at the following websites:
            </p>
            <ul className="copy-list">
                <li className="copy-list__item">
                    <a href="http://www.youronlinechoices.com/uk/your-ad-choices" target="_blank" rel="noopener noreferrer">http://www.youronlinechoices.com/uk/your-ad-choices</a>
                </li>
                <li className="copy-list__item">
                    <a href="http://www.networkadvertising.org/choices" target="_blank" rel="noopener noreferrer">http://www.networkadvertising.org/choices</a>
                </li>
                <li className="copy-list__item">
                    <a href="http://www.aboutads.info/choices" target="_blank" rel="noopener noreferrer">http://www.aboutads.info/choices</a>
                </li>
            </ul>

            <h3 className="headline-exception-secondary">Privacy of Children</h3>
            <p>We do not knowingly collect any Personal Information from children under the age of 18. If you
                are under the age of 18, please do not submit any Personal Information through the Services. If
                you have reason to believe that a child under the age of 18 has provided Personal Information to
                us through the Services, please contact us to request that we delete that child’s Personal
                Information from our Services.
            </p>

            <p>We encourage parents and legal guardians to monitor their cchildren&#x2019;s Internet usage and to help
                enforce this Policy by instructing their children never to provide Personal Information through the
                Services without their permission. We also ask that all parents and legal guardians overseeing the
                care of children take the necessary precautions to ensure that their children are instructed to never
                give out Personal Information when online without their permission.
            </p>

            <h3 className="headline-exception-secondary">Use and Processing of Collected Information</h3>
            <p>In the event we handle any of your Personal Information, we act as a data controller and a data
                processor in terms of the GDPR unless we have entered into a data processing agreement with you
                in which case you would be the data controller and we would be the data processor.
            </p>

            <p>Our role may also differ depending on the specific situation involving your Personal Information.
                We act in the capacity of a data controller if/when we ask you to submit your Personal Information
                that is necessary to ensure your access and use of the Services. In such instances, we are a data
                controller because we determine the purposes and means of the processing of your Personal
                Information, and we comply with data controllers&#x2019; obligations set forth in the GDPR.
            </p>

            <p>We act in the capacity of a data processor in situations if/when you submit your Personal
                Information through the Services. We do not own, control, or make decisions about the submitted
                Personal Information, and such Personal Information is processed only in accordance with your
                instructions. In such instances, you as the user providing Personal Information acts as a data
                controller in terms of the GDPR.
            </p>

            <p>In order to make the Services available to you, or to meet a legal obligation, we may need to collect
                and use certain Personal Information. If you do not provide the information that we request, we
                may not be able to provide you with the Services. Any of the information we collect from you may
                be used for the following purposes:
            </p>

            <ul className="copy-list">
                <li className="copy-list__item">Create and manage user accounts</li>
                <li className="copy-list__item">Deliver products or services</li>
                <li className="copy-list__item">Improve products and services</li>
                <li className="copy-list__item">Send administrative information</li>
                <li className="copy-list__item">Send marketing and promotional communications</li>
                <li className="copy-list__item">Respond to inquiries and offer support</li>
                <li className="copy-list__item">Request user feedback</li>
                <li className="copy-list__item">Improve user experience</li>
                {/* eslint-disable-next-line */}
                <li className="copy-list__item">Post customer testimonials</li>
                <li className="copy-list__item">Deliver targeted advertising</li>
                <li className="copy-list__item">Enforce terms and conditions and policies</li>
                <li className="copy-list__item">Protect from abuse and malicious users</li>
                <li className="copy-list__item">Respond to legal requests and prevent harm</li>
                <li className="copy-list__item">Run and operate the Services</li>
            </ul>

            <p>Processing your Personal Information depends on how you interact with the Services, where you
                are located in the world and if one of the following applies: (i) you have given your consent for
                one or more specific purposes; this, however, does not apply, whenever the processing of Personal
                Information is subject to California Consumer Privacy Act or European data protection law; (ii)
                provision of information is necessary for the performance of an agreement with you and/or for any
                pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal
                obligation to which you are subject; (iv) processing is related to a task that is carried out in the
                public interest or in the exercise of official authority vested in us; (v) processing is necessary for
                the purposes of the legitimate interests pursued by us or by a third party.
            </p>

            <p>Note that under some legislations we may be allowed to process information until you object to
                such processing by opting out, without having to rely on consent or any other of the legal bases.
                In any case, we will be happy to clarify the specific legal basis that applies to the processing, and
                in particular whether the provision of Personal Information is a statutory or contractual
                requirement, or a requirement necessary to enter into a contract.
            </p>

            <h3 className="headline-exception-secondary">Managing Information</h3>
            <p>You are able to delete certain Personal Information we may have about you. The Personal
                Information you can delete may change as the Services change. When you delete Personal
                Information, however, we may maintain a copy of the unrevised Personal Information in our
                records for the duration necessary to comply with our obligations to our affiliates and partners,
                and for the purposes described below. If we have any of your Personal Information and you would
                like to delete your Personal Information or permanently delete your account, you can do so on the
                settings page of your account on the Services or simply by contacting us.
            </p>

            <h3 className="headline-exception-secondary">Disclosure of Information</h3>
            <p>Depending on the requested Services or as necessary and legitimate to complete any transaction
                or provide any Service you have requested, we may share your information with our affiliates,
                contracted companies, and service providers (collectively, “Service Providers”) we rely upon to
                assist in the operation of the Services available to you and whose privacy policies are consistent
                with ours or who agree to abide by our policies with respect to Personal Information. We will not
                share any personally identifiable information with third parties and will not share any information
                with unaffiliated third parties.
            </p>

            <p>Service Providers are not authorized to use or disclose your information except as necessary to
                perform services on our behalf or comply with legal requirements. Service Providers are given the
                information they need only in order to perform their designated functions, and we do not authorize
                them to use or disclose any of the provided information for their own marketing or other purposes.
            </p>

            <p>We may also disclose any Personal Information we collect, use or receive if required or permitted
                by law, such as to comply with a subpoena or similar legal process, and when we believe in good
                faith that disclosure is necessary to protect our rights, protect your safety or the safety of others,
                investigate fraud, or respond to a government request.
            </p>

            <h3 className="headline-exception-secondary">How Your Information is Retained</h3>
            <p>If your Personal Information is collected by us, we will retain and use your Personal Information
                for the period necessary to enforce our agreements, resolve disputes, and unless a longer retention
                period is required or permitted by law up to a maximum of 60 months.
            </p>

            <p>We may use any aggregated data derived from or incorporating your Personal Information after
                you update or delete it, but not in a manner that would identify you personally. Once the retention
                period expires, Personal Information shall be deleted. Therefore, the right to access, the right to
                erasure, the right to rectification, and the right to data portability cannot be enforced after the
                expiration of the retention period.
            </p>

            <h3 className="headline-exception-secondary">Will Your Information Be Transferred?</h3>
            <p>Depending on your location, data transfers may involve transferring and storing your information
                in a country other than your own.
            </p>

            <p>If you are a resident of the European Economic Area (“EEA”) or the United Kingdom (“UK”) and
                your data is transferred outside of the EEA or UK, we ensure at least one of the following
                safeguards is implemented:
            </p>

            <ul>
                <li className="copy-list__item">We will only transfer your personal data to countries that have been deemed to provide
                    an adequate level of protection for personal data by the European Commission. For further
                    details, see European Commission: Adequacy of the protection of personal data in non-EU
                    countries.
                </li>
                <li className="copy-list__item">Where we use certain service providers, we may use specific contracts approved by the
                    European Commission which give personal data the same protection it has in Europe. For
                    further details, see European Commission: Model contracts for the transfer of personal data
                    to third countries.
                </li>
                <li className="copy-list__item">Where we use providers based in the US, we may transfer data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between Europe and the US. For further details, see European Commission: EU-US Privacy Shield.
                </li>
            </ul>

            <p>Please contact us if you want further information on the specific mechanism used by us when transferring your personal data.</p>

            <h3 className="headline-exception-secondary">Data Protection Rights Under the GDPR</h3>
            <p>If you are a resident of the European Economic Area (“EEA”) or the United Kingdom (“UK”), you have certain data protection rights and we aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Information. If you wish to be informed of what Personal Information, we hold about you and if you want it to be removed from our systems, please contact us. In certain circumstances, you have the following data protection rights:</p>

            <ol className="copy-list copy-list--roman copy-list--box">
                <li className="copy-list__item">You have the right to withdraw consent where you have previously given your consent to the processing of your Personal Information. To the extent that the legal basis for our processing of your Personal Information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</li>
                <li className="copy-list__item">You have the right to learn if your Personal Information is being processed by us, obtain disclosure regarding certain aspects of the processing, and obtain a copy of your Personal Information undergoing processing.</li>
                <li className="copy-list__item">You have the right to verify the accuracy of your information and ask for it to be updated or corrected. You also have the right to request us to complete the Personal Information you believe is incomplete.</li>
                <li className="copy-list__item">You have the right to object to the processing of your information if the processing is carried out on a legal basis other than consent. Where Personal Information is processed for the public interest, in the exercise of an official authority vested in us, or for the purposes of the legitimate interests pursued by us, you may object to such processing by providing a ground related to your particular situation to justify the objection. You must know that, however, should your Personal Information be processed for direct marketing purposes, you can object to that processing at any time without providing any justification. To learn whether we are processing Personal Information for direct marketing purposes, you may refer to the relevant sections of this Policy.</li>
                <li className="copy-list__item">You have the right, under certain circumstances, to restrict the processing of your Personal Information. These circumstances include: the accuracy of your Personal Information is contested by you and we must verify its accuracy; the processing is unlawful, but you oppose the erasure of your Personal Information and request the restriction of its use instead; we no longer need your Personal Information for the purposes of processing, but you require it to establish, exercise or defend your legal claims; you have objected to processing pending the verification of whether our legitimate grounds override your legitimate grounds. Where processing has been restricted, such Personal Information will be marked accordingly and, with the exception of storage, will be processed only with your consent or for the establishment, to exercise or defense of legal claims, for the protection of the rights of another natural, or legal person or for reasons of important public interest.</li>
                <li className="copy-list__item">You have the right, under certain circumstances, to obtain the erasure of your Personal Information from us. These circumstances include: the Personal Information is no longer necessary in relation to the purposes for which it was collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are exclusions of the right to erasure such as where processing is necessary: for exercising the right of freedom of expression and information; for compliance with a legal obligation; or for the establishment, to exercise or defense of legal claims.</li>
                <li className="copy-list__item">You have the right to receive your Personal Information that you have provided to us in a structured, commonly used, and machine-readable format and, if technically feasible, to have it transmitted to another controller without any hindrance from us, provided that such transmission does not adversely affect the rights and freedoms of others.</li>
                <li className="copy-list__item">You have the right to complain to a data protection authority about our collection and use of your Personal Information. If you are not satisfied with the outcome of your complaint directly with us, you have the right to lodge a complaint with your local data protection authority. For more information, please contact your local data protection authority in the EEA. This provision is applicable provided that your Personal Information is processed by automated means and that the processing is based on your consent, on a contract which you are part of, or on pre-contractual obligations thereof.</li>
            </ol>

            <h3 className="headline-exception-secondary">California Privacy Rights</h3>
            <p>Consumers residing in California are afforded certain additional rights with respect to their Personal Information under the California Consumer Privacy Act (“CCPA”) and the California Privacy Rights Act of 2020 (collectively referred to as the “CPRA”). If you are a California resident, this section applies to you, and you have the right to:</p>

            <ul className="copy-list">
                <li className="copy-list__item">know the categories of Personal Information collected about you in the prior 12 months and its sources and business purpose;</li>
                <li className="copy-list__item">know whether your Personal Information is sold or disclosed, and to whom, in prior 12 months;</li>
                <li className="copy-list__item">opt out of the sale or sharing of your Personal Information;</li>
                <li className="copy-list__item">access and then delete your Personal Information (subject to exceptions);</li>
                <li className="copy-list__item">equal service and price (non-discrimination) if you exercise your privacy rights;</li>
                <li className="copy-list__item">limit the use of your sensitive Personal Information; and</li>
                <li className="copy-list__item">correct your Personal Information.</li>
            </ul>

            <p>Under the CPRA, “Personal Information” is defined to include information that identifies, relates to, describes, or is capable of being associated with a particular consumer or household. This includes (among other types of personal information) IP addresses, geolocation data, biometric information, and “unique identifiers” such as device and cookie IDs, Internet activity information like browsing history, commercial information such as products or services purchased or consuming histories or tendencies, and characteristics concerning an individual’s race, color, sex (including pregnancy, childbirth, and related medical conditions), age (40 or older), religion, genetic information, sexual orientation, political affiliation, national origin, disability or citizenship status. Inferences drawn from personal information “to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, preferences, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes” are also considered personal information.</p>

            <p>To exercise your rights under the CPRA, simply contact us as detailed below. We will not discriminate against you if you exercise your rights under the CPRA.</p>

            <h3 className="headline-exception-secondary">How to Exercise Your Rights</h3>
            <p>Any requests to exercise your rights can be directed to:</p>
            <strong>Email Address: </strong>
            <br />
            <a href="mailto:info@sonicbrowser.com">info@sonicbrowser.com</a>

            <p><strong>Postal Address:</strong><br />{browserName} Browser LLC<br />1201 W Peachtree St NW, Suite 2625<br />Atlanta, GA 30309<br /></p>

            <p>Please note that we may ask you to verify your identity before responding to such requests. Your request must provide sufficient information that allows us to verify that you are the person you are claiming to be or that you are the authorized representative of such person. If we receive your request from an authorized representative, we may request evidence that you have provided such an authorized representative with power of attorney or that the authorized representative otherwise has valid written authority to submit requests on your behalf.</p>

            <p>You must include sufficient details to allow us to properly understand the request and respond to it. We cannot respond to your request or provide you with Personal Information unless we first verify your identity or authority to make such a request and confirm that the Personal Information relates to you.</p>

            <h3 className="headline-exception-secondary">Cookies</h3>
            <p>Our Services use “cookies” to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.</p>

            <p>We may use cookies to collect, store, and track information for security and personalization, and for statistical purposes. Please note that you have the ability to accept or decline cookies. Most web browsers automatically accept cookies by default, but you can modify your browser settings to decline cookies if you prefer. Please see our Cookie Policy at: www.sonic-browser.com/cookies to learn more about cookies and how they work.</p>

            <h3 className="headline-exception-secondary">Do Not Track Signals</h3>
            <p>Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, the Services are not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more detail throughout this Policy, we limit our use and collection of your Personal Information.</p>

            <h3 className="headline-exception-secondary">Advertisements</h3>
            <p>We may permit certain third-party companies to help us tailor advertising that we think may be of interest to users and to collect and use other data about user activities on the Services. These companies may deliver ads that might place cookies and otherwise track user behavior.</p>

            <h3 className="headline-exception-secondary">Social Media Features</h3>
            <p>Our Services may include social media features, such as the Facebook and Twitter buttons, Instagram and YouTube buttons, etc. (collectively, “Social Media Features”). These Social Media Features may collect your IP address, what page you are visiting on our Services, and may set a cookie to enable Social Media Features to function properly. Social Media Features are hosted either by their respective providers or directly on our Services. Your interactions with these Social Media Features are governed by the privacy policy of their respective providers.</p>

            <h3 className="headline-exception-secondary">Email Marketing</h3>
            <p>We may offer electronic newsletters to which you may voluntarily subscribe at any time. We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except as allowed in the information use and processing section or for the purposes of utilizing a third-party provider to send such emails. We will maintain the information sent via e-mail in accordance with applicable laws and regulations.</p>

            <p>In compliance with the CAN-SPAM Act and ePrivacy Directive, any e-mails sent from us will clearly state who the e-mail is from and provide clear information on how to contact the sender. If we send you newsletter or marketing material emails, that you have voluntarily subscribed to, you may choose to stop receiving those emails by following the unsubscribe instructions included in each email or by contacting us. However, you will continue to receive essential transactional emails.</p>

            <h3 className="headline-exception-secondary">Links to Other Resources</h3>
            <p>The Services may contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Services and to read the privacy statements of each and every resource that may collect Personal Information.</p>

            <h3 className="headline-exception-secondary">Information Security</h3>
            <p>We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.</p>

            <p>Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.</p>

            <p>As the security of Personal Information depends in part on the security of the device you use to communicate with us and the security you use to protect your credentials, please take appropriate measures to protect this information.</p>

            <h3 className="headline-exception-secondary">Data Breach</h3>
            <p>In the event we become aware that the security of the Services has been compromised or users’ Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach or if notice is otherwise required by law. When we do, we will post a notice on the Services, and/or send you an email.</p>

            <h3 className="headline-exception-secondary">Changes and Amendments</h3>
            <p>We reserve the right to modify this Policy or its terms related to the Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.</p>

            <p>An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.</p>

            <h3 className="headline-exception-secondary">Acceptance of this Policy</h3>
            <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Services.</p>

            <h3 className="headline-exception-secondary">Contacting Us</h3>
            <p>If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we encourage you to contact us using the details below:</p>

            <p><strong><a href="mailto:info@sonicbrowser.com">info@sonicbrowser.com</a></strong><br />{browserName} Browser LLC<br />1201 W Peachtree St NW, Suite 2625<br />Atlanta, GA 30309</p>
            <p>We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and, in any event, within the timescales provided by applicable data protection laws.</p>
        </>
    ) : null;
};

export default GetPrivacyContent;
