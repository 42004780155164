/**
 * # Set Title for Each Client
 */

/**
 * ## Types / Interfaces
 */
type setTitleProps = {
    title: string;
    client: string;
}

/**
 * ## Helper Function
 */
export const setTitle = ({ title }: setTitleProps): void => {
    document.title = title;

    const metaTag = document.createElement('meta');
    metaTag.setAttribute('property', 'og:title');
    metaTag.setAttribute('content', title);
    document.head.appendChild(metaTag);
};
