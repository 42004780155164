/**
 * # Page Header
 */

/**
 * ## Imports
 */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { isAndroid, isMobile, isTablet, osName } from 'react-device-detect';
import { CLIENT } from 'client/clientConfig';
import { useMobileMenu } from 'context/MobileMenuContext';
import { useDownload } from 'hooks/useDownload';
import { HOUDINI_DATA_EXPIRE_TIME } from 'lib/client/houdini';
import { ReactComponent as LogoIconSonic } from '../../images/sonic_browser/icons/logo-icon.svg';
import { ReactComponent as LogoIconAdblock } from '../../images/adblock_browser/icons/logo_icon.svg';
import { ReactComponent as LogoIconTempest } from '../../images/homepage_rebrand/icons/logo-icon.svg';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import MobileMenu from '../MobileMenu/MobileMenu';
import ButtonCta from '../ButtonCta';
import ButtonOptions from '../ButtonOptions/ButtonOptions';
import styles from './PageHeader.module.scss';
import ButtonBurger from '../ButtonBurger/ButtonBurger';

/**
 * ## Types / Interfaces
 */
type pageHeaderProps = {
    clientName: string;
    isMenuHidden?: boolean;
}

/**
 * ## Component
 */
const PageHeader = ({ clientName, isMenuHidden = false }: pageHeaderProps) => {
    /**
     * ### Variables
     */
    const location = useLocation();
    const isNewLayout = location?.state?.isNewLayout;
    const { isLoading, downloadBrowser } = useDownload(location?.search || '');
    const navigate = useNavigate();
    const isDownloadFeaturePage = location.pathname.includes('/download-windows-b');
    const isSonicClient = clientName === 'sonic';
    const isTempestClient = clientName === 'tempest';
    const isAdblockClient = clientName === 'adblock';
    const naviWidthThreshold = 768;

    const defaultHeaderLinks = [
        {
            route: '/', label: 'Home', isButton: false,
        },
    ];

    const sonicPageHeaderLinks = CLIENT.sonic.hasNavigationRoutes === true ? [
        {
            route: '/features', label: 'Features', isButton: false,
        },
        {
            route: '/about', label: 'About us', isButton: false,
        },
        {
            route: '/privacy', label: 'Privacy', isButton: false,
        },
        {
            route: '/customize', label: 'Customize', isButton: false,
        },
        {
            route: '/support', label: 'Support', isButton: false,
        },
        {
            route: '/download',
            label: 'Download Now',
            isButton: true,
        },
    ] : [];

    const adblockPageHeaderLinks = CLIENT.sonic.hasNavigationRoutes === true ? [
        {
            route: 'http://adblockbrowser.org/', label: 'Home', isButton: false,
        },
        {
            route: 'https://accounts.adblockplus.org/en/about', label: 'About us', isButton: false,
        },
        {
            route: 'https://blog.adblockplus.org/blog', label: 'Blog', isButton: false,
        },
        {
            route: '/support', label: 'Contact', isButton: false,
        },
        {
            route: '/customize', label: 'Customize', isButton: false,
        },
        {
            route: 'https://help.adblockplus.org/hc/en-us', label: 'Support', isButton: false,
        },
        {
            route: '/download',
            label: 'Download Adblock Browser',
            isButton: true,
        },
    ] : [];

    let Logo;
    switch (clientName) {
    case 'sonic':
        Logo = LogoIconSonic;
        break;
    case 'adblock':
        Logo = LogoIconAdblock;
        break;
    default:
        Logo = LogoIconTempest;
    }

    let pageHeaderLinks = defaultHeaderLinks;
    switch (clientName) {
    case 'sonic':
        pageHeaderLinks = sonicPageHeaderLinks;
        break;
    case 'adblock':
        pageHeaderLinks = adblockPageHeaderLinks;
        break;
    default:
        pageHeaderLinks = defaultHeaderLinks;
    }

    const buttonLabel = useCallback((label) => {
        if (isSonicClient) {
            return isMobile || isTablet || osName !== 'Windows' ? 'Coming soon' : label as any;
        }

        if (isAdblockClient) {
            if (isAndroid) {
                return label as any;
            }

            return isMobile || isTablet || !['Windows', 'Mac OS'].includes(osName) ? 'Coming soon' : label as any;
        }

        return label as any;
    }, [isSonicClient, isAdblockClient]);

    const isDisabled = useMemo(() => {
        if (isSonicClient) {
            return isMobile || isTablet || osName !== 'Windows';
        }

        if (isAdblockClient) {
            if (isAndroid) {
                return false;
            }

            return isMobile || isTablet || !['Windows', 'Mac OS'].includes(osName);
        }

        return true;
    }, [isSonicClient, isAdblockClient]);

    const handleDownloadButton = useCallback((route) => () => {
        if ((isSonicClient || isAdblockClient) && ['Windows', 'Mac OS'].includes(osName)) {
            navigate(route);
        } else {
            downloadBrowser();
        }
    }, [isSonicClient, isAdblockClient, downloadBrowser, navigate]);

    const renderPageHeaderLink = useCallback(({ route, label, isButton }: any) => {
        if (isButton) {
            return (
                <ButtonCta
                    clientName={clientName}
                    label={buttonLabel(label)}
                    onClick={handleDownloadButton(route)}
                    layoutMedium={isTempestClient}
                    isButtonDisabled={isLoading || isDisabled}
                />
            );
        }

        const linkComponent = window.location.pathname === route ? (
            <strong className={styles['active-point']}>{label}</strong>
        ) : (
            <Link
                to={route}
                rel={route.includes('http') ? 'noopener noreferrer' : ''}
                target={route.includes('http') ? '_blank' : '_self'}
                className={styles['link']}
            >
                {label}
            </Link>
        );

        return linkComponent;
    }, [clientName, isTempestClient, isLoading, isDisabled, buttonLabel, handleDownloadButton]);

    const desktopContent = useMemo(() => {
        if (isMenuHidden || isNewLayout) {
            return null;
        }

        if (isDownloadFeaturePage) {
            return <h1 className={styles['custom-header']}>The best features in one browser</h1>;
        }

        return pageHeaderLinks.length > 0 ? (
            <nav className={styles['nav']}>
                <h2 className="sr-only">Page Header</h2>
                <ul className={styles['list']}>
                    {pageHeaderLinks.map(({ route, label, isButton }) => (
                        <li key={route} className={styles['list-item']}>
                            {renderPageHeaderLink({ route, label, isButton })}
                        </li>
                    ))}
                </ul>
            </nav>
        ) : null;
    }, [isDownloadFeaturePage, pageHeaderLinks, renderPageHeaderLink, isMenuHidden, isNewLayout]);

    /**
     * ### State
     */
    const { isMobileMenuOpen, toggleMobileMenu, closeMobileMenu } = useMobileMenu();
    const [isViewportWiderThanThreshold, setIsViewportWiderThanThreshold] = useState<boolean>(window.innerWidth <= naviWidthThreshold);

    /**
     * ### Effects
    */
    /**
     * #### Toggling the correct menu state on resizing the browser window.
     */
    useEffect(() => {
        let timeout: NodeJS.Timeout;

        const handleResize = () => {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                setIsViewportWiderThanThreshold(window.innerWidth <= naviWidthThreshold);
            }, 300);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const pageHeaderElement = document.querySelector('.pageheader');

        function handleScroll() {
            if (window.scrollY > 0) {
                pageHeaderElement.classList.add(styles['pageheader--scrolled']);
            } else {
                pageHeaderElement.classList.remove(styles['pageheader--scrolled']);
            }
        }

        window.addEventListener('scroll', handleScroll, true);
        window.addEventListener('resize', handleScroll, true);
    });

    // TODO: there is no cleanups for the next sessions
    useEffect(() => {
        if (window?.location?.search) {
            const prevParams = localStorage.getItem('params');
            if (prevParams !== window?.location?.search) {
                localStorage.setItem('params', window?.location?.search);
                localStorage.setItem('paramsExpireTime', `${Date.now() + HOUDINI_DATA_EXPIRE_TIME}`);
            }
        }
    }, []);

    /**
     * ### Component's JSX
     */
    return (
        <div className={clsx(
            styles['pageheader'],
            'pageheader',
            {
                [styles['pageheader--sonic']]: isSonicClient,
                [styles['pageheader--adblock']]: isAdblockClient,
            },
        )}
        >
            <div className={clsx(
                styles['pageheader__body'],
                'centerwrapper',
            )}
            >
                <Link to="/">
                    <CompanyLogo
                        svgComponent={<Logo />}
                        width={154}
                        height={59}
                        clientName={clientName}
                    />
                </Link>
                {(isViewportWiderThanThreshold && pageHeaderLinks.length > 0) ? (
                    <div className={styles['mobile-menu-wrapper']}>
                        {isAdblockClient ? (
                            <ButtonBurger onClick={toggleMobileMenu} opened={isMobileMenuOpen} />
                        ) : (
                            <ButtonOptions onClick={toggleMobileMenu} opened={isMobileMenuOpen} />
                        )}
                        <MobileMenu
                            linkList={pageHeaderLinks}
                            hidden={!isMobileMenuOpen}
                            onClick={closeMobileMenu}
                        />
                    </div>
                ) : desktopContent}
            </div>
        </div>
    );
};

export default PageHeader;
