import { ReactComponent as SonicStarIcon } from 'common/images/sonic_browser/icons/star.svg';
import { ReactComponent as SonicStarHalfIcon } from 'common/images/sonic_browser/icons/star-half.svg';

import { ReactComponent as AdblockStarIcon } from 'common/images/adblock_browser/icons/star.svg';
import { ReactComponent as AdblockHalfStarIcon } from 'common/images/adblock_browser/icons/star-half.svg';

import { ClientName } from 'client/clientConfig';

const getStars = (count: number) => {
    const Star = ClientName === 'sonic' ? SonicStarIcon : AdblockStarIcon;
    const StarHalf = ClientName === 'sonic' ? SonicStarHalfIcon : AdblockHalfStarIcon;

    if (`${count}`.split('.').length === 2) {
        const fullStarsCount = `${count}`.split('.')[0];
        const fullStars = new Array(+fullStarsCount).fill(null)
            .map((_, index) => <Star key={index} className="star-icon" />);

        return [...fullStars, <StarHalf className="star-icon" />];
    }

    return new Array(count).fill(null)
        .map((_, index) => <Star key={index} className="star-icon" />);
};

export default getStars;
