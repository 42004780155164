/**
 * # Customization Teaser
 */

/**
 * ## Imports
 */
import React from 'react';
import AdvantageIllustration01Small from '../../images/sonic_browser/sonic-advantage-teaser-01-small.svg';
import AdvantageIllustration01Big from '../../images/sonic_browser/sonic-advantage-teaser-01-big.svg';
import AdvantageIllustration02Small from '../../images/sonic_browser/sonic-advantage-teaser-02-small.svg';
import AdvantageIllustration02Big from '../../images/sonic_browser/sonic-advantage-teaser-02-big.svg';
import AdvantageIllustration03Small from '../../images/sonic_browser/sonic-advantage-teaser-03-small.svg';
import AdvantageIllustration03Big from '../../images/sonic_browser/sonic-advantage-teaser-03-big.svg';
import AdvantageIllustration04Small from '../../images/sonic_browser/sonic-advantage-teaser-04-small.svg';
import AdvantageIllustration04Big from '../../images/sonic_browser/sonic-advantage-teaser-04-big.svg';
import styles from './CustomizationTeaser.module.scss';

/**
 * ## Types / Interfaces
 */
type Advantage = {
    illustrationSmall: string;
    illustrationBig: string;
    headline: string;
    copyText: string;
    id: string;
};

/**
 * ## Text Content
 */
const advantagesText: Advantage[] = [
    {
        illustrationSmall: AdvantageIllustration01Small,
        illustrationBig: AdvantageIllustration01Big,
        headline: 'Make Tabs Yours',
        copyText: 'Choose from a selection of backgrounds or upload your favorite cat meme to make your new tabs personal..',
        id: 'customize-tabs',
    },
    {
        illustrationSmall: AdvantageIllustration02Small,
        illustrationBig: AdvantageIllustration02Big,
        headline: 'Customize Widgets',
        copyText: 'Make your browser meet your needs with widgets that give you quick access to weather, web protection status, and favorite sites.',
        id: 'customize-widgets',
    },
    {
        illustrationSmall: AdvantageIllustration03Small,
        illustrationBig: AdvantageIllustration03Big,
        headline: 'Light & Dark Modes',
        copyText: 'Choose your browser theme to comfortably display pages day or night with the click of a button.',
        id: 'light-and-dark',
    },
    {
        illustrationSmall: AdvantageIllustration04Small,
        illustrationBig: AdvantageIllustration04Big,
        headline: 'Bring Your Extensions',
        // eslint-disable-next-line
        copyText: 'You’ll love our robust built-in features. But rest assured – if you have favorite extensions that you just can’t part with, you can easily install them from the Chrome Web Store.',
        id: 'bring-extensions',
    },
];

/**
 * ## Component Output
 */
const CustomizationTeaser: React.FC = () => {
    return (
        <section className={`${styles['wrapper']} centerwrapper`}>
            <h3 className={`${styles['headline']} ${styles['headline--first']}`}>Customize Everything</h3>
            <p className={styles['paragraph']}>Personalize your new tabs with unique backgrounds, helpful widgets, themes and Chrome extensions to make Sonic Browser yours.</p>
            {advantagesText && (
                <ul>
                    {advantagesText.map((advantage: Advantage, index: number) => (
                        <li id={advantage.id} className={styles['list-item']} key={index}>
                            <section className={styles['list-section']}>
                                <picture className={styles['illustration-wrapper']}>
                                    <source
                                        media="(max-width: 759px)"
                                        type="image/svg+xml"
                                        srcSet={advantage.illustrationSmall}
                                    />
                                    <source
                                        media="(min-width: 760px)"
                                        type="image/svg+xml"
                                        srcSet={advantage.illustrationBig}
                                    />
                                    <img
                                        // eslint-disable-next-line
                                        alt={'Browser Illustration'}
                                        src={advantage.illustrationSmall}
                                        className={styles['illustration']}
                                    />
                                </picture>
                                <div className={styles['list-text']}>
                                    <h4 className={styles['headline']}>{advantage.headline}</h4>
                                    <p className={styles['list-paragraph']}>{advantage.copyText}</p>
                                </div>
                            </section>
                        </li>
                    ))}
                </ul>
            )}
        </section>
    );
};

/**
 * ## Export
 */
export default CustomizationTeaser;
