export function getCookies() {
    const pairs = document.cookie;

    if (!pairs) {
        return null;
    }

    return pairs.split(';').reduce((acc, rec) => {
        const pair = rec.split('=');
        return {
            ...acc,
            [pair[0].trim()]: pair[1],
        };
    }, {});
}
