/**
 * # getPrivacyFeatureListContent
 */
/**
 * ## Imports
 */
import { ReactComponent as VisualPrivacyIcon } from 'common/images/sonic_browser/icons/privacy-visual.svg';
import { ReactComponent as AdBlockerPrivacyIcon } from 'common/images/sonic_browser/icons/privacy-ad-blocker.svg';
import { ReactComponent as TrackerBlockerPrivacyIcon } from 'common/images/sonic_browser/icons/privacy-tracker-blocker.svg';
import { ReactComponent as SafeBrowsingPrivacyIcon } from 'common/images/sonic_browser/icons/privacy-safe-browsing.svg';
import { ReactComponent as SecureConnectionPrivacyIcon } from 'common/images/sonic_browser/icons/privacy-secure-connection.svg';
import { ReactComponent as IncognitoIcon } from 'common/images/sonic_browser/icons/privacy-incognito.svg';
import { ReactComponent as CookieBlockerIcon } from 'common/images/sonic_browser/icons/privacy-cookie-blocker.svg';
import { ReactComponent as BrowserGuardianIcon } from 'common/images/sonic_browser/icons/privacy-browser-guardian.svg';

/**
 * ## Function
 */
export const getPrivacyFeatureListContent = () => {
    return [
        {
            title: 'Privacy Made Easy',
            description: 'Sonic makes private browsing accessible. Launch the browser and choose the security preference that matches your preference.',
            svgComponent: <VisualPrivacyIcon className="icon" />,
        },
        {
            title: 'Ad blocker',
            description: 'Say goodbye to disruptive ads. Sonic’s integrated ad blocker keeps sites clutter-free by intelligently blocking pop-ups, banners and videos for cleaner pages.',
            svgComponent: <AdBlockerPrivacyIcon className="icon" />,
        },
        {
            title: 'Tracker Blocker',
            description: 'Sonic instantly prevents invasive trackers from following you between sites and building profiles based on your activity so you can roam freely.',
            svgComponent: <TrackerBlockerPrivacyIcon className="icon" />,
        },
        {
            title: 'Safe Browsing',
            description: 'Discover safer internet with our Safe Browsing technology. Protect against malware and phishing effortlessly. Fast, secure, and user-friendly, it’s your shield in the digital world.',
            svgComponent: <SafeBrowsingPrivacyIcon className="icon" />,
        },
        {
            title: 'Secure Connections',
            description: 'Sonic uses cutting-edge encryption on all connections and alerts you to unsafe sites lacking HTTPS protection to proactively secure communications.',
            svgComponent: <SecureConnectionPrivacyIcon className="icon" />,
        },
        {
            title: 'Incognito Browsing',
            description: 'Explore completely off the record by preventing Sonic from retaining any history, searches, cookies or site data from private sessions.',
            svgComponent: <IncognitoIcon className="icon" />,
        },
        {
            title: 'Cookie Blocker',
            description: 'Control the personal information sites access with granular cookie management. Choose which cookies to accept or deny on a case-by-case basis.',
            svgComponent: <CookieBlockerIcon className="icon" />,
        },
        {
            title: 'Browser Guardian',
            description: 'Automatically blocks intrusive extensions that try to change your default settings without permission.',
            svgComponent: <BrowserGuardianIcon className="icon" />,
        },
    ];
};
