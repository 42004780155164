/**
 * # Get Feature Teaser List
 * - Returns content for the Feature Teaser List
 */

/**
 * ## Imports
 */
import featureTeaserSmallImageSrc01 from 'common/images/sonic_browser/sonic-feature-01-small.png';
import featureTeaserBigImageSrc01 from 'common/images/sonic_browser/sonic-feature-01-big.png';
import featureTeaserSmallImageSrc02 from 'common/images/sonic_browser/sonic-feature-02-small.png';
import featureTeaserBigImageSrc02 from 'common/images/sonic_browser/sonic-feature-02-big.png';
import featureTeaserSmallImageSrc03 from 'common/images/sonic_browser/sonic-feature-03-small.png';
import featureTeaserBigImageSrc03 from 'common/images/sonic_browser/sonic-feature-03-big.png';
import featureTeaserSmallImageSrc04 from 'common/images/sonic_browser/sonic-feature-04-small.png';
import featureTeaserBigImageSrc04 from 'common/images/sonic_browser/sonic-feature-04-big.png';

/**
 * ## Content
 */
export const getFeatureTeaserListContent = (client: string) => {
    const isSonicClient = client === 'sonic';

    if (isSonicClient) {
        return [
            {
                title: 'Custom Everything',
                description: 'Personalize your new tabs with custom backgrounds, useful widgets for weather, themes, and Chrome extensions to make Sonic Browser yours.',
                imageSrcBig: featureTeaserBigImageSrc01,
                imageSrcSmall: featureTeaserSmallImageSrc01,
                ctaLabel: 'Try it free',
            },
            {
                title: 'Available on all devices',
                description: 'Access on desktop or mobile by downloading from the App Store and Google Play Store to browse securely from anywhere.',
                imageSrcBig: featureTeaserBigImageSrc02,
                imageSrcSmall: featureTeaserSmallImageSrc02,
                ctaLabel: 'Try it free',
            },
            {
                title: 'Easy data syncing',
                description: 'Seamlessly add your bookmarks, browsing history and passwords across all your devices with Sonic Browser for added accessibility, for even more convenience and protection.',
                imageSrcBig: featureTeaserBigImageSrc03,
                imageSrcSmall: featureTeaserSmallImageSrc03,
                ctaLabel: 'Try it free',
            },
            {
                title: 'Automatic privacy',
                description: 'Sonic Browser safeguards your privacy with built-in ad blocking, tracker protection, cookie control, fingerprint obscuring, encryption, and incognito browsing to eliminate traces of your activity.',
                imageSrcBig: featureTeaserBigImageSrc04,
                imageSrcSmall: featureTeaserSmallImageSrc04,
                ctaLabel: 'Try it free',
            },
        ];
    }

    return null;
};
