// DE
import countriesDE from './locales/de/countries.json';
import commonDE from './locales/de/no_filename.json';
import usStatesDE from './locales/de/us_states.json';
import websiteV2DE from './locales/de/website-v2.json';
import privacyPolicyDE from './locales/de/privacy_policy.json';
// import termsOfServiceDE from './locales/de/terms_of_service.json';

// EN
import countriesEN from './locales/en/countries.json';
import commonEN from './locales/en/no_filename.json';
import usStatesEN from './locales/en/us_states.json';
import websiteV2EN from './locales/en/website-v2.json';
import privacyPolicyEN from './locales/en/privacy_policy.json';
import termsOfServiceEN from './locales/en/terms_of_service.json';

// ES
import countriesES from './locales/es/countries.json';
import commonES from './locales/es/no_filename.json';
import usStatesES from './locales/es/us_states.json';
import websiteV2ES from './locales/es/website-v2.json';
import privacyPolicyES from './locales/es/privacy_policy.json';
// import termsOfServiceES from './locales/es/terms_of_service.json';

// FR
import countriesFR from './locales/fr/countries.json';
import commonFR from './locales/fr/no_filename.json';
import usStatesFR from './locales/fr/us_states.json';
import websiteV2FR from './locales/fr/website-v2.json';
import privacyPolicyFR from './locales/fr/privacy_policy.json';
// import termsOfServiceFR from './locales/fr/terms_of_service.json';

// PT
import countriesPT from './locales/pt/countries.json';
import commonPT from './locales/pt/no_filename.json';
import usStatesPT from './locales/pt/us_states.json';
import websiteV2PT from './locales/pt/website-v2.json';
import privacyPolicyPT from './locales/pt/privacy_policy.json';
// import termsOfServicePT from './locales/pt/terms_of_service.json';

const resources = {
    en: {
        common: commonEN,
        countries: countriesEN,
        usStates: usStatesEN,
        websiteV2: websiteV2EN,
        privacyPolicy: privacyPolicyEN,
        termsOfService: termsOfServiceEN,
    },
    fr: {
        common: commonFR,
        countries: countriesFR,
        usStates: usStatesFR,
        websiteV2: websiteV2FR,
        privacyPolicy: privacyPolicyFR,
        // termsOfService: termsOfServiceFR
    },
    de: {
        common: commonDE,
        countries: countriesDE,
        usStates: usStatesDE,
        websiteV2: websiteV2DE,
        privacyPolicy: privacyPolicyDE,
        // termsOfService: termsOfServiceDE
    },
    pt: {
        common: commonPT,
        countries: countriesPT,
        usStates: usStatesPT,
        websiteV2: websiteV2PT,
        privacyPolicy: privacyPolicyPT,
        // termsOfService: termsOfServicePT
    },
    es: {
        common: commonES,
        countries: countriesES,
        usStates: usStatesES,
        websiteV2: websiteV2ES,
        privacyPolicy: privacyPolicyES,
        // termsOfService: termsOfServiceES
    },
};

export default resources;
