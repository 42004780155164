import clsx from 'clsx';
import SettingsIcon from 'common/images/sonic_browser/icons/settings-icon.png';
import OptionsIcon from 'common/images/sonic_browser/icons/options-icon.png';
import AutostartInstructionIcon from 'common/images/sonic_browser/autostart-instruction.png';

export const getGeneralFAQs = (styles) => {
    return [
        {
            summary: 'What is SONIC?',
            content: <p>SONIC is a safe and secure <span className={styles['bold']}>web browser</span> with built-in and easy-to-use privacy settings. It features built-in ad blocker, invisible trackers blocker, anti-fingerprinting and end-to-end encryption across devices so you can have a better web experience and stay safe online.</p>,
        },
        {
            summary: 'What are SONIC’s innovative security features?',
            content: <p>SONIC leverages the same underlying mechanism to block malicious websites, as SafeBrowsing, yet differently. It has built-in a Smart Aggregator Service that pulls information from multiple reputable sources. SONIC ensures better coverage over the security landscape, offering you a better protection.</p>,
        },
        {
            id: 'why-faster',
            summary: 'Why is SONIC faster than traditional browsers?',
            content: <p>While browsing with SONIC some unwanted content like cookies, trackers and ads can be blocked. This allows websites to load and display faster. With it&lsquo;s one-click cookie consent pop-up blocker, the browser experience is uninterrupted, allowing you to reach the website&lsquo;s content faster. SONIC is using advanced parallel downloading technology, where files are split up into smaller parts and download simultaneously to get you your content faster.</p>,
        },
        {
            id: 'available-os',
            summary: 'Is SONIC available for my device or Operating System?',
            content: (
                <div className={styles['mb-20']}>
                    <p>SONIC is currently available for most recent and popular versions of Windows and MacOS.</p>
                    <p>To use SONIC browser on <span className={styles['bold']}>Windows</span>, you&lsquo;ll need:</p>
                    <ul className={clsx(styles['list'], styles['list__no-gap'])}>
                        <li>Windows 10 or later or Windows Server 2016 or later</li>
                    </ul>
                    <p>To use SONIC browser on <span className={styles['bold']}>Mac</span>, you&lsquo;ll need:</p>
                    <ul className={clsx(styles['list'], styles['list__no-gap'])}>
                        <li>macOS Catalina 10.15 or later, working with both Intel and Apple Silicon processors (M1+). </li>
                    </ul>
                </div>
            ),
        },
        {
            id: 'how-to-install',
            summary: 'How to install SONIC?',
            content: (
                <>
                    <ol className={clsx(styles['list'], styles['list__numeric'])}>
                        <li>Go to: <a href="https://sonic-browser.com/">www.sonic-browser.com</a></li>
                        <li>Click on any <span className={styles['bold']}>Download</span> button and wait for the download to finish.</li>
                        <li>Launch the installer (double click it or Run it directly).</li>
                        <li>Click the <span className={styles['bold']}>Install</span> button to accept the License Agreement and EULA.</li>
                        <li>Wait until <span className={styles['bold']}>SONIC</span> is installed and configures its features.</li>
                        <li>Browse safely!</li>
                    </ol>
                    <ul className={styles['list']}>
                        <li>during the install process you can always opt-out of setting SONIC as default browser or pinning it to your dock/taskbar</li>
                    </ul>
                </>
            ),
        },
        {
            summary: 'Why is my antivirus blocking SONIC?',
            content: (
                <>
                    <p>If you cannot download or install the SONIC browser, or if you receive a message from your antivirus about blocking SONIC, please contact: <a href="mailto:support@sonic-browser.com">support@sonic-browser.com</a>.</p>
                    <p>Please check that your antivirus software is up-to-date, as it updates on a very frequent basis.</p>
                </>
            ),
        },
        {
            summary: 'How do I add extensions in SONIC?',
            content: (
                <ol className={clsx(styles['list'], styles['list__numeric'])}>
                    <li>Go to the <a href="https://chromewebstore.google.com/">Chrome Web Store</a></li>
                    <li>Search for your extension and click Add extension.</li>
                    <li>Your extension will automatically be added to SONIC.</li>
                </ol>
            ),
        },
        {
            summary: 'How do I customize SONIC?',
            content: <p className={styles['with-icon-text']}>SONIC offers multiple ways of customization ranging from themes, wallpapers, WOW cards and shortcuts. You can simply access them all from your NewTab’s settings button <img className={styles['icon']} src={SettingsIcon} width={30} alt="Settings button" /> located in the top right side.</p>,
        },
        {
            summary: 'What are WOW cards?',
            content: <p className={styles['with-icon-text']}>WOW cards are widgets displayed on the left side of your NewTab that display information about the weather, trackers blocked and others. You can turn them on / off or customize them by using the NewTab settingd button <img className={styles['icon']} src={SettingsIcon} width={30} alt="Settings button" /> top right.</p>,
        },
        {
            summary: 'What are Shortcuts?',
            content: <p className={styles['with-icon-text']}>Shortcuts, tiles or speed dials are links that can be added and customized to access faster your favorite websites. You can turn them on / off or customize the amount by using the NewTab settings button <img className={styles['icon']} src={SettingsIcon} width={30} alt="Settings button" /> top right.</p>,
        },
        {
            id: 'sonic-coupons',
            summary: 'What are Sonic Coupons?',
            content: (
                <>
                    <p>Sonic Coupons are a free browser feature that locates better deals for online shopping. It scans store homepages, newsletters, and the web daily to find up-to-date coupon codes. With one click, Sonic Coupons applies the best codes during checkout, eliminating the need for manual entry. It automatically appears at checkout on popular e-commerce sites, and you can reapply coupons anytime by clicking the Sonic Coupons icon in your browser toolbar.</p>
                    <p>Sonic Coupons are currently <strong>available only</strong> in the United States.</p>
                </>
            ),
        },
        {
            summary: 'How do I delete my browsing history and cache?',
            content: (
                <>
                    <ol className={clsx(styles['list'], styles['list__numeric'])}>
                        <li>At the top right corner, click on the Menu button <img className={styles['icon']} src={OptionsIcon} width={20} alt="Settings button" /></li>
                        <li>Select <span className={styles['bold']}>Clear browsing data</span>.</li>
                        <li>Choose which data to delete and click OK.</li>
                    </ol>
                    <p>To ensure that no cookies, cache, or browsing and search history are stored, you can also use the Incognito Window.</p>
                </>
            ),
        },
        {
            summary: 'How to enable or disable starting SONIC automatically?',
            content: (
                <>
                    <p>SONIC offers on the installation screen the option to automatically start-up with your OS for a better experience. This option can be easily enabled or disabled later on.</p>
                    <p>Use SONIC’s <span className={styles['bold']}>Settings</span> go to <span className={styles['bold']}>System &gt; Launch Sonic Browser at startup</span> and toggle the button on/off.</p>
                    <img className={styles['huge-img']} src={AutostartInstructionIcon} alt="Autostart instruction" />
                    <p>If you encounter any issues, please contact: <a href="mailto:support@sonic-browser.com">support@sonic-browser.com</a>.</p>
                </>
            ),
        },
        {
            summary: 'How to uninstall SONIC?',
            content: (
                <>
                    <p className={styles['bold']}>For Windows:</p>
                    <ol className={clsx(styles['list'], styles['list__numeric'])}>
                        <li>Go to <span className={styles['bold']}>Control Panel &gt; Programs and Features &gt; Uninstall a program</span>.</li>
                        <li>Find SONIC in the list of installed programs, right-click it and select <span className={styles['bold']}>Uninstall</span>.</li>
                        <li>Confirm the uninstallation.</li>
                    </ol>
                    <p className={styles['bold']}>For MacOS:</p>
                    <ol className={clsx(styles['list'], styles['list__numeric'])}>
                        <li>Quit SONIC: go to the menu bar and select <span className={styles['bold']}>SONIC &gt; Quit Sonic Browser</span>.</li>
                        <li>Open a new <span className={styles['bold']}>Finder</span> window.</li>
                        <li>Open your user <span className={styles['bold']}>Applications</span> folder.</li>
                        <li>Right-click on Sonic Browser.app and select <span className={styles['bold']}>Move to Trash</span>.</li>
                        <li>Right-click on your Trash and select <span className={styles['bold']}>Empty Trash</span>.</li>
                    </ol>
                    <p>If you encounter any issues, please contact: <a href="mailto:support@sonic-browser.com">support@sonic-browser.com</a>.</p>
                </>
            ),
        },
    ];
};
