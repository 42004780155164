/**
 * # Client Config
 */
export const CLIENT = {
    tempest: {
        name: 'Tempest',
        withFooter: true, // Just a demo
    },
    sonic: {
        name: 'Sonic',
        /* - 🚏 With this flag you can toggle the navigation routes and make sure that
         * other pages are or are not indexed or reachable by a user/crawler. */
        hasNavigationRoutes: true,
    },
    adblock: {
        name: 'Adblock',
        hasNavigationRoutes: true,
    },
};

const defaultClient = {
    name: 'tempest',
};

const selectedClient = CLIENT[process.env.REACT_APP_ENV] || defaultClient;

if (!selectedClient) {
    console.error(`Invalid client environment: ${process.env.REACT_APP_ENV}`);
}

export const ClientName = selectedClient.name.toLowerCase();
