import { isIOS, isAndroid, isMacOs, isWindows, isIOS13, isIPod13, isIPad13, isIPhone13 } from 'react-device-detect';

const userAgent = navigator.userAgent || navigator.vendor || window.opera;
const vendor = navigator.vendor;

export const UserAgentBrowserTypes = {
    Chrome: 'Chrome',
    Yandex: 'Yandex BrowserType',
    FirefoxIOS: 'FxiOS',
    ChromeIOS: 'CriOS',
    Safari: 'Safari',
    Opera: 'Opera',
    Firefox: 'Firefox',
    SamsungBrowser: 'Samsung Internet BrowserType',
    Edge: 'Edge',
    IOS: 'IOS',
    Android: 'Android',
    Mobile: 'Mobile',
    Other: 'Other',
};

export const UserAgentOSTypes = {
    MacOS: 'MacOS',
    MacOS14AndLower: 'MacOS14AndLower',
    Windows: 'Windows',
    Windows32: 'Windows32',
    Windows64: 'Windows64',
    Linux: 'Linux',
    Android: 'Android',
    iOS: 'iOS',
};

const iTunesLink = 'iTunesLink';

export const pluginSupportedBrowsers = [UserAgentBrowserTypes.Chrome, UserAgentBrowserTypes.Firefox];
// export const supportedOS = [UserAgentOSTypes.Windows, UserAgentOSTypes.iOS, UserAgentOSTypes.MacOS];

export const ExtensionLinks = {
    [UserAgentBrowserTypes.Chrome]: 'https://chrome.google.com/webstore/detail/tempest-search/aifabaooljbmglcjiedhcnmbpdbmliia',
    [UserAgentBrowserTypes.Firefox]: 'https://addons.mozilla.org/en-US/firefox/addon/tempest/',
    [UserAgentBrowserTypes.Safari]: 'https://chrome.google.com/webstore/detail/tempest-search/aifabaooljbmglcjiedhcnmbpdbmliia',
    [UserAgentBrowserTypes.Opera]: 'https://chrome.google.com/webstore/detail/tempest-search/aifabaooljbmglcjiedhcnmbpdbmliia',
};

export const browserLinks = {
    [UserAgentOSTypes.MacOS]: `${process.env.REACT_APP_DOWNLOADS_URL_MACOS}${process.env.REACT_APP_DOWNLOADS_MACOS_ENDPOINT}`,
    [UserAgentOSTypes.Windows]: `${process.env.REACT_APP_DOWNLOADS_URL_WINDOWS}${process.env.REACT_APP_DOWNLOADS_WINDOWS_X64_ENDPOINT}`,
    [UserAgentOSTypes.Windows64]: `${process.env.REACT_APP_DOWNLOADS_URL_WINDOWS}${process.env.REACT_APP_DOWNLOADS_WINDOWS_X64_ENDPOINT}`,
    [UserAgentOSTypes.Windows32]: `${process.env.REACT_APP_DOWNLOADS_URL_WINDOWS}${process.env.REACT_APP_DOWNLOADS_WINDOWS_X86_ENDPOINT}`,

    [UserAgentOSTypes.iOS]: `${process.env.REACT_APP_DOWNLOADS_IOS_URL}`,
    iTunesLink: `${process.env.REACT_APP_DOWNLOADS_ITUNES_URL}`,
    [UserAgentOSTypes.Android]: `${process.env.REACT_APP_DOWNLOADS_ANDROID_URL}`,
    [UserAgentOSTypes.Linux]: '/browser',
};

export const isWindows64OS = /Win64/.test(navigator.userAgent);
export const isMacOS = /mac/i.test(userAgent);
// export const isMacOS = /mac/i.test(userAgent);
export const isLinuxOS = /linux/i.test(userAgent);
// export const isAndroid = isAndroid;
export const isIos = isIOS || isIOS13 || isIPod13 || isIPad13 || isIPhone13;

export const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(vendor);
export const isYandexIOS = /YaBrowser/.test(userAgent) || /YaApp/.test(userAgent);
export const isFirefoxIOS = /FxiOS/.test(userAgent);
export const isChromeIOS = /CriOS/.test(userAgent);
export const isSafari = !isChromeIOS && !isYandexIOS && !isFirefoxIOS && /Safari/.test(userAgent) && /Apple Computer/.test(vendor);
export const isOpera = /OPR|Opera/.test(userAgent);
export const isFirefox = /Firefox/.test(userAgent);
export const isSamsungBrowser = /SamsungBrowser/.test(userAgent);
export const isEdge = /Edg/.test(userAgent);
export const isTablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent);
export const isMobile = /Mobi/i.test(userAgent) || ('ontouchstart' in document.documentElement) || isIOS || isAndroid;
export const isWebView = /wv/i.test(userAgent);
// export const isFacebook = /FBAN|FBAV/i.test(userAgent);
// export const isInstagram = /Instagram/i.test(userAgent);
// export const isTwitter = /Twitter/i.test(userAgent);
// export const isInAppBrowser = isFacebook || isInstagram || isTwitter;

export const getBrowserName = () => {
    if (isYandexIOS) { return UserAgentBrowserTypes.Yandex; }
    if (isFirefoxIOS) { return UserAgentBrowserTypes.FirefoxIOS; }
    if (isChromeIOS) { return UserAgentBrowserTypes.ChromeIOS; }
    if (isSafari) { return UserAgentBrowserTypes.Safari; }
    if (isOpera) { return UserAgentBrowserTypes.Opera; }
    if (isFirefox) { return UserAgentBrowserTypes.Firefox; }
    if (isSamsungBrowser) { return UserAgentBrowserTypes.SamsungBrowser; }
    if (isEdge) { return UserAgentBrowserTypes.Edge; }
    if (isIOS) { return UserAgentBrowserTypes.IOS; }
    if (isChrome) { return UserAgentBrowserTypes.Chrome; }
    if (isAndroid) { return UserAgentBrowserTypes.Android; }
    if (isMobile) { return UserAgentBrowserTypes.Mobile; }
    return UserAgentBrowserTypes.Other;
};

export const getMacOSVersionType = () => {
    const OSVersion = userAgent.slice(userAgent.indexOf('Mac OS X'), userAgent.indexOf('Apple')).trim()
        .replace('Mac OS X ', '')
        .split('_');
    return OSVersion[1] >= 15 ? UserAgentOSTypes.MacOS : UserAgentBrowserTypes.MacOS14AndLower;
};

export const getOS = (isDownloadLink) => {
    if (isAndroid) {
        return UserAgentOSTypes.Android;
    }
    if (isWindows) {
        return isWindows64OS ? UserAgentOSTypes.Windows64 : UserAgentOSTypes.Windows32;
    }
    // isOS should be above isMacOs for correct identification of iPads and iPhones
    if (isIOS) {
        if (isDownloadLink) {
            return iTunesLink;
        }
        return UserAgentOSTypes.iOS;
    }
    if (isMacOs) {
        return getMacOSVersionType();
    }
    if (isLinuxOS) {
        return UserAgentOSTypes.Linux;
    }

    return UserAgentOSTypes.Windows;
};

export const isPluginSupportedBrowser = () => pluginSupportedBrowsers.includes(getBrowserName());

export const isMobileBasedOnOS = () => {
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return true;
    }

    if (/android/i.test(userAgent)) {
        return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
    }

    return false;
};

export const mostLikelyMobileDevice = (isMobile || isIOS || isAndroid || isMobileBasedOnOS());
