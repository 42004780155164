const getPagesCheck = (location: { [x: string]: any }) => {
    const isHomePage: boolean = location.pathname === '/';
    const isDownloadFeaturePage: boolean = location.pathname.includes('/download-windows-b');
    const isFeaturesPage: boolean = location.pathname.includes('/features') || isDownloadFeaturePage;
    const isPrivacyPage: boolean = location.pathname.includes('/privacy');
    const isAboutPage: boolean = location.pathname.includes('/about');
    const isCustomizePage: boolean = location.pathname.includes('/customize');

    return { isHomePage, isDownloadFeaturePage, isFeaturesPage, isPrivacyPage, isAboutPage, isCustomizePage };
};

const getClientNameChecks = (clientName: string) => {
    const isSonicClient = clientName === 'sonic';
    const isTempestClient = clientName === 'tempest';
    const isAdblockClient = clientName === 'adblock';

    return { isTempestClient, isSonicClient, isAdblockClient };
};

export const getClassName = ({ location, clientName, classList, styles }: {
    location: { [x: string]: any };
    styles: { [x: string]: any };
    clientName: string;
    classList: string;
}) => {
    const { isHomePage, isDownloadFeaturePage, isFeaturesPage, isPrivacyPage, isAboutPage, isCustomizePage } = getPagesCheck(location);

    const { isTempestClient } = getClientNameChecks(clientName);

    if (isTempestClient) {
        return `${styles['cta-teaser-wrapper']} ${styles['cta-teaser-tempest']} ${classList}`;
    }

    if (isHomePage) return `${styles['cta-teaser-wrapper']} ${styles[`cta-teaser-${clientName}`]} ${styles[`cta-teaser-${clientName}--overlap`]} ${classList}`;
    if (isDownloadFeaturePage) return `${styles['cta-teaser-wrapper']} ${styles[`cta-teaser-${clientName}`]} ${styles['cta-teaser-test']} ${classList}`;
    if (isFeaturesPage || isPrivacyPage || isAboutPage || isCustomizePage) return `${styles['cta-teaser-wrapper']} ${styles[`cta-teaser-${clientName}`]} ${classList}`;

    return `${styles['cta-teaser-wrapper']} ${styles['cta-teaser-tempest']} ${classList}`;
};

export const getHeadlineMain = ({ clientName, location }) => {
    const { isHomePage, isDownloadFeaturePage, isFeaturesPage, isPrivacyPage, isAboutPage, isCustomizePage } = getPagesCheck(location);
    const { isSonicClient, isAdblockClient } = getClientNameChecks(clientName);

    if (isSonicClient) {
        if (isHomePage) return 'Browse at the <span class="italic">speed</span> of sound';
        if (isDownloadFeaturePage) return '';
        if (isFeaturesPage) return 'The best features in one browser';
        if (isPrivacyPage) return 'Privacy at Record Speeds';
        if (isAboutPage) return 'Hi. We’re Sonic. <br /> It’s nice to meet you.';
        if (isCustomizePage) return 'The best features in one browser';
    }

    if (isAdblockClient) {
        return '<strong>Ads Under Control:</strong> Discover a Clean Internet with <strong>Adblock</strong>Browser';
    }

    return 'Download <br /> Tempest Browser';
};
