/**
 * # Smarter Browsing Feature Component
 */

/**
 * ## Imports
 */
import { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { isAndroid, isMobile, isTablet, osName } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDownload } from 'hooks/useDownload';
import { ClientName } from 'client/clientConfig';
import ButtonCta from '../ButtonCta';
import noAdsImage from './images/no-ads.png';
import batteryImage from './images/battery.png';
import streamImage from './images/stream.png';
import styles from './SmarterBrowsing.module.scss';

const images = [noAdsImage, batteryImage, streamImage];

const accordionData = [
    {
        title: 'The power of Adblock Plus built into your browser',
        content: 'Enjoy superior ad blocking and faster browsing.',
    },
    {
        title: 'Maximize your data plan and battery.',
        content: 'Blocking ads means fewer data to download, saving you data and extending your battery.',
    },
    {
        title: 'Stream videos without any interruptions',
        content: 'Say goodbye to annoying ads on your favorite video sites.',
    },
];

/**
 * ## Component
 */
const SmarterBrowsing = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoading, downloadBrowser } = useDownload(location?.search || '');

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    const handleClick = (index) => () => {
        setActiveIndex(index);
    };

    const handleDownload = () => {
        if (['Windows', 'Mac OS'].includes(osName)) {
            navigate('/download');
        } else {
            downloadBrowser();
        }
    };

    const label = useMemo(() => {
        if (isAndroid) {
            return 'Try it free';
        }

        return isMobile || isTablet || !['Windows', 'Mac OS'].includes(osName) ? 'Coming soon' : 'Try it free';
    }, []);

    const isDisabled = useMemo(() => {
        if (isAndroid) {
            return false;
        }

        return isMobile || isTablet || !['Windows', 'Mac OS'].includes(osName);
    }, []);

    return (
        <div className={styles['wrapper']}>
            <h2 className={styles['title']}>Lightning Speed. Bulletproof Privacy. Smarter Browsing.</h2>
            <div className={styles['carousel-container']}>
                <div className={styles['image-container']}>
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            className={clsx(styles['carousel-image'], {
                                [styles['active']]: activeIndex === index,
                            })}
                            alt={`Slide ${index}`}
                        />
                    ))}
                </div>
                <div className={styles['accordion-container']}>
                    {accordionData.map((item, index) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div
                            key={index}
                            className={clsx(styles['accordion-item'], { [styles['active']]: activeIndex === index })}
                            onClick={handleClick(index)}
                        >
                            <h3 className={styles['accordion-item--title']}>{item.title}</h3>
                            <p className={styles['accordion-item--text']}>{item.content}</p>
                        </div>
                    ))}
                    <div className={styles['vertical-line']}>
                        <div className={clsx(styles['progress'], [styles[`progress--${activeIndex}`]])} />
                    </div>

                    <ButtonCta
                        clientName={ClientName}
                        classList={styles['button']}
                        label={label}
                        onClick={handleDownload}
                        layoutBig={false}
                        layoutGlowBottom={false}
                        large
                        layoutLofty={false}
                        isButtonDisabled={isLoading || isDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default SmarterBrowsing;
