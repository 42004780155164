/**
 * # Sonic Routes
 */
/**
 * ## Imports
 */
import { CLIENT } from 'client/clientConfig';
import DownloadPage from 'pages/DownloadPage';
import AfterDownloadPage from 'pages/AfterDownloadPage';
import { UserAgentOSTypes } from 'lib/userAgent';
import HomePage from './pages/HomePage';
import PrivacyPage from './pages/PrivacyPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import CookiePolicyPage from './pages/CookiePolicyPage';
import UserLicensePage from './pages/UserLicensePage';
import TermsPage from './pages/TermsPage';
import ContactPage from './pages/ContactPage';
import SupportPage from './pages/SupportPage';
import FeaturesPage from './pages/FeaturesPage';
import AboutPage from './pages/AboutPage';
// import ProductivityPage from './pages/ProductivityPage';
import CustomizePage from './pages/CustomizePage';
import DownloadFeaturesPage from './pages/DownloadFeaturesPage';
import NotFoundPage from './pages/NotFoundPage';
import WelcomePage from './pages/WelcomePage';

/**
 * ## Routes
 */
export const routesSonic = (CLIENT.sonic.hasNavigationRoutes) ? [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '/terms-of-use',
        element: <TermsPage />,
    },
    {
        path: '/contact',
        element: <ContactPage />,
    },
    {
        path: '/welcome',
        element: <WelcomePage />,
    },
    {
        path: '/support',
        element: <SupportPage />,
    },
    {
        path: '/cookie-policy',
        element: <CookiePolicyPage />,
    },
    {
        path: '/terms-of-service',
        element: <UserLicensePage />,
    },
    {
        path: '/user-license-agreement',
        element: <UserLicensePage />,
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicyPage />,
    },
    {
        path: '/features',
        element: <FeaturesPage />,
    },
    {
        path: '/privacy',
        element: <PrivacyPage />,
    },
    {
        path: '/about',
        element: <AboutPage />,
    },
    {
        path: '/download',
        element: <DownloadPage />,
    },
    {
        path: '/download-mac',
        element: <AfterDownloadPage OS={UserAgentOSTypes.MacOS} />,
    },
    {
        path: '/download-windows',
        element: <AfterDownloadPage OS={UserAgentOSTypes.Windows} />,
    },
    {
        path: '/download-windows-b',
        element: <DownloadFeaturesPage OS={UserAgentOSTypes.Windows} />,
    },
    // {
    //     path: '/productivity',
    //     element: <ProductivityPage />,
    // },
    {
        path: '/customize',
        element: <CustomizePage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
] : [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
];
