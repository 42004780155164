/**
 * # CTA Teaser Intro
 */

/**
 * ## Imports
 */
import { FC, useMemo } from 'react';
import { isAndroid, isMobile, isTablet, osName } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { ClientName } from 'client/clientConfig';
import { useDownload } from 'hooks/useDownload';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import styles from './CtaTeaserIntro.module.scss';
import ButtonCta from '../ButtonCta';
import { ReactComponent as LogoIconTempest } from '../../images/homepage_rebrand/icons/logo-icon.svg';
import DownloadProgressBar from '../DownloadProgressBar/DownloadProgressBar';

/**
 * ## Types / Interfaces
 */
type CtaTeaserIntroProps = {
    headlineLabel: string;
    copyText: React.ReactElement | string;
    hasButton?: boolean;
    buttonLabel?: string;
    centeredLayout?: boolean;
    isNewLayout?: boolean;
}

/**
 * ## Component
 */
const CtaTeaserIntro: FC<CtaTeaserIntroProps> = ({
    headlineLabel,
    copyText,
    hasButton,
    buttonLabel,
    centeredLayout = false,
    isNewLayout,
}) => {
    const isTempestClient = ClientName === 'tempest';
    const isSonicClient = ClientName === 'sonic';
    const isAdblockClient = ClientName === 'adblock';
    const logoIcon = <LogoIconTempest />;
    const location = useLocation();
    const { isLoading, downloadBrowser } = useDownload(location?.search || '');
    const navigate = useNavigate();
    const handleDownloadButton = () => {
        if ((isSonicClient || isAdblockClient) && ['Windows', 'Mac OS'].includes(osName)) {
            navigate('/download', { state: { isNewLayout } });
        } else {
            downloadBrowser();
        }
    };

    const label = useMemo(() => {
        if (isSonicClient) {
            return isMobile || isTablet || osName !== 'Windows' ? 'Coming soon' : buttonLabel as any;
        }

        if (isAdblockClient) {
            if (isAndroid) {
                return buttonLabel as any;
            }

            return isMobile || isTablet || !['Windows', 'Mac OS'].includes(osName) ? 'Coming soon' : buttonLabel as any;
        }

        return buttonLabel as any;
    }, [isSonicClient, isAdblockClient, buttonLabel]);

    const isDisabled = useMemo(() => {
        if (isSonicClient) {
            return isMobile || isTablet || osName !== 'Windows';
        }

        if (isAdblockClient) {
            if (isAndroid) {
                return false;
            }

            return isMobile || isTablet || !['Windows', 'Mac OS'].includes(osName);
        }

        return true;
    }, [isSonicClient, isAdblockClient]);

    const renderCopyText = () => {
        if (typeof copyText === 'string') {
            return (
                // eslint-disable-next-line react/no-danger
                <p className="intro-paragraph intro-paragraph--bold" dangerouslySetInnerHTML={{ __html: copyText }} />
            );
        }
        return copyText;
    };

    return (
        <div className={clsx(
            styles['cta-teaser-intro'],
            {
                [styles['cta-teaser-intro--sonic']]: isSonicClient,
                [styles['cta-teaser-intro--tempest']]: isTempestClient,
                [styles['cta-teaser-intro--adblock']]: isAdblockClient,
                [styles['cta-teaser-intro--download-features']]: isNewLayout,
                centerwrapper: centeredLayout,
            },
        )}
        >
            {isTempestClient && (
                <CompanyLogo
                    svgComponent={logoIcon}
                    width={
                        isSonicClient ? 154 : 86
                    }
                    height={
                        isSonicClient ? 59 : 86
                    }
                    clientName={ClientName}
                />
            )}
            {headlineLabel && (
                <h2
                    className={styles['headline-main']}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: headlineLabel }}
                />
            )}
            {renderCopyText()}

            {hasButton && (
                <div className={clsx(styles['button-wrapper'], {
                    [styles['continue-button']]: isNewLayout,
                })}
                >
                    <ButtonCta
                        clientName={ClientName}
                        label={label}
                        arrowIndicator={isTempestClient}
                        downloadIcon={isSonicClient || isAdblockClient}
                        layoutAlt={isTempestClient}
                        layoutGlowBottom={isTempestClient || isSonicClient}
                        large={isTempestClient}
                        isAnimated={isTempestClient}
                        onClick={handleDownloadButton}
                        hasAnimatedBorder={isTempestClient}
                        isButtonDisabled={isLoading || isDisabled}
                    />
                </div>
            )}

            {(isNewLayout && osName === 'Windows') && <DownloadProgressBar />}
        </div>
    );
};

/**
 * ## Exports
 */
export default CtaTeaserIntro;
