/**
 * # Bounce Instruction
 */

/**
 * ## Imports
 */
import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './BounceInstruction.module.scss';
import { ReactComponent as DownloadIcon } from './images/download.svg';

/**
 * ## Types / Interfaces
 */
type BounceInstructionProps = {
    text?: string;
    isLastStep?: boolean;
}

/**
 * ## Component
 */
const BounceInstruction: FC<BounceInstructionProps> = ({ text = 'Click here to install', isLastStep = false }) => {
    const [showSecondAnimation, setShowSecondAnimation] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowSecondAnimation(true);
        }, 5000);
    });

    if (isLastStep) {
        return (
            <div className={clsx(styles['tooltip__bounce-bottom'], styles['tooltip'])}>
                <p className={styles['text']}>Click Install</p>
            </div>
        );
    }

    if (showSecondAnimation) {
        return (
            <div className={clsx(styles['tooltip__bounce-top'], styles['tooltip'])}>
                <p className={styles['text']}>Click <DownloadIcon className={styles['icon']} /> above</p>
                <p className={styles['text']}>Then click the sonicbrowserinstaller.exe file</p>
            </div>
        );
    }

    return (
        <div className={clsx(styles['bounce-right'], styles['tooltip'])}>
            <p className={styles['text']}>{text}</p>
        </div>
    );
};

export default BounceInstruction;
