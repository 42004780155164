/**
 * # Privacy Summary List
 */

/**
 * ## Imports
 */
import { FC } from 'react';
import clsx from 'clsx';
import styles from './PrivacySummaryList.module.scss';

/**
 * ## Types / Interfaces
 */
type PrivacyFeature = {
    title: React.ReactElement;
    svgComponent: React.ReactElement;
}

type PrivacySummaryListProps = {
    PrivacyFeatureListData: PrivacyFeature[];
};

/**
 * ## Component
 */
const PrivacySummaryList: FC<PrivacySummaryListProps> = ({ PrivacyFeatureListData }) => {
    return (
        <section>
            <ul className={clsx(
                styles['privacy-summary'],
                'centerwrapper',
            )}
            >
                {PrivacyFeatureListData.map((item, index) => {
                    return (
                        <li className={styles['list-item']} key={index}>
                            <span className={styles['illustration-frame']}>{item.svgComponent}</span>
                            <p className={styles['paragraph']}>{item.title}</p>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

/**
 * ## Exports
 */
export default PrivacySummaryList;
