/**
 * # Terms of Use
 */

/**
 * ## Imports
 */
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import clsx from 'clsx';
import { ClientName } from 'client/clientConfig';

import PageFooter from 'common/components/PageFooter/PageFooter';
import PageHeader from 'common/components/PageHeader/PageHeader';
import CopyText from 'common/components/CopyText';
import GetTermsContent from './utils/GetTermsContent';
import { submitMetrics } from '../../lib/submitMetrcis';
import { queryStringToObject } from '../../lib/query';

/**
 * ## Component
 */
const TermsPage = () => {
    const isSonicClient = ClientName === 'sonic';
    const isAdblockClient = ClientName === 'adblock';
    const location = useLocation();
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''}`;
    const updatedOn = isSonicClient ? 'February 15, 2024' : 'January 28, 2024';

    useEffect(() => {
        submitMetrics({
            Category: 'BrowserLandingPage',
            Action: 'view',
            Object: 'webpage',
            ReferalSource: queryStringToObject(location?.search || ''),
        });
    });

    return (
        <div className={appClassName}>
            {(isSonicClient || isAdblockClient) && (
                <PageHeader clientName={ClientName} />
            )}
            <div className={clsx('follows-pageheader framed-section framed-section--less-margin-bottom', {
                'framed-section--adblock': isAdblockClient,
            })}
            >
                <div className="centerwrapper centerwrapper--very-narrow">
                    <h1 className="headline-main">Terms of Service</h1>
                    <h3 className="headline-exception-sextary">These Terms were last updated on {updatedOn}</h3>
                </div>
            </div>
            <CopyText clientName={ClientName}>{GetTermsContent()}</CopyText>
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default TermsPage;
