export const queryStringToObject = (queryString: string) => {
    if (!queryString || (queryString?.length === 0)) {
        return {};
    }

    return queryString
        .slice(1)
        .split('&')
        .map((p) => p.split('='))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
};
