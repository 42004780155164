/**
 * # FAQ
 */

/**
 * ## Imports
 */
import React, { useMemo } from 'react';
import clsx from 'clsx';
import styles from './faq.module.scss';
import Accordion from '../Accordion/Accordion';
import { getGeneralFAQs } from './utils/getGeneralFAQs';
import { getPrivacyFAQs } from './utils/getPrivacyFAQs';
import { getAdblockFAQs } from './utils/getAdblockFAQs';

/**
 * ## Component output
 */
type FAQProps = {
    title?: string;
    clientName?: string;
}

const FAQ = ({ title, clientName }: FAQProps) => {
    const isAdblockClient = useMemo(() => clientName === 'adblock', [clientName]);

    const generalData = useMemo(() => getGeneralFAQs(styles), []);
    const privacyData = useMemo(() => getPrivacyFAQs(styles), []);

    const adblcokData = useMemo(() => getAdblockFAQs(styles), []);

    const data = useMemo(() => {
        if (isAdblockClient) return adblcokData;

        return [...generalData, ...privacyData];
    }, [isAdblockClient, adblcokData, generalData, privacyData]);

    return (
        <section
            id="faq"
            className={clsx('centerwrapper', styles['wrapper'], {
                [styles['adblock']]: isAdblockClient,
            })}
        >
            <h2 className={styles['headline']}>{title || 'FAQ'}</h2>
            <Accordion data={data} clientName={clientName} />
        </section>
    );
};

export default FAQ;
