/**
 * # About Page
 */

/**
 * ## Imports
 */
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ClientName } from 'client/clientConfig';
import CtaTeaser from 'common/components/CtaTeaser/CtaTeaser';
import PageFooter from 'common/components/PageFooter/PageFooter';
import PageHeader from 'common/components/PageHeader/PageHeader';
import GlobalTeaser from 'common/components/GlobalTeaser/GlobalTeaser';
import { submitMetrics } from '../../lib/submitMetrcis';
import { queryStringToObject } from '../../lib/query';

/**
 * ## Component
 */
const AboutPage = () => {
    const location = useLocation();
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''} app--about`;
    const isTempestClient = ClientName === 'tempest';

    useEffect(() => {
        submitMetrics({
            Category: 'BrowserLandingPage',
            Action: 'view',
            Object: 'webpage',
            ReferalSource: queryStringToObject(location?.search || ''),
        });
    });

    return (
        <div className={appClassName}>
            <h1 className="sr-only">Sonic Browser - About</h1>
            <PageHeader clientName={ClientName} />
            <CtaTeaser clientName={ClientName} classList={!isTempestClient ? 'follows-pageheader framed-section' : ''} />
            <GlobalTeaser />
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default AboutPage;
