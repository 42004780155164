import clsx from 'clsx';
import SettingsIcon from 'common/images/sonic_browser/icons/settings-icon.png';
import OptionsIcon from 'common/images/sonic_browser/icons/options-icon.png';
import AutostartInstructionIcon from 'common/images/adblock_browser/auto-start-instruction.png';
import BlockAdsInstruction from 'common/images/adblock_browser/block-ads-instruction.png';
import AdsSupportInstruction from 'common/images/adblock_browser/ads-support-instruction.png';

export const getAdblockFAQs = (styles) => {
    return [
        {
            summary: 'What is Adblock Browser?',
            content: <p>Adblock Browser is a safe and secure web browser with built-in and easy-to-use privacy settings. It features built-in ad blocker, invisible trackers blocker, anti-fingerprinting and end-to-end encryption across devices so you can have a better web experience and stay safe online.</p>,
        },
        {
            id: 'security',
            summary: 'What are Adblock Browser’s innovative security features?',
            content: <p>Adblock leverages the same underlying mechanism to block malicious websites, as SafeBrowsing, yet differently. It has built-in a Smart Aggregator Service that pulls information from multiple reputable sources. Adblock ensures better coverage over the security landscape, offering you a better protection.</p>,
        },
        {
            id: 'why-faster',
            summary: 'Why is Adblock Browser faster than traditional browsers?',
            content: <p>While browsing with Adblock some unwanted content like cookies, trackers and ads can be blocked. This allows websites to load and display faster. With it&lsquo;s one-click cookie consent pop-up blocker, the browser experience is uninterrupted, allowing you to reach the website&lsquo;s content faster. Adblock is using advanced parallel downloading technology, where files are split up into smaller parts and download simultaneously to get you your content faster</p>,
        },
        {
            id: 'available-os',
            summary: 'Is Adblock available for my device or Operating System?',
            content: (
                <div className={styles['mb-20']}>
                    <p>Adblock is currently available for most recent and popular versions of Windows and MacOS.</p>
                    <p>To use Adblock browser on <span className={styles['bold']}>Windows</span>, you&lsquo;ll need:</p>
                    <ul className={clsx(styles['list'], styles['list__no-gap'])}>
                        <li>Windows 10 or later or Windows Server 2016 or later</li>
                    </ul>
                    <p>To use Adblock browser on <span className={styles['bold']}>Mac</span>, you&lsquo;ll need:</p>
                    <ul className={clsx(styles['list'], styles['list__no-gap'])}>
                        <li>macOS Catalina 10.15 or later, working with both Intel and Apple Silicon processors (M1+). </li>
                    </ul>
                </div>
            ),
        },
        {
            id: 'how-to-install',
            summary: 'How to install Adblock?',
            content: (
                <>
                    <ol className={clsx(styles['list'], styles['list__numeric'])}>
                        <li>Go to: <a href="https://adblock-browser.com/">www.adblock-browser.com</a></li>
                        <li>Click on any <span className={styles['bold']}>Download</span> button and wait for the download to finish.</li>
                        <li>Launch the installer (double click it or Run it directly).</li>
                        <li>Click the <span className={styles['bold']}>Install</span> button to accept the License Agreement and EULA.</li>
                        <li>Wait until <span className={styles['bold']}>Adblock</span> is installed and configures its features.</li>
                        <li>Browse safely!</li>
                    </ol>
                    <ul className={styles['list']}>
                        <li>during the install process you can always opt-out of setting Adblock as default browser or pinning it to your dock/taskbar</li>
                    </ul>
                </>
            ),
        },
        {
            summary: 'Why is my antivirus blocking Adblock?',
            content: (
                <>
                    <p>If you cannot download or install the Adblock browser, or if you receive a message from your antivirus about blocking Adblock, please contact: <a href="mailto:support@adblockplus.org">support@adblockplus.org</a>.</p>
                    <p>Please check that your antivirus software is up-to-date, as it updates on a very frequent basis.</p>
                </>
            ),
        },
        {
            id: 'extensions',
            summary: 'How do I add extensions in Adblock?',
            content: (
                <ol className={clsx(styles['list'], styles['list__numeric'])}>
                    <li>Go to the <a href="https://chromewebstore.google.com/">Chrome Web Store</a></li>
                    <li>Search for your extension and click Add extension.</li>
                    <li>Your extension will automatically be added to Adblock.</li>
                </ol>
            ),
        },
        {
            id: 'customize',
            summary: 'How do I customize Adblock?',
            content: <p className={styles['with-icon-text']}>Adblock offers multiple ways of customization ranging from themes, wallpapers, WOW cards and shortcuts. You can simply access them all from your NewTab’s settings button <img className={styles['icon']} src={SettingsIcon} width={30} alt="Settings button" /> located in the top right side.</p>,
        },
        {
            summary: 'What are WOW cards?',
            content: <p className={styles['with-icon-text']}>WOW cards are widgets displayed on the left side of your NewTab that display information about the weather, trackers blocked and others. You can turn them on / off or customize them by using the NewTab settingd button <img className={styles['icon']} src={SettingsIcon} width={30} alt="Settings button" /> top right.</p>,
        },
        {
            summary: 'What are Shortcuts?',
            content: <p className={styles['with-icon-text']}>Shortcuts, tiles or speed dials are links that can be added and customized to access faster your favorite websites. You can turn them on / off or customize the amount by using the NewTab settings button <img className={styles['icon']} src={SettingsIcon} width={30} alt="Settings button" /> top right.</p>,
        },
        {
            summary: 'How do I delete my browsing history and cache?',
            content: (
                <>
                    <ol className={clsx(styles['list'], styles['list__numeric'])}>
                        <li>At the top right corner, click on the Menu button <img className={styles['icon']} src={OptionsIcon} width={20} alt="Settings button" /></li>
                        <li>Select <span className={styles['bold']}>Clear browsing data</span>.</li>
                        <li>Choose which data to delete and click OK.</li>
                    </ol>
                    <p>To ensure that no cookies, cache, or browsing and search history are stored, you can also use the Incognito Window.</p>
                </>
            ),
        },
        {
            summary: 'How to enable or disable starting Adblock automatically?',
            content: (
                <>
                    <p>Adblock offers on the installation screen the option to automatically start-up with your OS for a better experience. This option can be easily enabled or disabled later on.</p>
                    <p>Use Adblock’s <span className={styles['bold']}>Settings</span> go to <span className={styles['bold']}>System &gt; Launch Adblock Browser at startup</span> and toggle the button on/off.</p>
                    <img className={styles['huge-img']} src={AutostartInstructionIcon} alt="Autostart instruction" />
                    <p>If you encounter any issues, please contact: <a href="mailto:support@adblockplus.org">support@adblockplus.org</a>.</p>
                </>
            ),
        },
        {
            summary: 'How to uninstall Adblock?',
            content: (
                <>
                    <p className={styles['bold']}>For Windows:</p>
                    <ol className={clsx(styles['list'], styles['list__numeric'])}>
                        <li>Go to <span className={styles['bold']}>Control Panel &gt; Programs and Features &gt; Uninstall a program</span>.</li>
                        <li>Find Adblock in the list of installed programs, right-click it and select <span className={styles['bold']}>Uninstall</span>.</li>
                        <li>Confirm the uninstallation.</li>
                    </ol>
                    <p className={styles['bold']}>For MacOS:</p>
                    <ol className={clsx(styles['list'], styles['list__numeric'])}>
                        <li>Quit Adblock: go to the menu bar and select <span className={styles['bold']}>Adblock &gt; Quit Adblock Browser</span>.</li>
                        <li>Open a new <span className={styles['bold']}>Finder</span> window.</li>
                        <li>Open your user <span className={styles['bold']}>Applications</span> folder.</li>
                        <li>Right-click on Adblock Browser.app and select <span className={styles['bold']}>Move to Trash</span>.</li>
                        <li>Right-click on your Trash and select <span className={styles['bold']}>Empty Trash</span>.</li>
                    </ol>
                    <p>If you encounter any issues, please contact: <a href="mailto:support@adblockplus.org">support@adblockplus.org</a>.</p>
                </>
            ),
        },
        {
            summary: 'What are ads?',
            content: <p>Ads are short for advertisements. Businesses or individuals create ads to sell their product and pay to have them on websites. Ads are often part of an advertising network, which use cookies extensively to display targeted advertising. Adblock offers a built-in ad blocker which lets you block such advertisements.</p>,
        },
        {
            summary: 'How do I block ads?',
            content: (
                <>
                    <p>To block ads, you must have the Block Ads and Trackers option enabled. To do that go to Adblock’s <span className={styles['bold']}>Settings &gt; Privacy and Security &gt; Ad and Tracker blocking &gt; Block Ads</span></p>
                    <img className={styles['huge-img']} alt="Block ads instruction" src={BlockAdsInstruction} />
                </>
            ),
        },
        {
            summary: 'Why should I allow ads to display on a website?',
            content: (
                <>
                    <p>Advertising is what helps pay for website costs like web hosting, site maintenance, licensing fees, content and more. By allowing advertising, the website earns revenue when you see ads. By allowing them to display, you directly support the websites you enjoy most.</p>
                    <p>To allow ads and directly support a website <span className={styles['bold']}>click the Shield</span> icon next to the website address and <span className={styles['bold']}>turn off</span> the Ads and Trackers <span className={styles['bold']}>button</span></p>
                    <img className={styles['mid-img']} alt="Block ads instruction" src={AdsSupportInstruction} />
                </>
            ),
        },
        {
            summary: 'What are cookies?',
            content: (
                <>
                    <p>A cookie is a small text file that is stored in your browser. A cookie is created when you visit a website. This allows the website to keep track of your movements on the website, and to remember your login information, items in your cart and other preferences.</p>
                    <p>While first-party cookies (from the actual website) post no privacy issues, third-party cookies (from other companies or websites) are much more invasive.</p>
                    <p>Third-party cookies can track your online behavior all across the web. This helps advertising companies create deep profiles based on your interests, which they can then use to display targeted advertising.</p>
                    <p>All third-party cookies can be blocked when using Adblock browser if Trackers blocking is enabled. Shield will show you how many cookies have been blocked on a given page. Moreover, Adblock features a one-click solution for cookie consent pop-ups. Set your consent preferences once and let Adblock deal with all consent pop-ups for all the website you visit, for an uninterrupted browsing experience.</p>
                </>
            ),
        },
        {
            summary: 'What are trackers?',
            content: (
                <>
                    <p>An invisible tracker is a small piece of code, often called web beacon, web bug or web pixel. It is used extensively in advertising, because it can monitor what you are doing online—sites you visit, ads you click on and purchases you make. Trackers can be directly integrated into the code of a website or an email.</p>
                    <p>Adblock browser can help you block them, and using Shield you can see how many trackers have been blocked on a given page.</p>
                </>
            ),
        },
        {
            id: 'shield',
            summary: 'What is Shield?',
            content: <p>Shield shows you what is the current status of blocking ads or trackers on sites. Look for the shield icon to the left of the address bar. When you visit a website the Shield icon might show different states depending on your privacy settings: stroked shield when disabled, standard shield when partially blocking, shield with check mark when blocking all. You can also click it to toggle blocking on/off for specific websites.</p>,
        },
        {
            id: 'https-redirect',
            summary: 'What is HTTPS Redirect?',
            content: (
                <>
                    <p>Websites often accept two protocols which allow you to connect to the website. The original, HTTP, has been around since the beginning of the internet. HTTPS, however, is newer and it encrypts your data on the website. This is especially important for sensitive information.</p>
                    <p>Where possible, Adblock will redirect users to the more secure HTTPS version of a website.</p>
                </>
            ),
        },
        // {
        //     id: 'anti-fingerprinting',
        //     summary: 'What is Anti-Fingerprinting?',
        //     content: <p>Anti-Fingerprinting is a feature built into Adblock when the highest privacy setting is set, to combat a &ldquo;digital fingerprint&rdquo; being created with your data. To limit your browser being identified, we continually randomize your device information. This means that you will appear as a different user across the web, even if you return to the same website.</p>,
        // },
        {
            summary: 'Some websites act strange or don’t load all elements. What’s wrong?',
            content: <p>Because of the high privacy settings, some websites may not load correctly in ‘Block Ads and Trackers’ mode. If you encounter this, we suggest that you temporary disable blocking by using the Shield or switch to lower level of blocking via browser settings.</p>,
        },
    ];
};
