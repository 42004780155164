/**
 * # App
 */

/**
 * ## Imports
 */
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ClientName } from 'client/clientConfig';

import CtaTeaser from 'common/components/CtaTeaser/CtaTeaser';
import AdvantagesList from 'common/components/AdvantagesList/AdvantagesList';
import PageHeader from 'common/components/PageHeader/PageHeader';
import PageFooter from 'common/components/PageFooter/PageFooter';

import FeatureTeaserList from 'common/components/FeatureTeaserList/FeatureTeaserList';
import Testimonials from 'common/components/Testimonials/Testimonials';
import ExperienceFeature from 'common/components/ExperienceFeature/ExperienceFeature';
import FAQ from 'common/components/FAQ/faq';
import SmarterBrowsing from 'common/components/SmarterBrowsing/SmarterBrowsing';
import { getAdvantagesListContent } from './utils/getAdvantagesListContent';
import { getFeatureTeaserListContent } from './utils/getFeatureTeaserListContent';
import { submitMetrics } from '../../lib/submitMetrcis';
import { queryStringToObject } from '../../lib/query';

/**
 * ## Component
 */
const HomePage = () => {
    const isSonicClient = ClientName === 'sonic';
    const isTempestClient = ClientName === 'tempest';
    const isAdblockClient = ClientName === 'adblock';

    const slides = getAdvantagesListContent(ClientName);

    // const activeClient = process.env.REACT_APP_ENV;
    const location = useLocation();

    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''} app--home`;

    useEffect(() => {
        submitMetrics({
            Category: 'BrowserLandingPage',
            Action: 'view',
            Object: 'webpage',
            ReferalSource: queryStringToObject(location?.search || ''),
        });
    });

    /**
     * ### Text Content
     */
    let pageHeadline;
    if (isSonicClient) {
        pageHeadline = 'Sonic Browser - Home';
    } else if (isAdblockClient) {
        pageHeadline = 'AdBlock Browser - Home';
    } else {
        pageHeadline = 'Tempest Browser - Home';
    }

    /**
     * ### Returned JSX
     */
    return (
        <div className={appClassName}>
            <h1 className="sr-only">{pageHeadline}</h1>
            {!isTempestClient && (
                <PageHeader clientName={ClientName} />
            )}
            <CtaTeaser clientName={ClientName} classList={!isTempestClient ? 'follows-pageheader' : ''} />
            {(isTempestClient || isSonicClient) && <AdvantagesList clientName={ClientName} slideList={slides} />}
            {isAdblockClient && (
                <>
                    <SmarterBrowsing />
                    <Testimonials clientName={ClientName} />
                    <ExperienceFeature clientName={ClientName} />
                    <FAQ clientName={ClientName} />
                </>
            )}
            {isSonicClient && (
                <>
                    <Testimonials clientName={ClientName} />
                    <FeatureTeaserList
                        clientName={ClientName}
                        contentData={getFeatureTeaserListContent(ClientName)}
                    />
                    <FAQ />
                </>
            )}
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default HomePage;
