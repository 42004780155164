/**
 * # Company Logo
 */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx, { ClassValue } from 'clsx';
import styles from './CompanyLogo.module.scss';

/**
 * ## Types / Interfaces
 */
interface ICompanyLogoProps {
    alwaysBrightLogo?: boolean;
    svgComponent: React.ReactElement;
    width?: number;
    height?: number;
    classList?: string | string[];
    link?: string;
    clientName?: string;
}

/**
 * ## Component
 */
const CompanyLogo = ({
    alwaysBrightLogo = true,
    svgComponent,
    width = 274,
    height = 60,
    classList = undefined,
    link = undefined,
    clientName,
}: ICompanyLogoProps): JSX.Element => {
    const isSonicClient = clientName === 'sonic';
    const isAdblockClient = clientName === 'adblock';
    const isTempestClient = clientName === 'tempest';

    const logoIconClassName = clsx({
        [styles['logo-icon-sonic']]: isSonicClient,
        [styles['logo-icon-tempest']]: isTempestClient,
        [styles['logo-icon-adblock']]: isAdblockClient,
    });
    /**
     * - To permanently display the bright version of the logo, pass in the
     * property `alwaysBrightLogo={true}`:
     */
    const svgClass: ClassValue = clsx(
        'logo-svg',
        {
            'logo-svg--bright': alwaysBrightLogo,
        },
        logoIconClassName,
        classList,
    );
    const wrapperClassName = clsx(styles['company-logo-wrapper'], {
        [styles['company-logo-wrapper--sonic']]: isSonicClient,
        [styles['company-logo-wrapper--adblock']]: isAdblockClient,
    });

    const logoContent = React.cloneElement(svgComponent, { width, height, className: svgClass });

    if (typeof link === 'string') {
        return (
            <div className={wrapperClassName}>
                <a href={link} className={styles['company-logo-wrapper__link']}>
                    {logoContent}
                </a>
            </div>
        );
    }
    return (
        <div className={styles['company-logo-wrapper']}>
            {logoContent}
        </div>
    );
};

export default CompanyLogo;
