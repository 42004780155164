/**
 * # Feature Teaser List
 */
/**
 * ## Imports
 */
import React from 'react';
import FeatureTeaser from '../FeatureTeaser/FeatureTeaser';
import styles from './FeatureTeaserList.module.scss';

/**
 * ## Types / Interfaces
 */
type FeatureTeaserListProps = {
    clientName: string;
    contentData: {
        title: string;
        description: string;
        imageSrcBig: string;
        imageSrcSmall: string;
        ctaLabel: string;
    }[];
}

/**
 * ## Component
 */
const FeatureTeaserList: React.FC<FeatureTeaserListProps> = ({ clientName, contentData }) => {
    return (
        <section className={`${styles['wrapper']} centerwrapper`}>
            <h2 className={styles['headline']}>The best features in one browser</h2>
            {contentData && (
                <ul className={styles['teaser-list']}>
                    {contentData.map((item, index) => (
                        <FeatureTeaser
                            clientName={clientName}
                            title={item.title}
                            description={item.description}
                            imageSrcBig={item.imageSrcBig}
                            imageSrcSmall={item.imageSrcSmall}
                            ctaLabel={item.ctaLabel}
                            activateButton={index + 1 === contentData.length}
                            index={index}
                            key={index}
                        />
                    ))}
                </ul>
            )}
        </section>
    );
};

export default FeatureTeaserList;
