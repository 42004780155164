/**
 * # Customization Feature Teaser
 */

/**
 * ## Imports
 */
import React from 'react';
import { getCustomizationFeatureTeaserContent } from './utils/getCustomizationFeatureTeaserContent';
import styles from './CustomizationFeatureTeaser.module.scss';

/**
 * ## Component
 */
const CustomizationFeatureTeaser: React.FunctionComponent = () => {
    const customizationFeatures = getCustomizationFeatureTeaserContent();

    return (
        <div className={`${styles['wrapper']} centerwrapper`}>
            {/* <picture>
                <source
                    media="(max-width: 759px)"
                    srcSet={ctaTeaserSmallImageSrcSonic}
                />
                <source
                    media="(min-width: 760px)"
                    srcSet={ctaTeaserBigImageSrcSonic}
                />
                <img
                    // eslint-disable-next-line
                    alt={`Sonic Browser Customization Options`}
                    src={ctaTeaserSmallImageSrcSonic}
                    className={styles['visual-image']}
                />
            </picture> */}
            {customizationFeatures.length > 0 && (
                <ul className={styles['layout-list']}>
                    {customizationFeatures.map((feature, index) => (
                        <li key={index} className={styles['list-item']}>
                            <div className={styles['icon-wrapper']}>
                                {feature.svgComponent}
                            </div>
                            <div className={styles['text-wrapper']}>
                                <h3 className={styles['headline']}>{feature.title}</h3>
                                <p className={styles['copytext']}>{feature.description}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

/**
 * ## Exports
 */
export default CustomizationFeatureTeaser;
