/**
 * # Testimonials
 */

/**
 * ## Imports
 */
import { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as NavRight } from 'common/images/adblock_browser/icons/nav-right.svg';
import styles from './Testimonials.module.scss';
import getReviews from './utils/getReviews';

/**
 * ## Component
 */
const Testimonials = ({ clientName }: {
    clientName: string;
}) => {
    const reviews = getReviews();
    const isSonicClient: boolean = clientName === 'sonic';
    const isAdblockClient: boolean = clientName === 'adblock';

    const [activeIndex, setActiveIndex] = useState(0);

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === (reviews.length - 1) ? prevIndex : prevIndex + 1));
    };

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? prevIndex : prevIndex - 1));
    };

    return (
        <div className={clsx(styles['wrapper'], {
            [styles['adblock']]: isAdblockClient,
        })}
        >
            <h2 className={clsx(styles['headline'], {
                [styles['headline--adblock']]: isAdblockClient,
            })}
            >
                {isSonicClient ? 'People love to use Sonic' : <span>People love to use <strong>Adblock</strong><span className={styles['tiny']}>Browser</span></span>}
            </h2>

            <div className={styles['reviews-wrapper']}>
                <div className={clsx(styles['reviews'], styles[`review-${activeIndex}`])}>
                    {reviews.map((review, index) => (
                        <div key={`${review.review}-${index}`} className={styles['review']}>
                            <p className={styles['review--text']}>{review.review}</p>
                            <div className={styles['review--author']}>
                                <span>{review.stars}</span>
                                <span>{review.name}</span>
                            </div>
                        </div>
                    ))}
                </div>

                <div aria-hidden className={clsx(styles['reviews'], { [styles['hide-mobile']]: isAdblockClient })}>
                    {reviews.map((review, index) => (
                        <div key={`${review.name}--${index}`} className={styles['review']}>
                            <p className={styles['review--text']}>{review.review}</p>
                            <div className={styles['review--author']}>
                                <span>{review.stars}</span>
                                <span>{review.name}</span>
                            </div>
                        </div>
                    ))}
                </div>

                {isAdblockClient && (
                    <>
                        <NavRight
                            onClick={handleNext}
                            className={clsx(styles['nav'], styles['nav--right'], {
                                [styles['nav--disabled']]: activeIndex === (reviews.length - 1),
                            })}
                        />
                        <NavRight
                            onClick={handlePrev}
                            className={clsx(styles['nav'], styles['nav--left'], {
                                [styles['nav--disabled']]: activeIndex === 0,
                            })}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default Testimonials;
