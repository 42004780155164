/**
 * # Device Teaser
 */

/**
 * ## Imports
 */
import React from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { osName } from 'react-device-detect';
import { ClientName } from 'client/clientConfig';
import { useDownload } from 'hooks/useDownload';
import { ctaButtonTypes } from 'constants/buttonTypes';
import deviceTeaserSmallImageSrc from '../../images/sonic_browser/sonic-device-teaser-illustration-small.png';
import deviceTeaserBigImageSrc from '../../images/sonic_browser/sonic-device-teaser-illustration-big.png';
import { ReactComponent as WindowsIcon } from '../../images/sonic_browser/icons/ms-windows.svg';
import { ReactComponent as AppleIcon } from '../../images/sonic_browser/icons/apple.svg';
import { ReactComponent as AndroidIcon } from '../../images/sonic_browser/icons/android.svg';
import styles from './DeviceTeaser.module.scss';
import ButtonCta from '../ButtonCta';

/**
 * ## Types / Interfaces
 */

/**
 * ## Component
 */
const DeviceTeaser: React.FC = () => {
    const iOSURL = process.env.REACT_APP_DOWNLOADS_IOS_URL;
    const androidURL = process.env.REACT_APP_DOWNLOADS_ANDROID_URL;
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoading, downloadBrowser } = useDownload(location?.search || '');
    const { download } = ctaButtonTypes.downloadButtons;
    const isSonicClient = ClientName === 'sonic';

    const isDownloadFeaturePage = location.pathname.includes('download-windows-b');

    const handleDownloadButton = () => {
        if (isSonicClient) {
            navigate('/download', { state: { isNewLayout: true } });
        } else {
            downloadBrowser(download);
        }
    };

    return (
        <>
            <section className={styles['illustration-section']}>
                <div className={clsx(styles['illustration-section__centerwrapper'], styles['centered-content'])}>
                    {/* {!isDownloadFeaturePage && (
                        <div className={styles['illustration-section__text-body']}>
                            <h2 className={styles['illustration-section__headline']}>Available on all devices</h2>
                            <h3 className={styles['illustration-section__subline']}>With Sonic, you can browse securely from anywhere.</h3>
                        </div>
                    )} */}
                    <picture className={styles['illustration-wrapper']}>
                        <source media="(max-width: 759px)" srcSet={deviceTeaserSmallImageSrc} />
                        <source media="(min-width: 760px)" srcSet={deviceTeaserBigImageSrc} />
                        <img
                            className={styles['illustration']}
                            src={deviceTeaserSmallImageSrc}
                            alt="Sonic Browser"
                        />
                    </picture>
                </div>
            </section>
            {(isDownloadFeaturePage && osName === 'Windows') && (
                <div className={clsx(styles['button-wrapper'], styles['continue-button'])}>
                    <ButtonCta
                        clientName={ClientName}
                        label="Continue"
                        arrowIndicator
                        layoutAlt={false}
                        layoutGlowBottom={isSonicClient}
                        large
                        isAnimated={false}
                        onClick={handleDownloadButton}
                        hasAnimatedBorder={false}
                        isButtonDisabled={isLoading}
                    />
                </div>
            )}
            <section className={styles['os-section']}>
                <div className={styles['os-section__centerwrapper']}>
                    <div className={styles['os-section__text-body']}>
                        <h2 className={styles['os-section__headline']}>Soon available on all devices</h2>
                        <h3 className={styles['os-section__subline']}>Our fast, personalized browser on-the-go convenience.</h3>
                    </div>
                    <ul className={styles['os-list']}>
                        <li className={styles['os-list__item']}>
                            <WindowsIcon className={styles['os-list__icon']} />
                            <h4 className={styles['os-list__headline']}>Windows</h4>
                            <p className={styles['os-list__copytext']}>With Windows, you can download the Sonic Browser and enjoy speedy performance and connect to Android or iOS Sonic browsers.</p>
                        </li>
                        <li className={clsx(styles['os-list__item'], [styles['os-list__item--inactive']])}>
                            <AppleIcon className={styles['os-list__icon']} />
                            <h4 className={styles['os-list__headline']}>Mac</h4>
                            {/* <p className={styles['os-list__copytext']}>Download Sonic Browser for Mac with features including voice commands, translator and personalization sync.</p> */}
                            <p className={styles['os-list__copytext']}>
                                Launching soon!
                            </p>
                        </li>
                        <li
                            className={clsx(
                                styles['os-list__item'],
                                {
                                    [styles['os-list__item--inactive']]: !iOSURL,
                                },
                            )}
                        >
                            <AppleIcon className={styles['os-list__icon']} />
                            <h4 className={styles['os-list__headline']}>iOS</h4>
                            <p className={styles['os-list__copytext']}>
                                {iOSURL ? 'Find Sonic Browser in the App Store to instantly sync with Mac, Windows and other iOS devices.' : 'Launching soon!'}
                            </p>
                        </li>
                        <li
                            className={clsx(
                                styles['os-list__item'],
                                {
                                    [styles['os-list__item--inactive']]: !androidURL,
                                },
                            )}
                        >
                            <AndroidIcon className={styles['os-list__icon']} />
                            <h4 className={styles['os-list__headline']}>Android</h4>
                            <p className={styles['os-list__copytext']}>
                                {androidURL ? 'Launching soon!' : 'Launching soon!'}
                            </p>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
};

/**
 * ## Exports
 */
export default DeviceTeaser;
