/**
 * # Get User License Agreement Content
 */
/**
 * ## Imports
 */
import { Link } from 'react-router-dom';
import { ClientName } from 'client/clientConfig';

/* eslint-disable-next-line react/no-unescaped-entities */

/**
 * ## Output
 */
// eslint-disable-next-line
const GetUserLicenseAgreementContent = () => {
    const isSonicClient = ClientName === 'sonic';
    const isAdblockClient = ClientName === 'adblock';
    const browserName = isSonicClient ? 'Sonic' : 'Adblock';
    const mail = isSonicClient ? 'info@sonicbrowser.com' : 'support@adblockplus.org';

    if (isAdblockClient) {
        return (
            <>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <p className="policy-intro-text">This AdBlock Browser End User License Agreement ("<strong>Agreement</strong>") is a binding agreement between you ("<strong>End User</strong>" or "<strong>you</strong>") and Eyeo GmbH (“<strong>Eyeo</strong>”, "<strong>Company</strong>"). This Agreement governs your use of the AdBlock Browser web browser in executable form, (including all related documentation, the "<strong>Application</strong>"), from a compatible web browser. The Application is licensed, not sold, to you.</p>

                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <p className="policy-intro-text uppercase">By clicking the "agree" button/downloading/installing/using the application, you (a) acknowledge that you have read and understand this agreement; (b) represent that you are of legal age to enter into a binding agreement; and (c) accept this agreement and agree that you are legally bound by its terms. If you do not agree to these terms, do not download/install/use the application and delete it from your mobile device.</p>

                <ol className="copy-list copy-list--ordered copy-list--box">
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">License Grant. Subject to the terms of this Agreement, Eyeo grants you a limited, non-exclusive, and non transferable license to:</h3>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <p className="copy-list__text">(a) download, install, and use, via a compatible web browser, the Application for your personal, non-commercial use to a single computer, mobile device, or other device owned or otherwise controlled by you (each a "Device") strictly in accordance with the Application's documentation/; and <br /> (b) access, stream, download, and use on such Device the Content and Services (as defined in 5) made available in or otherwise accessible through the Application, strictly in accordance with this Agreement and the Terms of Use applicable to such Content and Services as set forth in 5.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">License Restrictions. You shall not:</h3>
                        <p className="copy-list__text">(a) copy the Application, except as expressly permitted by this license;<br />
                            (b) modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Application;<br />
                            (c) reverse engineer, disassemble, decompile, decode, or other wise attempt to derive or gain access to the source code of theApplication or any part thereof;<br />
                            (d) remove, delete, alter, or obscure any trademarks or any copyright,   trademark, patent, or other intellectual property or proprietary rights   notices from the Application, including any copy there of;<br />
                            (e) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Application, or any features or functionality of the Application, to any third party for any reason, including by making the Application available on a network where it is capable of being accessed by more than one device at anytime; or<br />
                            (f) remove, disable, circumvent, or otherwise create or implement any   work around to any copy protection, rights management, or security features in or protecting the Application; or<br />
                            (g) use the Application in, or in association with, the design, construction, maintenance, or operation of any hazardous environments or systems, including any power generation systems; aircraft navigation or communication systems, air traffic control systems, or any other transport management systems; safety-critical applications, including medical or life-support systems, vehicle operation applications or any police, fire, or other safety response systems; and military or aerospace applications, weapons systems, or environments.
                        </p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Reservation of Rights.</h3>
                        <p className="copy-list__text">You acknowledge and agree that the Application is provided under license, and not sold, to you. You do not acquire any ownership interest in the Application under this Agreement, or any other rights thereto other than to use the Application in accordance with the license granted, and subject to all terms, conditions, and restrictions, under this Agreement. Eyeo and its licensors and service providers reserve and shall retain their entire right, title, and interest in and to the Application, including all copyrights, trademarks, and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Collection and Use of Your Information.</h3>
                        <p className="copy-list__text">You acknowledge that when you download, install, or use the Application, Eyeo may use automatic means (including, for example, cookies and web beacons) to collect information about your Device and about your use of the Application. You also may be required to provide certain information about yourself as a condition to downloading, installing, or using the Application or certain of its features or functionality, and the Application may provide you with opportunities to share information about yourself with others. All information we collect through or in connection with this Application is subject to our Privacy Policy at: <Link to="/privacy-policy">Privacy Policy</Link>. By downloading, installing, using, and providing information to or through this Application, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>
                    </li>
                    <li className="copy-list__item">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <h3 className="headline-quaternary">Content and Services.</h3>
                        <p className="copy-list__text">The Application may provide you with access to the AdBlock Browser website located at www.adblockplus.org (the &ldquo;Website&rdquo;) and products and services accessible thereon, and certain features, functionality, and content accessible on or through the Application may be hosted on the Website (collectively, &ldquo;Content and Services&rdquo;). Your access to and use of such Content and Services are governed by Website&apos;s Terms of Use at: (<a href="https://adblockplus.org/en/terms">https://adblockplus.org/en/terms</a>) and Privacy Policy at: (<a href="https://adblockplus.org/en/privacy">https://adblockplus.org/en/privacy</a>), which are incorporated herein by this reference. Your access to and use of such Content and Services may require you to acknowledge your acceptance of such Terms of Use and Privacy Policy and/or to register with the Website, and your failure to do so may restrict you from accessing or using certain of the Application&apos;s features and functionality. Any violation of such Terms of Use will also be deemed a violation of this Agreement.</p>
                    </li>
                    <li className="copy-list__item">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <h3 className="headline-quaternary">Updates.</h3>
                        <p className="copy-list__text">Eyeo may from time to time in its sole discretion develop and provide Application updates, which may include upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including related documentation, &ldquo;Updates&rdquo;). Updates may also modify or delete in their entirety certain features and functionality. You agree that Eyeo has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. Based on your Device settings, when your Device is connected to the internet either:</p>
                        <p className="copy-list__text">(a) the Application will automatically download and install all available Updates; or</p>
                        <p className="copy-list__text">(b) you may receive notice of or be prompted to download and install available Updates.</p>
                        <p className="copy-list__text">You shall promptly download and install all Updates and acknowledge and agree that the Application or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the Application and be subject to all terms and conditions of this Agreement.</p>
                    </li>
                    <li className="copy-list__item">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <h3 className="headline-quaternary">Third-Party Materials.</h3>
                        <p className="copy-list__text">The Application may display, include, or make available third-party content (including data, information, applications, and other products, services, and/or materials) or provide links to third-party websites or services, including through third-party advertising (&ldquo;<strong>Third-Party Materials</strong>&rdquo;). You acknowledge and agree that Eyeo is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Eyeo does not assume and will not have any liability or responsibility to you or any other person or entity for any Third-Party Materials. Third-Party Materials and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties&apos; terms and conditions.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Term and Termination.</h3>
                        <p className="copy-list__text">
                            (a) The term of Agreement commences when you download/install the Application or acknowledge your acceptance and will continue in effect until terminated by you or Eyeo as set forth in this 8.<br /><br />
                            (b) You may terminate this Agreement by deleting the Application and all copies thereof from your Device.<br /><br />
                            (c) Eyeo may terminate this Agreement at any time without notice if it ceases to support the Application, which Eyeo may do in its sole discretion. In addition, this Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement.<br /><br />
                            (d) Upon termination:<br />&nbsp;&nbsp;&nbsp;(i) all rights granted to you under this Agreement will also terminate; and<br />&nbsp;&nbsp;&nbsp;(ii) you must cease all use of the Application and delete all copies of the Application from your Device and account.<br /><br />
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            (e) Termination will not limit any of Eyeo's rights or remedies at law or in equity.
                        </p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Disclaimer of Warranties.</h3>
                        <p className="uppercase copy-list__text">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            THE APPLICATION IS PROVIDED TO END USER "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, Eyeo, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, Eyeo PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
                        </p>
                        <p className="uppercase copy-list__text">SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Limitation of Liability.</h3>
                        <p className="uppercase copy-list__text">
                            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL Eyeo OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES FOR:
                        </p>
                        <p className="uppercase copy-list__text">
                            (a) PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.<br />
                            (b) DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION.
                        </p>
                        <p className="uppercase copy-list__text">THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR Eyeo WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Indemnification</h3>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <p className="copy-list__text">You agree to indemnify, defend, and hold harmless Eyeo and its officers, directors, employees, agents, affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys' fees, arising from or relating to your use or misuse of the Application or your breach of this Agreement, including but not limited to the content you submit or make available through this Application.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Export Regulation</h3>
                        <p className="copy-list__text">The Application may be subject to the export control laws of various countries, including without limit Ireland and the United States. You shall not, directly or indirectly, export, re-export, or release the Application to, or make the Application accessible from, any jurisdiction or country to which export, re-export, or release is prohibited by law, rule, or regulation. You shall comply with all applicable laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval), prior to exporting, re-exporting, releasing, or otherwise making the Application available outside of the subject jurisdiction or country.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Severability.</h3>
                        <p className="copy-list__text">If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect; provided, however, that if any fundamental term or provision of this Agreement is invalid, illegal, or unenforceable, the remainder of this Agreement shall be unenforceable.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Governing Law.</h3>
                        <p>This Agreement is governed by and construed in accordance with the substantive and procedural laws of the Republic of Ireland. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts of Ireland, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Limitation of Time to File Claims.</h3>
                        <p className="uppercase copy-list__text">ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE APPLICATION MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Entire Agreement.</h3>
                        <p className="copy-list__text">This Agreement, our Terms and Conditions and our Privacy Policy constitute the entire agreement between you and Eyeo with respect to the Application and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the Application.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Waiver.</h3>
                        <p className="copy-list__text">No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</p>
                    </li>
                    <li className="copy-list__item">
                        <h3 className="headline-quaternary">Contact Information</h3>
                        <p className="copy-list__text">You can contact us through the Applicationor by emailing us at <a href={`mailto:${mail}`}>{mail}</a>.</p>
                    </li>
                </ol>
            </>
        );
    }

    return (isSonicClient || isAdblockClient) ? (
        <>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p className="policy-intro-text">This {browserName} Browser End User License Agreement ("Agreement") is a binding agreement between you ("End User" or "you") and {browserName} Browser web browser (“{browserName} Browser”, or “Service”). This Agreement governs your use of the {browserName} Browser web browser in executable form, (including all related documentation, the "Application"), from a compatible web browser. The Application is licensed, not sold, to you.</p>

            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p className="policy-intro-text uppercase">By clicking the "agree" button/downloading/installing/using the application, you (a) acknowledge that you have read and understand this agreement; (b) represent that you are of legal age to enter into a binding agreement; and (c) accept this agreement and agree that you are legally bound by its terms. If you do not agree to these terms, do not download/install/use the application and delete it from your mobile device.</p>

            <ol className="copy-list copy-list--ordered copy-list--box">
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">License Grant. Subject to the terms of this Agreement, {browserName} Browser grants you a limited, non-exclusive, and non transferable license to:</h3>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <p className="copy-list__text">(a) download, install, and use, via a compatible web browser, the   Application for your personal, non-commercial use to a single computer,   mobile device, or other device owned or otherwise controlled by you   (each a "Device") strictly in accordance with theApplication's documentation/; and <br /> (b) access, stream, download, and use on such Device the Content and Services (as defined in 5) made available in or otherwise accessible   through the Application, strictly in accordance with this Agreement and   the Terms of Use applicable to such Content and Services as set forth in 5.</p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">License Restrictions. You shall not:</h3>
                    <p className="copy-list__text">(a) copy the Application, except as expressly permitted by this license;<br />
                        (b) modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Application;<br />
                        (c) reverse engineer, disassemble, decompile, decode, or other wise attempt to derive or gain access to the source code of theApplication or any part thereof;<br />
                        (d) remove, delete, alter, or obscure any trademarks or any copyright,   trademark, patent, or other intellectual property or proprietary rights   notices from the Application, including any copy there of;<br />
                        (e) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Application, or any features or functionality of the Application, to any third party for any reason, including by making the Application available on a network where it is capable of being accessed by more than one device at anytime; or<br />
                        (f) remove, disable, circumvent, or otherwise create or implement any   work around to any copy protection, rights management, or security features in or protecting the Application; or<br />
                        (g) use the Application in, or in association with, the design, construction, maintenance, or operation of any hazardous environments or systems, including any power generation systems; aircraft navigation or communication systems, air traffic control systems, or any other transport management systems; safety-critical applications, including medical or life-support systems, vehicle operation applications or any police, fire, or other safety response systems; and military or aerospace applications, weapons systems, or environments.
                    </p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Reservation of Rights. You acknowledge and agree that the Application is provided under license, and not sold, to you.   You do  not acquire any ownership interest in the Application under this Agreement, or any other rights thereto other than to use the Application in accordance with the license granted,   and subject to all terms, conditions, and restrictions, under   this Agreement. {browserName} Browser and its licensors and service providers reserve and shall retain their entire right, title, and   interest in and to the Application, including all copyrights, trademarks, and other intellectual property rights therein or  relating thereto, except as expressly granted to you in this Agreement.</h3>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Collection and Use of Your Information . You acknowledge   that when you download, install, or use the   Application,   {browserName} Browser may use automatic means (including, for example, cookies and web beacons) to collect information about your Device and about your use of the Application. You also may be required to provide certain information about yourself as a condition to downloading, installing, or using the Application or certain of its features or functionality, and the Application may provide you with opportunities to share information about yourself with others. All information we collect through   or in connection with this Application is subject to our Privacy Policy at: www.sonic-browser.com/privacy-policy. By downloading, installing, using, and providing information to or through this Application, you consent to all actions taken by us with respect  to your information in compliance with the Privacy Policy.</h3>
                </li>
                <li className="copy-list__item">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <h3 className="headline-quaternary">Content and Services. The Application may provide you with access to the {browserName} Browser website located at www.sonic-browser.com (the"Website") and products and services accessible there on, and certain features, functionality, and content accessible on or through the Application may be hosted on the Website (collectively, "Content and Services"). Your access to and use of such Content and Services are governed by Website's Terms of Use at: www.sonic-browser.com/terms-of-use and Privacy Policy at:www.sonic-browser.com/privacy-policy, which are incorporated here in by this reference. Your access to and use of such Content and Services may require you to acknowledge your acceptance of such Terms of Use and Privacy Policy and/or to register with the Website, and your failure to do so may restrict you from accessing or using certain of the Application's features and functionality. Any   violation of such Terms of Use will also be deemed aviolation of this Agreement.</h3>
                </li>
                <li className="copy-list__item">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <h3 className="headline-quaternary">Updates. {browserName} Browser  may from time to time in its sole discretion develop and provide Application updates, which may include upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including related documentation, "Updates"). Updates may also modify or delete in their entirety certain features and functionality. You agree that {browserName} Browser has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. Based on your Device settings, when your Device is connected to the internet either:</h3>
                    <p className="copy-list__text">(a) the Application will automatically download and install all available Updates; or(b) you may receive notice of or be prompted to download and install available Updates.</p>
                    <p className="copy-list__text">(b) you may receive notice of or be prompted to download and install available Updates.</p>
                    <h3 className="headline-quaternary">You shall promptly download and install all Updates and acknowledge and agree that the Application or portions there of may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the Application and be subject to all terms and conditions of this Agreement.</h3>
                </li>
                <li className="copy-list__item">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <h3 className="headline-quaternary">Third-Party Materials. The Application may display, include,   or make available third-party content (including data, information, applications, and other products, services, and/or materials) or provide links to third-party websites or services, including through third-party advertising ("Third-PartyMaterials"). You acknowledge and agree that {browserName} Browser is not responsible forThird-Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect there of. {browserName} Browser does not assume and will not have any liability or responsibility to you or any other person or entity for any Third-Party Materials. Third-Party Materials and links   thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</h3>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Term and Termination.</h3>
                    <p className="copy-list__text">
                        (a) The term of Agreement commences when you download/install the Application or acknowledge your acceptance and will continue in effect until terminated by you or {browserName} Browser as set forth in this 8.<br />
                        (b) You may terminate this Agreement by deleting the Application and all copies thereof from your Device.<br />
                        (c) {browserName} Browser may terminate this Agreement at any time without notice if it ceases to support the Application, which {browserName} Browser may do in its sole discretion. In addition, this Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement.<br />
                        (d) Upon termination:(i) all rights granted to you under this Agreement will also terminate; and(ii) you must cease all use of the Application and delete all copies of the Application from your Device and account.<br />
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        (e) Termination will not limit any of {browserName} Browser's rights or remedies at law or in equity.
                    </p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Disclaimer of Warranties.</h3>
                    <p className="uppercase copy-list__text">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        The application is provided to end user "as is" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, {browserName} Browser, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the application, including all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage, or trade practice. Without limitation to the foregoing, {browserName} Browser provides no warranty or undertaking, and makes no representation of any kind that the application will meet your requirements, achieve any intended results, be compatible, or work with any other software, applications, systems, or services, operate without interruption, meet any performance or reliability standards, or be error-free, or that any errors or defects can or will be corrected. Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.
                    </p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Limitation of Liability.</h3>
                    <p className="uppercase copy-list__text">
                        To the fullest extent permitted by applicable law, in no event will {browserName} Browser or its affiliates, or any of its or their respective licensors or service providers, have any liability arising from or related to your use of or inability to use the application or the content and services for:
                    </p>
                    <p>
                        (a) Personal injury, property damage, lost profits, cost of substitute goods or services, loss of data, loss of goodwill, business interruption, computer failure or malfunction, or any other consequential, incidental, indirect, exemplary, special, or punitive damages.<br />
                        (b) Direct damages in amounts that in the aggregate exceed the amount actually paid by you for the application.
                    </p>
                    <p>The foregoing limitations will apply whether such damages arise out of breach of contract, tort (including negligence), or otherwise and regardless of whether such damages were foreseeable or {browserName} Browser was advised of the possibility of such damages. Some jurisdictions do not allow certain limitations of liability so some or all of the above limitations of liability may not apply to you.</p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Indemnification</h3>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <p className="copy-list__text">You agree to indemnify, defend, and   hold harmless {browserName} Browser and its   officers,   directors, employees, agents,   affiliates, successors, and assigns   from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys' fees, arising from or relating to your use or misuse of the Application or your breach of this Agreement, including but not limited to the content you submit or make available through this Application.</p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Export Regulation</h3>
                    <p className="copy-list__text">The Application may be subject to the export control laws of various countries, including without limit Ireland and the United States. You shall not, directly or indirectly, export, re-export, or release the Application to, or make the Application accessible from, any jurisdiction or country to which export, re-export, or release is prohibited bylaw, rule, or regulation. You shall comply with all applicable laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval), prior to exporting, re-exporting, releasing, or otherwise making the Application available outside of the subject jurisdiction or country.</p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Severability.</h3>
                    <p className="copy-list__text">If any provision of this Agreement is illegal or un enforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect; provided,   however, that if any fundamental term or provision of this Agreement is invalid, illegal, or unenforceable, the remainder of this Agreement shall be unenforceable.</p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Governing Law.</h3>
                    <p>This Agreement is governed by and construed in accordance with the substantive and procedural laws of the Republic of Ireland. The exclusive jurisdiction and venue for actions related to the subject matter here of shall be the courts of Ireland, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.</p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Limitation of Time to File Claims.</h3>
                    <p className="uppercase copy-list__text">Any cause of action or claim you may have arising out of or relating to this agreement or the application must be commenced within one (1) year after the cause of action accrues otherwise such cause of action or claim is permanently barred.</p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Entire Agreement.</h3>
                    <p className="copy-list__text">This Agreement, our Terms and Conditions and our Privacy Policy constitute the entire agreement between you and {browserName} Browser with respect to   the Application and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the Application.</p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Waiver.</h3>
                    <p className="copy-list__text">No failure to exercise, and no delay in exercising, on the part of either party,   any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</p>
                </li>
                <li className="copy-list__item">
                    <h3 className="headline-quaternary">Contact Information</h3>
                    <p className="copy-list__text">You can contact us through the Applicationor by emailing us at <a href={`mailto:${mail}`}>{mail}</a>.</p>
                </li>
            </ol>
        </>
    ) : null;
};

export default GetUserLicenseAgreementContent;
