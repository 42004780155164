/**
 * # Cookie Policy Page
 */

/**
 * ## Imports
 */
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import clsx from 'clsx';
import { ClientName } from 'client/clientConfig';

import PageFooter from 'common/components/PageFooter/PageFooter';
import PageHeader from 'common/components/PageHeader/PageHeader';
import CopyText from 'common/components/CopyText';
import GetUserLicenseAgreementContent from './utils/GetUserLicenseAgreementContent';
import { submitMetrics } from '../../lib/submitMetrcis';
import { queryStringToObject } from '../../lib/query';

/**
 * ## Component
 */
const UserLicenseAgreementPage = () => {
    const isSonicClient = ClientName === 'sonic';
    const isAdblockClient = ClientName === 'adblock';
    const location = useLocation();
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''}`;

    useEffect(() => {
        submitMetrics({
            Category: 'BrowserLandingPage',
            Action: 'view',
            Object: 'webpage',
            ReferalSource: queryStringToObject(location?.search || ''),
        });
    });

    return (
        <div className={appClassName}>
            {(isSonicClient || isAdblockClient) && (
                <PageHeader clientName={ClientName} />
            )}
            <div className={clsx('follows-pageheader framed-section framed-section--less-margin-bottom', {
                'framed-section--adblock': isAdblockClient,
            })}
            >
                <div className="centerwrapper centerwrapper--very-narrow">
                    <h1 className="headline-main">{isAdblockClient ? 'ADBLOCK BROWSER End User License Agreement' : 'User License Agreement'}</h1>
                </div>
            </div>
            <CopyText clientName={ClientName}>{GetUserLicenseAgreementContent()}</CopyText>
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default UserLicenseAgreementPage;
