/**
 * # `useDownload` - Custom hook to automatically detect the client's OS
 * and download the appropriate browser.
 */

/**
 * ## Imports
 */
import { useState } from 'react';
import { isWindows } from 'react-device-detect';
import { pingHoudini } from 'lib/client/houdini';
import { getOS, mostLikelyMobileDevice, browserLinks, UserAgentOSTypes, isMacOS, isIos } from 'lib/userAgent';
import { queryStringToObject } from 'lib/query';
import { sleep } from 'lib/client/http';
import { getCookies } from 'lib/cookies';
import { submitMetrics } from '../lib/submitMetrcis';

/**
 * ## useDownload
 * @param queryParams - The query params
 * @returns [isLoading, downloadBrowser]
 */
export function useDownload(queryParams: string = '') {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const storageParams = localStorage.getItem('params');
    const paramsExpireTime = localStorage.getItem('paramsExpireTime') || '0';
    let qParams = storageParams || queryParams;
    if (Date.now() > +paramsExpireTime) {
        qParams = '';
        localStorage.setItem('params', '');
        localStorage.setItem('paramsExpireTime', '');
    }
    const cookies = getCookies();
    const gtmCookie = cookies?.['_ga'];
    if (gtmCookie) {
        qParams = `${qParams}${qParams ? '&' : '?'}clientId=${gtmCookie?.substring(6)}`;
    }

    const callHoudini = async (params: { [key: string]: any }) => {
        let response = await pingHoudini(params, !mostLikelyMobileDevice);
        let retries = 0;

        if (response.status !== 200) {
            retries += 1;
            await sleep(1000 + 1000 * 2 ** retries);
            // eslint-disable-next-line no-console
            console.log(`Retry nr. ${retries} result: ${response.status}`);
            response = await pingHoudini(params, !mostLikelyMobileDevice);
        }

        if (response.status !== 200 && retries === 1) {
            response = await pingHoudini({}, !mostLikelyMobileDevice);
            // eslint-disable-next-line no-console
            console.log(`Retry nr. ${retries} result: ${response.status}`);
        }
        return response;
    };

    const downloadBrowser = async (type?, callback?, isNewLayout = false) => {
        const OS = type || getOS();
        let downloadUrl: string | undefined;

        if (OS === UserAgentOSTypes.Windows) {
            downloadUrl = isWindows
                ? `${browserLinks[UserAgentOSTypes.Windows64]}${qParams}`
                : `${browserLinks[UserAgentOSTypes.Windows32]}${qParams}`;
        } else if (isWindows) {
            downloadUrl = `${browserLinks[UserAgentOSTypes.Windows]}${qParams}`;
        } else if (isMacOS && !isIos) {
            downloadUrl = `${browserLinks[UserAgentOSTypes.MacOS]}`;
        } else {
            downloadUrl = browserLinks[OS];
        }

        if (!downloadUrl) {
            return;
        }

        setIsLoading(true);

        submitMetrics({
            Category: 'BrowserLandingPage',
            Action: 'Click',
            Object: 'DownloadButton',
            Value: type || OS,
            testPage: isNewLayout ? 'handholding' : 'baseline',
        });

        if (![UserAgentOSTypes.Windows, UserAgentOSTypes.Windows32, UserAgentOSTypes.Windows64].includes(OS)) {
            try {
                await callHoudini(queryStringToObject(qParams));
            } catch (error) {
                console.error(error);
            }
        }

        window.location.href = downloadUrl;

        setIsLoading(false);

        if (callback && typeof callback === 'function') {
            callback();
        }
    };

    return { isLoading, downloadBrowser };
}
