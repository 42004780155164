/**
 * # Button Burger
 */

/**
 * ## Imports
 */
import React from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './ButtonOptions.module.scss';

/**
 * ## Types / Interfaces
 */
type ButtonOptionsProps = {
    onClick: () => void;
    opened: boolean;
}

/**
 * ## Component
 */
const ButtonOptions: React.FC<ButtonOptionsProps> = ({ onClick, opened }) => {
    /**
     * ### Click Handler
     */
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        onClick();
        event.stopPropagation();
    };

    const buttonClassName: ClassValue = clsx(styles['options'], {
        [styles['options--open']]: opened,
    });

    /**
     * ### JSX
     */
    return (
        <button
            className={buttonClassName}
            onClick={handleClick}
            type="button"
        >
            <span className={styles['options__body']}>
                {!opened ? 'Open Menu' : 'Close Menu'}
            </span>
        </button>
    );
};

export default ButtonOptions;
