/**
 * # Privacy Feature List
 */

/**
 * ## Imports
 */
import clsx from 'clsx';
import styles from './PrivacyFeatureList.module.scss';

/**
 * ## Types / Interfaces
 */
type PrivacyFeature = {
    title: string;
    description: string;
    svgComponent: React.ReactElement;
}
type PrivacyFeatureListProps = {
    privacyFeatureListData: PrivacyFeature[];
}

/**
 * ## Component
 */
const PrivacyFeatureList: React.FC<PrivacyFeatureListProps> = ({ privacyFeatureListData }) => {
    if (!privacyFeatureListData) {
        return null;
    }

    return (
        <>
            {/* {mostLikelyMobileDevice && (
                <div className={styles['text-body']}>
                    <h2 className={styles['headline']}>Browse faster and more secure</h2>
                    <p className={styles['copytext']}>Enjoy your favorite sites at top speed</p>
                </div>
            )} */}
            <ul
                className={clsx(
                    styles['list'],
                    'centerwrapper',
                    'centerwrapper--narrow',
                )}
            >
                {privacyFeatureListData.map((item, index) => (
                    <li className={styles['list-item']} key={index}>
                        <span className={styles['icon-wrapper']}>{item.svgComponent}</span>
                        <h3 className={styles['title']}>{item.title}</h3>
                        <p className={styles['description']}>{item.description}</p>
                    </li>
                ))}
            </ul>
        </>
    );
};

/**
 * ## Exports
 */
export default PrivacyFeatureList;
