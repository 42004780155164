/**
 * # Download Progress Bar
 */

/**
 * ## Imports
 */
import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './DownloadProgressBar.module.scss';

/**
 * ## Types / Interfaces
 */
type DownloadProgressBarProps = {
    currentStep?: number;
    classList?: string;
}

/**
 * ## Component
 */
const DownloadProgressBar: FC<DownloadProgressBarProps> = ({ currentStep = 1, classList }) => {
    return (
        <div className={clsx(styles['arrow-steps'], classList)}>
            <div
                data-step="1"
                className={clsx(styles['step'], {
                    [styles['step__current']]: currentStep === 1,
                    [styles['step__past']]: currentStep > 1,
                })}
            >
                {currentStep > 1 && <i className={clsx('fa fa-check', styles['check-icon'])} />}
                Download
            </div>
            <div
                data-step="2"
                className={clsx(styles['step'], {
                    [styles['step__current']]: currentStep === 2,
                    [styles['step__past']]: currentStep > 2,
                })}
            >
                {currentStep > 2 && <i className={clsx('fa fa-check', styles['check-icon'])} />}
                Run Installer
            </div>
            <div
                data-step="3"
                className={clsx(styles['step'], {
                    [styles['step__current']]: currentStep === 3,
                    [styles['step__past']]: currentStep > 3,
                })}
            >
                {currentStep > 3 && <i className={clsx('fa fa-check', styles['check-icon'])} />}
                Install
            </div>
        </div>
    );
};

export default DownloadProgressBar;
