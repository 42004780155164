/**
 * # Privacy Page
 */

/**
 * ## Imports
 */
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ClientName } from 'client/clientConfig';
import CtaTeaser from 'common/components/CtaTeaser/CtaTeaser';
import PageFooter from 'common/components/PageFooter/PageFooter';
import PageHeader from 'common/components/PageHeader/PageHeader';
import PrivacyFeatureList from 'common/components/PrivacyFeatureList/PrivacyFeatureList';
import PrivacySummaryList from 'common/components/PrivacySummaryList/PrivacySummaryList';
import { submitMetrics } from '../../lib/submitMetrcis';
import { queryStringToObject } from '../../lib/query';
import { getPrivacyFeatureListContent } from './utils/getPrivacyFeatureListContent';
import { getPrivacySummaryListContent } from './utils/getPrivacySummaryListContent';

/**
 * ## Component
 */
const PrivacyPage = () => {
    /**
     * ### Variables
     */
    const location = useLocation();
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''} app--privacy`;
    const isTempestClient = ClientName === 'tempest';
    const privacyFeatureListData = getPrivacyFeatureListContent();
    const privacySummaryListData = getPrivacySummaryListContent();

    /**
     * ### Effects
     */
    useEffect(() => {
        submitMetrics({
            Category: 'BrowserLandingPage',
            Action: 'view',
            Object: 'webpage',
            ReferalSource: queryStringToObject(location?.search || ''),
        });
    });

    return (
        <div className={appClassName}>
            <h1 className="sr-only">Sonic Browser - Privacy</h1>
            <PageHeader clientName={ClientName} />
            <CtaTeaser clientName={ClientName} classList={!isTempestClient ? 'follows-pageheader framed-section privacy-teaser' : ''} />
            <PrivacyFeatureList privacyFeatureListData={privacyFeatureListData} />
            <PrivacySummaryList PrivacyFeatureListData={privacySummaryListData} />
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default PrivacyPage;
