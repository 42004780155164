/**
 * # Mobile Menu Context
 * - Provides context of mobile navigation status to consuming components.
 */

/**
 * ## Imports
 */
import React, { createContext, useState, useContext, useMemo, ReactNode } from 'react';

/**
 * ## Types / Interfaces
 */
interface IMobileMenuContextType {
    isMobileMenuOpen: boolean;
    toggleMobileMenu: () => void;
    closeMobileMenu: () => void;
}

/**
 * ## Context
 */
const MobileMenuContext = createContext<IMobileMenuContextType | undefined>(undefined);

/**
 * ## Provider
 * Provides a context for managing the state of the mobile menue and exposes
 * a `MobileMenuProvider` component and `useMobileMenu` hook for use in child components.
 */
// eslint-disable-next-line react/function-component-definition
export const MobileMenuProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const contextValue = useMemo(() => {
        return {
            isMobileMenuOpen,
            toggleMobileMenu: () => {
                setIsMobileMenuOpen((prev) => { return !prev; });
            },
            closeMobileMenu: () => {
                setIsMobileMenuOpen(false);
            },
        };
    }, [isMobileMenuOpen]);

    return (
        <MobileMenuContext.Provider value={contextValue}>
            {children}
        </MobileMenuContext.Provider>
    );
};

export const useMobileMenu = (): IMobileMenuContextType => {
    const context = useContext(MobileMenuContext);
    if (context === undefined) {
        throw new Error('useMobileMenu must be used within a MobileMenuProvider');
    }
    return context;
};
