import BlockAdsInstruction from 'common/images/sonic_browser/block-ads-instruction.png';
import AdsSupportInstruction from 'common/images/sonic_browser/ads-support-instruction.png';

export const getPrivacyFAQs = (styles) => {
    return [
        {
            summary: 'What are ads?',
            content: <p>Ads are short for advertisements. Businesses or individuals create ads to sell their product and pay to have them on websites. Ads are often part of an advertising network, which use cookies extensively to display targeted advertising. SONIC offers a built-in ad blocker which lets you block such advertisements.</p>,
        },
        {
            summary: 'How do I block ads?',
            content: (
                <>
                    <p>To block ads, you must have the Block Ads and Trackers option enabled. To do that go to SONIC’s <span className={styles['bold']}>Settings &gt; Privacy and Security &gt; Ad and Tracker blocking &gt; Block Ads and Trackers</span></p>
                    <img className={styles['huge-img']} alt="Block ads instruction" src={BlockAdsInstruction} />
                </>
            ),
        },
        {
            summary: 'Why should I allow ads to display on a website?',
            content: (
                <>
                    <p>Advertising is what helps pay for website costs like web hosting, site maintenance, licensing fees, content and more. By allowing advertising, the website earns revenue when you see ads. By allowing them to display, you directly support the websites you enjoy most.</p>
                    <p>To allow ads and directly support a website <span className={styles['bold']}>click the Shield</span> icon next to the website address and <span className={styles['bold']}>turn off</span> the Ads and Trackers <span className={styles['bold']}>button</span></p>
                    <img className={styles['mid-img']} alt="Block ads instruction" src={AdsSupportInstruction} />
                </>
            ),
        },
        {
            summary: 'What are cookies?',
            content: (
                <>
                    <p>A cookie is a small text file that is stored in your browser. A cookie is created when you visit a website. This allows the website to keep track of your movements on the website, and to remember your login information, items in your cart and other preferences.</p>
                    <p>While first-party cookies (from the actual website) post no privacy issues, third-party cookies (from other companies or websites) are much more invasive.</p>
                    <p>Third-party cookies can track your online behavior all across the web. This helps advertising companies create deep profiles based on your interests, which they can then use to display targeted advertising.</p>
                    <p>All third-party cookies can be blocked when using SONIC browser if Trackers blocking is enabled. Shield will show you how many cookies have been blocked on a given page. Moreover, SONIC features a one-click solution for cookie consent pop-ups. Set your consent preferences once and let SONIC deal with all consent pop-ups for all the website you visit, for an uninterrupted browsing experience.</p>
                </>
            ),
        },
        {
            summary: 'What are trackers?',
            content: (
                <>
                    <p>An invisible tracker is a small piece of code, often called web beacon, web bug or web pixel. It is used extensively in advertising, because it can monitor what you are doing online—sites you visit, ads you click on and purchases you make. Trackers can be directly integrated into the code of a website or an email.</p>
                    <p>SONIC browser can help you block them, and using Shield you can see how many trackers have been blocked on a given page.</p>
                </>
            ),
        },
        {
            id: 'shield',
            summary: 'What is Shield?',
            content: <p>Shield shows you what is the current status of blocking ads or trackers on sites. Look for the shield icon to the left of the address bar. When you visit a website the Shield icon might show different states depending on your privacy settings: stroked shield when disabled, standard shield when partially blocking, shield with check mark when blocking all. You can also click it to toggle blocking on/off for specific websites.</p>,
        },
        {
            id: 'https-redirect',
            summary: 'What is HTTPS Redirect?',
            content: (
                <>
                    <p>Websites often accept two protocols which allow you to connect to the website. The original, HTTP, has been around since the beginning of the internet. HTTPS, however, is newer and it encrypts your data on the website. This is especially important for sensitive information.</p>
                    <p>Where possible, SONIC will redirect users to the more secure HTTPS version of a website.</p>
                </>
            ),
        },
        {
            id: 'anti-fingerprinting',
            summary: 'What is Anti-Fingerprinting?',
            content: <p>Anti-Fingerprinting is a feature built into SONIC when the highest privacy setting is set, to combat a &ldquo;digital fingerprint&rdquo; being created with your data. To limit your browser being identified, we continually randomize your device information. This means that you will appear as a different user across the web, even if you return to the same website.</p>,
        },
        {
            summary: 'Some websites act strange or don’t load all elements. What’s wrong?',
            content: <p>Because of the high privacy settings, some websites may not load correctly in ‘Block Ads and Trackers’ mode. If you encounter this, we suggest that you temporary disable blocking by using the Shield or switch to lower level of blocking via browser settings.</p>,
        },
    ];
};
