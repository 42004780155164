/**
 * # Features Page
 */

/**
 * ## Imports
 */
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import CustomizationTeaser from 'common/components/CustomizationTeaser/CustomizationTeaser';
import { ClientName } from 'client/clientConfig';
import CtaTeaser from 'common/components/CtaTeaser/CtaTeaser';
import DeviceTeaser from 'common/components/DeviceTeaser/DeviceTeaser';
import PageFooter from 'common/components/PageFooter/PageFooter';
import PageHeader from 'common/components/PageHeader/PageHeader';
import IllustrationWithListTeaser from 'common/components/IllustrationWithListTeaser/IllustrationWithListTeaser';
import PrivacyTeaser from 'common/components/PrivacyTeaser/PrivacyTeaser';
import useScrollToHash from 'hooks/useScrollToHash';
import { submitMetrics } from '../../lib/submitMetrcis';
import { queryStringToObject } from '../../lib/query';

/**
 * ## Component
 */
const FeaturesPage = () => {
    const location = useLocation();
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''} app--features`;
    const isTempestClient = ClientName === 'tempest';
    useScrollToHash();

    useEffect(() => {
        submitMetrics({
            Category: 'BrowserLandingPage',
            Action: 'view',
            Object: 'webpage',
            ReferalSource: queryStringToObject(location?.search || ''),
        });
    });

    return (
        <div className={appClassName}>
            <h1 className="sr-only">Sonic Browser - Features</h1>
            <PageHeader clientName={ClientName} />
            <CtaTeaser clientName={ClientName} classList={!isTempestClient ? 'follows-pageheader framed-section' : ''} />
            <CustomizationTeaser />
            <DeviceTeaser />
            <IllustrationWithListTeaser />
            <PrivacyTeaser />
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default FeaturesPage;
