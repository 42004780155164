/**
 * # Contact Teaser
 */

/**
 * ## Imports
 */
import React from 'react';
import { ClientName } from 'client/clientConfig';
import styles from './ContactTeaser.module.scss';
import ButtonCta from '../ButtonCta';

/**
 * ## Component output
 */
const ContactTeaser = ({ headline, copytext }) => {
    const clientName = ClientName;
    const handleButtonClick = () => {
        window.location.href = 'mailto:info@sonicbrowser.com';
    };

    return (
        <section className={styles['wrapper']}>
            <div className={styles['box']}>
                <h2 className={styles['headline']}>{headline}</h2>
                <p className={styles['copytext']}>{copytext}</p>
                <ButtonCta
                    clientName={clientName}
                    label="Contact us"
                    arrowIndicator
                    large
                    onClick={() => {
                        handleButtonClick();
                    }}
                />
            </div>
        </section>
    );
};

export default ContactTeaser;
