/**
 * # Feature Teaser Slides
 */
/**
 * ## Imports
 */
import AdvantageImg1 from 'common/images/homepage_rebrand/advantage-1.png';
import AdvantageImg2 from 'common/images/homepage_rebrand/advantage-2.png';
import AdvantageImg3 from 'common/images/homepage_rebrand/advantage-3.png';
import AdvantageImg1Sonic from 'common/images/sonic_browser/advantage-1.svg';
import AdvantageImg2Sonic from 'common/images/sonic_browser/advantage-2.svg';
import AdvantageImg3Sonic from 'common/images/sonic_browser/advantage-3.svg';

/**
 * ## Constants
 */
export const getAdvantagesListContent = (client: string) => {
    const isSonicClient = client === 'sonic';

    if (isSonicClient) {
        return [
            { id: 1, title: <><h3 className="uppercase advantage-subheadline">Speed</h3><p className="advantage-paragraph">Sonic helps your favorite pages load quicker and smoother, so you can wait less and explore more.</p></>, image: AdvantageImg1Sonic },
            { id: 2, title: <><h3 className="uppercase advantage-subheadline">Privacy</h3><p className="advantage-paragraph">Browse anonymously with built in tracker, ad and fingerprint blocking. Plus, get end-to-end encryption across devices.</p></>, image: AdvantageImg2Sonic },
            { id: 3, title: <><h3 className="uppercase advantage-subheadline">Efficiency</h3><p className="advantage-paragraph">Optimized performance with memory saver mode and automatic energy conservation at 20% battery.</p></>, image: AdvantageImg3Sonic },
        ];
    }

    return [
        { id: 1, title: <>Be invisible to prying eyes with <strong>private search</strong></>, image: AdvantageImg1 },
        { id: 2, title: <>Keep your personal, family and medical data secure with <strong>tracker blocking</strong></>, image: AdvantageImg2 },
        { id: 3, title: <><strong>Sync</strong> across multiple devices for added protection</>, image: AdvantageImg3 },
    ];
};
