/**
 * # Advantages List
 * - This component resembles a lot of `tempest-search`'s `advantages-screen` section.
 */

/**
 * ## Imports
 */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet, isIOS, isAndroid, osName } from 'react-device-detect';
import clsx from 'clsx';
import { isMobileBasedOnOS } from 'lib/userAgent';
import ButtonCta from '../ButtonCta';
import { useDownload } from '../../../hooks/useDownload';
import { ctaButtonTypes } from '../../../constants/buttonTypes';

import styles from './AdvantagesList.module.scss';

const { getTempest } = ctaButtonTypes.downloadButtons;
/**
 * ## Types / Interfaces
 */
interface ISlides {
    id: number;
    image: string;
    title: React.ReactNode;
}

/**
 * ## Component
 */
const AdvantagesList = ({ clientName, slideList }: { clientName: string; slideList: ISlides[] }) => {
    const { t } = useTranslation('websiteV2');
    const location = useLocation();
    const mostLikelyMobileDevice = (isMobile || isIOS || isAndroid || isMobileBasedOnOS());
    const isSonicClient = clientName === 'sonic';
    const isTempestClient = clientName === 'tempest';

    const { isLoading, downloadBrowser } = useDownload(location?.search || '');

    const navigate = useNavigate();
    const handleGetTempestButton = () => {
        if (isSonicClient) {
            navigate('/download');
        } else {
            downloadBrowser(getTempest);
        }
    };

    const advantagesScreenClassName = isSonicClient
        ? `${styles['advantages-screen']} ${styles['advantages-screen--sonic']}`
        : `${styles['advantages-screen']}`;

    const headlineLabel = isSonicClient
        ? 'Lightning Speed. Bulletproof Privacy. Smarter Browsing.'
        : 'Browse faster and more secure';

    const ctaParagraphLabel = (
        <>
            Get <strong>search results</strong> outside the bubble
        </>
    );

    /**
     * ### Button Label
     */
    let buttonLabel;
    if (mostLikelyMobileDevice && isTempestClient) {
        buttonLabel = t('download', 'Download');
    } else if (isSonicClient) {
        /** 🚨 Todo: Translation needed. */
        buttonLabel = 'Download Sonic';
    } else if (isLoading) {
        /** 🚨 Todo: Translation needed. */
        buttonLabel = 'Loading...';
    } else {
        buttonLabel = t('browser-page-section-2-button-label', 'Get tempest browser');
    }

    return (
        <div className={clsx(
            styles['advantages-screen-wrapper'],
            {
                [styles['advantages-screen-wrapper--sonic']]: isSonicClient,
            },
        )}
        >
            <section className={advantagesScreenClassName}>
                {isTempestClient && (
                    <div className={styles['advantages-screen__ornament-wrapper']} />
                )}
                <div className="centerwrapper">
                    <div className={styles['advantages-screen__body']}>
                        {/** 🚨 Todo: Translation needed. */}
                        <h2 className={styles['advantages-screen__headline']}>{ headlineLabel }</h2>

                        {isTempestClient && (
                            <h3 className={styles['headline advantages-screen__headline-sub']}>Enjoy your favorite sites at top speed</h3>
                        )}

                        {slideList && (
                            <ol className={styles['advantages-screen__list']}>
                                {slideList.map((slide) => (
                                    <li className={styles['advantages-screen__list-item']} key={slide.id}>
                                        <picture className={styles['advantages-screen__image-wrapper']}>
                                            <img src={slide.image} alt="" className={styles['advantages-screen__image']} />
                                        </picture>
                                        <div className={styles['advantages-screen__list-text']}>{slide.title}</div>
                                    </li>
                                ))}
                            </ol>
                        )}
                        {/*  eslint-disable-next-line */}
                        <>
                            {!isSonicClient && <p className={styles['advantages-screen__closure']}>{ctaParagraphLabel}</p>}
                            <ButtonCta
                                classList={styles['advantages-screen__get-button']}
                                clientName={clientName}
                                label={
                                    (
                                        isSonicClient && (
                                            isMobile
                                            || isTablet
                                            || osName !== 'Windows'
                                        )
                                    )
                                        ? 'Coming Soon'
                                        : buttonLabel as any
                                }
                                downloadIcon={isSonicClient && (!isMobile && !isTablet && osName === 'Windows')}
                                onClick={handleGetTempestButton}
                                layoutBig={isTempestClient}
                                layoutGlowBottom={isSonicClient}
                                large
                                layoutLofty={isTempestClient}
                                isButtonDisabled={
                                    isLoading
                                    || (isSonicClient && (
                                        isMobile
                                        || isTablet
                                        || osName !== 'Windows'
                                    ))
                                }
                            />
                        </>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AdvantagesList;
