/**
 * # Download Offer
 */

/**
 * ## Imports
 */
import { useLocation } from 'react-router-dom';
import { useMemo, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDownload } from 'hooks/useDownload';
import { isMacOS, UserAgentOSTypes } from 'lib/userAgent';
import { ClientName } from 'client/clientConfig';
import { getMacOSLayout } from './utils/getMacOSLayout';
import { getWinLayout } from './utils/getWinLayout';
import CtaTeaserIntro from '../CtaTeaserIntro/CtaTeaserIntro';
import DownloadProgressBar from '../DownloadProgressBar/DownloadProgressBar';
import styles from './AfterDownloadInstructions.module.scss';
import BounceInstruction from '../BounceInstruction/BounceInstruction';

/**
 * ## Component
 */

const DownloadOffer = ({ OS, isNewLayout = false }: { OS?: string; isNewLayout?: boolean }) => {
    const location = useLocation();
    const { downloadBrowser } = useDownload(location?.search || '');
    const allowedOSSystems: string[] = useMemo(() => [UserAgentOSTypes.MacOS, UserAgentOSTypes.Windows], []);
    const [currentStep, setCurrentStep] = useState(2);
    const isSonicClient = ClientName === 'sonic';
    const isAdblockClient = ClientName === 'adblock';

    const bowserName = useMemo(() => {
        if (isSonicClient) return 'Sonic';

        if (isAdblockClient) return 'Adblock';

        return 'Sonic';
    }, [isSonicClient, isAdblockClient]);

    const instructionLayout = useMemo(() => {
        if (OS && allowedOSSystems.includes(OS)) {
            if (OS === UserAgentOSTypes.MacOS) {
                return getMacOSLayout();
            }

            if (OS === UserAgentOSTypes.Windows) {
                return getWinLayout();
            }
        }

        if (isMacOS) {
            return getMacOSLayout();
        }

        return getWinLayout();
    }, [OS, allowedOSSystems]);

    const handleDownloadClick = () => {
        downloadBrowser(OS, null, isNewLayout);
    };

    useEffect(() => {
        function onWindowBlur() {
            setTimeout(() => {
                setCurrentStep(3);
            }, 3000);
        }

        setTimeout(() => window.addEventListener('blur', onWindowBlur), 5000);

        return () => window.removeEventListener('blur', onWindowBlur);
    }, []);

    return (
        <section className={clsx('download-offer centerwrapper follows-pageheader', { [styles['adblock']]: isAdblockClient })}>
            {(OS === UserAgentOSTypes.Windows && isNewLayout) && <BounceInstruction isLastStep={currentStep === 3} />}
            <CtaTeaserIntro
                headlineLabel="Thanks for downloading!"
                copyText={(
                    <>
                        {(OS === UserAgentOSTypes.Windows && isNewLayout) && <DownloadProgressBar currentStep={currentStep} classList={styles['arrow-steps-wrapper']} />}
                        <p className="intro-paragraph intro-pragraph--bold">
                            Your download should begin automatically.{isAdblockClient && <br />} If it didn’t start,&nbsp;<a href="#" onClick={handleDownloadClick}>download {bowserName} manually</a>
                        </p>
                    </>
                )}
                hasButton={false}
            />

            {(currentStep !== 3 || !isNewLayout) && (
                <ol className={styles['list']}>
                    {instructionLayout.map((item, index) => (
                        <li className={styles['list-item']} key={index}>
                            <img src={item.image} alt="sdfd" className={styles['image']} />
                            <h3 className={styles['step']}>Step {index + 1}</h3>
                            <h4 className={styles['headline']}>{item.title}</h4>
                            <p className={styles['copytext']}>{item.description}</p>
                        </li>
                    ))}
                </ol>
            )}

            {(currentStep === 3 && isNewLayout) && <div className={styles['last-step-area']} />}
        </section>
    );
};

/**
 * ## Exports
 */
export default DownloadOffer;
