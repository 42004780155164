/**
 * # Button CTA
 */

/**
 * ## Imports
 */
import clsx from 'clsx';
import { osName } from 'react-device-detect';
import ArrowToLineIcon from 'common/images/sonic_browser/icons/arrow-to-line.svg';
import styles from './ButtonCta.module.scss';

/**
 * ## Component Props
 */
interface IButtonCtaProps {
    classList?: string;
    clientName?: string;
    label?: string;
    layoutAlt?: boolean;
    layoutBig?: boolean;
    layoutMedium?: boolean;
    layoutReduced?: boolean;
    layoutDry?: boolean;
    layoutLofty?: boolean;
    layoutGlowBottom?: boolean;
    arrowIndicator?: boolean;
    downloadIcon?: boolean;
    fullWidth?: boolean;
    large?: boolean;
    isAnimated?: boolean;
    isButtonDisabled?: boolean;
    hasAnimatedBorder?: boolean;
    onClick?: () => void;
}

const ButtonCta = ({
    classList = '',
    clientName = 'tempest',
    label = 'text',
    layoutAlt = false,
    layoutBig = false,
    layoutMedium = false,
    layoutReduced = false,
    layoutDry = false,
    layoutLofty = false,
    layoutGlowBottom = false,
    arrowIndicator = false,
    downloadIcon = false,
    fullWidth = false,
    large = false,
    isAnimated = false,
    isButtonDisabled = false,
    hasAnimatedBorder = false,
    onClick = () => { },
}: IButtonCtaProps) => {
    const isSonicClient = clientName === 'sonic';
    const isAdblockClient = clientName === 'adblock';

    const buttonClassName = clsx(
        styles['button-cta'],
        osName?.replace(' ', ''),
        {
            [styles['button-cta--alt']]: layoutAlt,
            [styles['button-cta--sonic']]: isSonicClient,
            [styles['button-cta--adblock']]: isAdblockClient,
            [styles['button-cta--big']]: layoutBig,
            [styles['button-cta--medium']]: layoutMedium,
            [styles['button-cta--reduced']]: layoutReduced,
            [styles['button-cta--arrow']]: arrowIndicator,
            [styles['button-cta--download']]: downloadIcon,
            [styles['button-cta--animated-arrow']]: isAnimated,
            [styles['button-cta--arrow-large']]: (arrowIndicator && large),
            [styles['button-cta--dry']]: layoutDry,
            [styles['button-cta--lofty']]: layoutLofty,
            [styles['button-cta--large']]: large,
            [styles['button-cta--full-width']]: fullWidth,
            [styles['button-cta--glow-bottom']]: layoutGlowBottom,
            [styles['button-cta--disabled']]: isButtonDisabled,
        },
        classList,
    );

    const buttonElement = (
        <button
            type="button"
            onClick={onClick}
            className={buttonClassName}
            disabled={isButtonDisabled}
        >
            <span className={styles['button-cta__body']}>
                {downloadIcon && <img src={ArrowToLineIcon} width={24} height={24} className={styles['download-icon']} alt="Download icon" />}
                {label}
            </span>
        </button>
    );

    return hasAnimatedBorder
        ? <div className={`${styles['button-cta-wrapper']}`}>{buttonElement}</div>
        : buttonElement;
};

export default ButtonCta;
