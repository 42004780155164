/**
 * # Set Favicon for Each Client
 */

/**
 * ## Helper Function
 */
export const setFavicon = ({ client }: {client: string}) => {
    const favicon: HTMLLinkElement | null = document.querySelector('link[rel="icon"]');
    const isTempestClient = client === 'tempest';
    const isSonicClient = client === 'sonic';
    const isAdblockClient = client === 'adblock';

    if (isTempestClient) {
        favicon.href = 'tempest/favicon.ico';
    } else if (isSonicClient) {
        favicon.href = 'sonic/favicon.ico';
    } else if (isAdblockClient) {
        favicon.href = 'adblock/favicon.ico';
    } else {
        favicon.href = '/favicon.ico';
    }

    /**
     * ### Apple Touch Icons 🍎📱
     */
    const appleTouchIcons = {
        'apple-touch-icon-76x76': 'tempest/apple-touch-icon-76x76.png',
        'apple-touch-icon-120x120': 'tempest/apple-touch-icon-120x120.png',
        'apple-touch-icon-152x152': 'tempest/apple-touch-icon-152x152.png',
        'apple-touch-icon-180x180': 'tempest/apple-touch-icon-180x180.png',
        'apple-touch-icon-default': 'tempest/apple-touch-icon.png',
    };

    if (isSonicClient) {
        appleTouchIcons['apple-touch-icon-76x76'] = 'sonic/apple-touch-icon-76x76.png';
        appleTouchIcons['apple-touch-icon-120x120'] = 'sonic/apple-touch-icon-120x120.png';
        appleTouchIcons['apple-touch-icon-152x152'] = 'sonic/apple-touch-icon-152x152.png';
        appleTouchIcons['apple-touch-icon-180x180'] = 'sonic/apple-touch-icon-180x180.png';
        appleTouchIcons['apple-touch-icon-default'] = 'sonic/apple-touch-icon.png';
    }

    if (isAdblockClient) {
        appleTouchIcons['apple-touch-icon-76x76'] = 'adblock/apple-touch-icon-76x76.png';
        appleTouchIcons['apple-touch-icon-120x120'] = 'adblock/apple-touch-icon-120x120.png';
        appleTouchIcons['apple-touch-icon-152x152'] = 'adblock/apple-touch-icon-152x152.png';
        appleTouchIcons['apple-touch-icon-180x180'] = 'adblock/apple-touch-icon-180x180.png';
        appleTouchIcons['apple-touch-icon-default'] = 'adblock/apple-touch-icon.png';
    }

    Object.keys(appleTouchIcons).forEach((key) => {
        const link = document.getElementById(key);
        if (link instanceof HTMLLinkElement) {
            link.href = appleTouchIcons[key];
        }
    });
};
