/**
 * # Privacy Teaser
 */

/**
 * ## Imports
 */
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { osName } from 'react-device-detect';
import { useDownload } from 'hooks/useDownload';
import { ctaButtonTypes } from 'constants/buttonTypes';
import { ClientName } from 'client/clientConfig';
import styles from './PrivacyTeaser.module.scss';
import { ReactComponent as ShieldIcon } from '../../images/sonic_browser/icons/shield.svg';
import { ReactComponent as TrackingIcon } from '../../images/sonic_browser/icons/tracking.svg';
import { ReactComponent as CookieIcon } from '../../images/sonic_browser/icons/cookie.svg';
import ButtonCta from '../ButtonCta';

/**
 * ## Component
 */
const PrivacyTeaser = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoading, downloadBrowser } = useDownload(location?.search || '');
    const { download } = ctaButtonTypes.downloadButtons;
    const isSonicClient = ClientName === 'sonic';

    const isDownloadFeaturePage = location.pathname.includes('download-windows-b');

    const handleDownloadButton = () => {
        if (isSonicClient) {
            navigate('/download', { state: { isNewLayout: isDownloadFeaturePage } });
        } else {
            downloadBrowser(download);
        }
    };

    const teaserContent = [
        {
            iconSrc: ShieldIcon,
            headline: 'Auto-Update With Background Sync',
            copytext: 'Once you sign into your Sonic account, your data magically imports into any inked browser to make the experience familiar.',
        },
        {
            iconSrc: TrackingIcon,
            headline: 'Intrusive Tracker Protection',
            copytext: 'Our browser  finally puts an end to powerful tracker blocking systems that follow your activity and track your behaivor online.',
        },
        {
            iconSrc: CookieIcon,
            headline: 'Intelligent Cookie Control',
            copytext: 'Dynamic cookie blocking is built right into  your browser, limiting data gathering from any website and asking you for consent before sharing any information.',
        },
    ];

    return (
        <section className="centerwrapper">
            <div className={styles['text-body']}>
                <h2 className={styles['headline']}>Your Privacy on Auto-Pilot</h2>
                <h3 className={styles['subline']}>Sonic Browser protects your privacy with built-in ad blocking, tracker blocking, cookie control, fingerprint obscuring, encrypted connections, and incognito browsing to eliminate any trace of your activity.</h3>
            </div>
            <ul className={styles['teaser-list']}>
                {teaserContent.map((teaser, index) => {
                    return (
                        <li key={index} className={styles['teaser-list__item']}>
                            <span className={styles['teaser-image-wrapper']}>
                                <teaser.iconSrc className={styles['teaser-icon']} />
                            </span>
                            <h4 className={styles['teaser-headline']}>{teaser.headline}</h4>
                            <p className={styles['teaser-label']}>{teaser.copytext}</p>
                            <div className="availability">
                                <h4 className="sr-only">Available on: </h4>
                                <ul className={styles['availability__list']}>
                                    <li>
                                        <em className={styles['availability__desktop']}>Desktop</em>
                                    </li>
                                    <li>
                                        <em className={styles['availability__smartphone']}>Smartphone</em>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    );
                })}
            </ul>

            {(isDownloadFeaturePage && osName === 'Windows') && (
                <div className={clsx(styles['button-wrapper'], styles['continue-button'])}>
                    <ButtonCta
                        clientName={ClientName}
                        label="Continue"
                        arrowIndicator
                        layoutAlt={false}
                        layoutGlowBottom={isSonicClient}
                        large
                        isAnimated={false}
                        onClick={handleDownloadButton}
                        hasAnimatedBorder={false}
                        isButtonDisabled={isLoading}
                    />
                </div>
            )}
        </section>
    );
};

/**
 * ## Exports
 */
export default PrivacyTeaser;
