/**
 * # Support
 */

/**
 * ## Imports
 */
import { ClientName } from 'client/clientConfig';
import PageFooter from 'common/components/PageFooter/PageFooter';
import PageHeader from 'common/components/PageHeader/PageHeader';
import ContactBox from 'common/components/ContactBox/ContactBox';
import FAQ from 'common/components/FAQ/faq';

/**
 * ## Component
 */
const WelcomePage = () => {
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''} app--welcome`;

    const handleStartBrowsing = () => {
        window.open();
    };

    return (
        <div className={appClassName}>
            <h1 className="sr-only">Welcome Page</h1>
            <PageHeader clientName={ClientName} isMenuHidden />
            <ContactBox
                title="Welcome to Sonic"
                text="Thank you for installing Sonic browser. Let`s start exploring the web together!"
                buttonLabel="Start Browsing"
                onClick={handleStartBrowsing}
            />
            <FAQ title="Make it yours!" />
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default WelcomePage;
