/**
 * # Global Teaser
 */

/**
 * ## Imports
 */
import React from 'react';
import clsx from 'clsx';
import TempestMap from 'common/components/TempestMap/TempestMap';
import ContactTeaser from '../ContactTeaser/ContactTeaser';
import styles from './GlobalTeaser.module.scss';

/**
 * ## Component
 */
const GlobalTeaser: React.FC = () => {
    return (
        <section className={clsx(
            styles['wrapper'],
        )}
        >
            <div className="centerwrapper">
                <div className={styles['text-body']}>
                    <h2 className={styles['headline']}>Crafted by Global Browser Enthusiasts</h2>
                    <p>Our team of engineers and design experts develop products in countries across the world. Our offices include Germany, France, Singapore, Portugal, and the US.</p>
                </div>
                <TempestMap />
                {/* <div className={styles['divider']} /> */}
            </div>

            <ContactTeaser headline="Work with Us" copytext="Interested in joining our team?" />
        </section>
    );
};

/**
 * ## Exports
 */
export default GlobalTeaser;
