import axios from 'axios';

export class HttpClient {
    client = null;

    constructor(options) {
        this.client = axios.create(options);
    }

    async getHeaders() {
        const headers = {};
        return headers;
    }

    async createRequest(config) {
        try {
            return await this.client.request(config);
        } catch (error) {
            console.error('CSRF error:', error?.message);
            throw error;
        }
    }

    async get(url, config) {
        return this.createRequest({
            url,
            ...config,
            method: 'GET',
            headers: await this.getHeaders('GET'),
            withCredentials: false,
        });
    }

    async post(url, data, config) {
        return this.createRequest({
            ...config,
            url,
            data,
            method: 'POST',
            headers: {
                ...(await this.getHeaders('POST')),
                ...(config?.headers || {}),
            },
            withCredentials: false,
        });
    }

    async patch(url, data, config) {
        return this.createRequest({
            ...config,
            url,
            data,
            method: 'PATCH',
            headers: await this.getHeaders('PATCH'),
            withCredentials: false,
        });
    }

    async put(url, data, config) {
        return this.createRequest({
            ...config,
            url,
            data,
            method: 'PUT',
            headers: await this.getHeaders('PUT'),
            withCredentials: false,
        });
    }

    async delete(url, config) {
        return this.createRequest({
            ...config,
            url,
            method: 'DELETE',
            headers: await this.getHeaders('DELETE'),
            withCredentials: false,
        });
    }
}
