/**
 * # Experience
 */

/**
 * ## Imports
 */
import { isAndroid, isMobile, isTablet, osName } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useDownload } from 'hooks/useDownload';
import ButtonCta from '../ButtonCta';
import experienceSrc from './images/privacy.png';
import deviceSrc from './images/device.png';
import fairSrc from './images/fair.png';
import phoneSrc from './images/phone.png';
import styles from './ExperienceFeature.module.scss';

/**
 * ## Component
 */
const ExperienceFeature = ({ clientName }: {
    clientName: string;
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoading, downloadBrowser } = useDownload(location?.search || '');
    const isSonicClient = clientName === 'sonic';
    const isAdblockClient = clientName === 'adblock';

    const handleDownload = () => {
        if ((isSonicClient || isAdblockClient) && ['Windows', 'Mac OS'].includes(osName)) {
            navigate('/download');
        } else {
            downloadBrowser();
        }
    };

    const label = useMemo(() => {
        if (isSonicClient) {
            return isMobile || isTablet || osName !== 'Windows' ? 'Coming soon' : 'Try it free';
        }

        if (isAdblockClient) {
            if (isAndroid) {
                return 'Try it free';
            }

            return isMobile || isTablet || !['Windows', 'Mac OS'].includes(osName) ? 'Coming soon' : 'Try it free';
        }

        return 'Try it free';
    }, [isSonicClient, isAdblockClient]);

    const isDisabled = useMemo(() => {
        if (isSonicClient) {
            return isMobile || isTablet || osName !== 'Windows';
        }

        if (isAdblockClient) {
            if (isAndroid) {
                return false;
            }

            return isMobile || isTablet || !['Windows', 'Mac OS'].includes(osName);
        }

        return true;
    }, [isSonicClient, isAdblockClient]);

    return (
        <div className={styles['wrapper']}>
            <h2 className={styles['title']}>Treat yourself to a better browsing experience</h2>

            <div className={styles['cards']}>
                <div className={styles['cards--row']}>
                    <div className={styles['card']}>
                        <img className={styles['card--image']} src={experienceSrc} alt="Upgrade your experience" />
                        <h3 className={styles['card--title']}>Upgrade your experience</h3>
                        <p className={styles['card--text']}>Upgrade to a cleaner, faster web experience: enjoy enhanced privacy and freedom from ad clutter, making your online browsing seamless and secure.</p>
                    </div>
                    <div className={styles['card']}>
                        <img className={styles['card--image']} src={deviceSrc} alt="Available on all devices" />
                        <h3 className={styles['card--title']}>Available on all devices (Coming Soon)</h3>
                        <p className={styles['card--text']}>Access on desktop or mobile by downloading from the App Store and Google Play to browse securely from anywhere.</p>
                    </div>
                </div>

                <div className={styles['cards--row']}>
                    <div className={styles['card']}>
                        <img className={styles['card--image']} src={fairSrc} alt="Fair. For everyone." />
                        <h3 className={styles['card--title']}>Fair. For everyone.</h3>
                        <p className={styles['card--text']}>Websites need revenue to stay free. Support them by allowing Acceptable Ads.</p>
                    </div>
                    <div className={styles['card']}>
                        <img className={styles['card--image']} src={phoneSrc} alt="Now your phone is even better" />
                        <h3 className={styles['card--title']}>Now your phone is even better</h3>
                        <p className={styles['card--text']}>Adblock Browser safeguards your privacy with built-in ad blocking, tracker protection, cookie control, fingerprint obscuring, encryption, and incognito browsing to eliminate traces of your activity.</p>
                    </div>
                </div>
            </div>

            <ButtonCta
                clientName={clientName}
                classList={styles['button']}
                label={label}
                onClick={handleDownload}
                layoutBig={false}
                layoutGlowBottom={false}
                large
                layoutLofty={false}
                isButtonDisabled={isLoading || isDisabled}
            />
        </div>
    );
};

export default ExperienceFeature;
