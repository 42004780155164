/**
 * # Download Features Page A/B test
 */

/**
 * ## Imports
 */
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CustomizationTeaser from 'common/components/CustomizationTeaser/CustomizationTeaser';
import { ClientName } from 'client/clientConfig';
import CtaTeaser from 'common/components/CtaTeaser/CtaTeaser';
import DeviceTeaser from 'common/components/DeviceTeaser/DeviceTeaser';
import PageFooter from 'common/components/PageFooter/PageFooter';
import PageHeader from 'common/components/PageHeader/PageHeader';
import PrivacyTeaser from 'common/components/PrivacyTeaser/PrivacyTeaser';
import { submitMetrics } from '../../lib/submitMetrcis';
import { queryStringToObject } from '../../lib/query';

/**
 * ## Component
 */
const DownloadFeaturesPage = () => {
    const location = useLocation();
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''} app--features`;
    const isTempestClient = ClientName === 'tempest';
    const [isABTest, setIsABTest] = useState(true);

    useEffect(() => {
        const variant = Math.random() < 0.5;
        setIsABTest(variant);

        submitMetrics({
            Category: 'BrowserLandingPage',
            Action: 'view',
            Object: 'lander',
            ReferalSource: queryStringToObject(location?.search || ''),
            testPage: variant ? 'handholding' : 'baseline',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.search]);

    return (
        <div className={appClassName}>
            <h1 className="sr-only">Sonic Browser - Features</h1>
            <PageHeader clientName={ClientName} />
            <CtaTeaser
                clientName={ClientName}
                classList={!isTempestClient ? 'follows-pageheader framed-section' : ''}
                isABTest={isABTest}
            />
            <CustomizationTeaser />
            <DeviceTeaser />
            <PrivacyTeaser />
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default DownloadFeaturesPage;
