/**
 * # Customize Page
 */

/**
 * ## Imports
 */
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { ClientName } from 'client/clientConfig';

import CtaTeaser from 'common/components/CtaTeaser/CtaTeaser';
import PageFooter from 'common/components/PageFooter/PageFooter';
import PageHeader from 'common/components/PageHeader/PageHeader';
import SonicDarkSrc from 'common/images/sonic_browser/sonic-dark.png';
import SonicLightSrc from 'common/images/sonic_browser/sonic-light.png';
import AdblockDarkSrc from 'common/images/adblock_browser/adblock_dark.png';
import AdblockLightSrc from 'common/images/adblock_browser/adblock_light.png';
import SliderButtonImage from 'common/images/sonic_browser/icons/dragger-icon.png';
import CustomizationFeatureTeaser from 'common/components/CustomizationFeatureTeaser/CustomizationFeatureTeaser';
import { isIos } from 'lib/userAgent';
import { submitMetrics } from '../../lib/submitMetrcis';
import { queryStringToObject } from '../../lib/query';
import styles from './CustomizePage.module.scss';

/**
 * ## Component
 */
const CustomizePage = () => {
    const isTempestClient = ClientName === 'tempest';
    const isAdblockClient = ClientName === 'adblock';
    const isSonicClient = ClientName === 'sonic';
    // const activeClient = process.env.REACT_APP_ENV;
    // console.log('activeClient', activeClient, ClientName);
    const location = useLocation();
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''} app--customize`;
    const [sliderValue, setSliderValue] = useState(20);

    const images = useMemo(() => {
        if (isAdblockClient) {
            return {
                dark: AdblockDarkSrc,
                light: AdblockLightSrc,
            };
        }

        if (isSonicClient) {
            return {
                dark: SonicDarkSrc,
                light: SonicLightSrc,
            };
        }

        return {
            dark: SonicDarkSrc,
            light: SonicLightSrc,
        };
    }, [isAdblockClient, isSonicClient]);

    const handleSliderChange = (event) => {
        setSliderValue(event.target.value);
        const container = document.getElementById('slider-container');
        container.style.setProperty('--position', `${event?.target?.value}%`);
    };

    useEffect(() => {
        const handleScroll = () => {
            const sliderContainer = document.getElementById('slider-container');
            const scrollY = window.scrollY;
            const sliderHeight = sliderContainer.offsetHeight;
            const scrollPosition = Math.min(Math.max(scrollY, 0), sliderHeight);
            const newSliderValue = 20 + (75 * scrollPosition) / sliderHeight;
            setSliderValue(newSliderValue);
            sliderContainer.style.setProperty('--position', `${newSliderValue}%`);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        submitMetrics({
            Category: 'BrowserLandingPage',
            Action: 'view',
            Object: 'webpage',
            ReferalSource: queryStringToObject(location?.search || ''),
        });
    });

    // console.log('activeClient', activeClient, ClientName);

    return (
        <div className={appClassName}>
            <h1 className="sr-only">Customize Page</h1>
            <PageHeader clientName={ClientName} />
            <CtaTeaser
                clientName={ClientName}
                classList={clsx({
                    'follows-pageheader': !isTempestClient,
                    'framed-section': !isTempestClient,
                    'ios-section': !isTempestClient && isIos,
                })}
            />
            <div className={styles['sonic-theme-slider']}>
                <div id="slider-container" className={styles['slider-container']}>
                    <div className={styles['image-container']}>
                        <img className={clsx(styles['image-left'], styles['slider-image'])} src={images.dark} alt="Dark theme" />
                        <img className={clsx(styles['image-right'], styles['slider-image'])} src={images.light} alt="Light theme" />
                    </div>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        step={0.5}
                        value={sliderValue}
                        aria-label="Percentage of left image shown"
                        className={styles['slider']}
                        id="slider"
                        onChange={handleSliderChange}
                    />
                    <div className={styles['slider-line']} aria-hidden="true" />
                    <div className={styles['slider-button']} aria-hidden="true"><img className={styles['slider-button-img']} alt="Slider button icon" src={SliderButtonImage} /></div>
                </div>
            </div>
            <CustomizationFeatureTeaser />
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default CustomizePage;
