/**
 * # CTA Teaser
 */

/**
 * ## Imports
 */
import { useTranslation } from 'react-i18next';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { isMobileBasedOnOS } from 'lib/userAgent';
import { useDownload } from 'hooks/useDownload';
import ctaTeaserBigImageSrcTempest from 'common/images/homepage_rebrand/cta-teaser-big.png';
import ctaTeaserSmallImageSrcTempest from 'common/images/homepage_rebrand/cta-teaser-small.png';
import ctaTeaserBigImageSrcSonic from 'common/images/sonic_browser/macbook-pro-front-view.png';
import ctaTeaserSmallImageSrcSonic from 'common/images/sonic_browser/macbook-pro-front-view-mobile.png';
import ctaTeaserBigImageSrcAdblock from 'common/images/adblock_browser/cta-teaser-big.png';
import ctaTeaserSmallImageSrcAdblock from 'common/images/adblock_browser/cta-teaser-small.png';
import styles from './CtaTeaser.module.scss';
import { getClassName, getHeadlineMain } from './utils';
import CtaTeaserIntro from '../CtaTeaserIntro/CtaTeaserIntro';

type CtaTeaserProps = {
    clientName: string;
    classList?: string;
    hasButton?: boolean;
    isABTest?: boolean;
}

/**
 * ## Component Output
 */
const CtaTeaser: React.FC<CtaTeaserProps> = ({ clientName, classList, hasButton = true, isABTest }) => {
    const mostLikelyMobileDevice = (isMobile || isIOS || isAndroid || isMobileBasedOnOS());
    const { t } = useTranslation('websiteV2');
    const location = useLocation();
    const { isLoading } = useDownload(location?.search || '');
    const isHomePage = location.pathname === '/';
    const isDownloadFeaturePage = location.pathname.includes('/download-windows-b');
    const isFeaturesPage = location.pathname.includes('/features') || isDownloadFeaturePage;
    const isPrivacyPage = location.pathname.includes('/privacy');
    const isAboutPage = location.pathname.includes('/about');
    const isCustomizePage = location.pathname.includes('/customize');

    /**
     * ### Client Split
     */
    const isSonicClient = clientName === 'sonic';
    const isTempestClient = clientName === 'tempest';
    const isAdblockClient = clientName === 'adblock';

    const handleLinkClick = (link) => (event) => {
        event?.preventDefault();
        window.location.href = link;
    };

    let ctaTeaserSmallImageSrc;
    switch (clientName) {
    case 'sonic':
        ctaTeaserSmallImageSrc = ctaTeaserSmallImageSrcSonic;
        break;
    case 'adblock':
        ctaTeaserSmallImageSrc = ctaTeaserSmallImageSrcAdblock;
        break;
    default:
        ctaTeaserSmallImageSrc = ctaTeaserSmallImageSrcTempest;
    }

    let ctaTeaserBigImageSrc;
    switch (clientName) {
    case 'sonic':
        ctaTeaserBigImageSrc = ctaTeaserBigImageSrcSonic;
        break;
    case 'adblock':
        ctaTeaserBigImageSrc = ctaTeaserBigImageSrcAdblock;
        break;
    default:
        ctaTeaserBigImageSrc = ctaTeaserBigImageSrcTempest;
    }

    /**
     * ### CSS Classes
     */
    const ctaTeaserClassName = getClassName({ location, clientName, styles, classList });

    /**
     * ### Text contents
     * - Todo: create a new JSX file, let's say CtaTeaserContent.jsx, to hold the content.
     */
    /**
     * #### Headline
     */
    const headlineMain = getHeadlineMain({ clientName, location });

    /**
     * #### Copy Text
     */
    let copyText;
    if (isSonicClient && isHomePage) {
        copyText = <p className="intro-paragraph">Sonic combines best-in-class performance by blocking unwanted ads, with  innovative security features that flags a wide range of malicious  websites online. The result? An incredibly safe browser that‘s faster  than ever before.</p>;
    } else if (isSonicClient && isDownloadFeaturePage) {
        copyText = <p className="intro-paragraph intro-paragraph-download">Change your default browser to Sonic, a Chromium-based browser that offers faster page loads than Chrome by blocking unwanted ads. We’re pioneering privacy features to keep your browsing smooth and secure. Sonic browser offers as standard a set of tools to keep you safe and productive, while having a fun customised experience. Get now an incredibly safe browser that‘s faster than ever before.</p>;
    } else if (isSonicClient && isFeaturesPage) {
        copyText = <p className="intro-paragraph intro-pragraph--bold">Our state-of-the-art browser offers faster page loads than Chrome, with buttery-smooth scrolling and instant site access. Plus, we’re pioneering privacy features to keep your browsing secure.</p>;
    } else if (isSonicClient && isAboutPage) {
        copyText = <p className="intro-paragraph intro-pragraph--bold">We were launched in 2023 by <a href="https://infinity-browsers.com/" className="intro-paragraph__link" onClick={handleLinkClick('https://infinity-browsers.com/')}>Infinity Browsers</a> – the company that makes browser technology accessible, customizable, and quick-to-market for brands everywhere.</p>;
    } else if (isSonicClient && isPrivacyPage) {
        copyText = <p className="intro-paragraph intro-pragraph--bold">Browse anonymously with Sonic’s robust privacy protections. State-of-the-art features like encrypted connections, tracker blocking and incognito mode give you total control without sacrificing performance.</p>;
    } else if (isSonicClient && isCustomizePage) {
        copyText = <p className="intro-paragraph intro-pragraph--bold">Our state-of-the-art browser offers faster page loads than Chrome, with buttery-smooth scrolling and instant site access. Plus, we’re pioneering privacy features to keep your browsing secure.</p>;
    } else if (isAdblockClient) {
        copyText = <p className="intro-paragraph">Combining best-in-class performance with the most innovative security features online, Adblock Browser brings you an incredibly safe browser experience that’s faster than ever before.</p>;
    } else {
        copyText = <h2 className="headline-sub">Private browser that puts you in control.</h2>;
    }

    /**
     * #### Button Label
     */
    let buttonLabel;
    if (mostLikelyMobileDevice && isTempestClient) {
        buttonLabel = t('get-now', 'get-now');
    } else if (isSonicClient && isDownloadFeaturePage) {
        buttonLabel = 'Continue';
    } else if (isSonicClient && isHomePage) {
        buttonLabel = 'Download Sonic';
    } else if (isSonicClient) {
        buttonLabel = 'Download now';
    } else if (isAdblockClient) {
        buttonLabel = 'Download Adblock Browser';
    } else if (isLoading) {
        buttonLabel = 'Loading...';
    } else {
        buttonLabel = t('download', 'Download');
    }

    return (
        <section className={ctaTeaserClassName}>
            <div className={`${styles['cta-teaser']} centerwrapper}`}>
                <CtaTeaserIntro
                    headlineLabel={headlineMain}
                    copyText={copyText}
                    hasButton={hasButton}
                    buttonLabel={buttonLabel}
                    isNewLayout={isDownloadFeaturePage && isABTest}
                />

                {((isSonicClient && isHomePage) || (isAdblockClient && isHomePage) || isTempestClient) && (
                    <picture className={styles['cta-teaser__visual']}>
                        <source
                            media="(max-width: 759px)"
                            srcSet={ctaTeaserSmallImageSrc}
                            className={styles['cta-teaser__visual-source']}
                        />
                        <source
                            media="(min-width: 760px)"
                            srcSet={ctaTeaserBigImageSrc}
                            className={styles['cta-teaser__visual-source']}
                        />
                        <img
                            // eslint-disable-next-line
                            alt={`This shows two devices, a desktop monitor and a smartphone, displaying the interface of a web browser named \"${clientName.charAt(0).toUpperCase() + clientName.slice(1)}.\" Both screens showcase the browser's start page. On the desktop monitor, the start page includes a search bar with a placeholder text that reads \"Search Tempest or type a URL.\" Below the search bar, there are shortcut icons to various popular websites such as YouTube, Netflix, Booking, Reddit, Amazon, Dribbble, Wikipedia, and Zoom. There's also an option to \"Add shortcut.\" The browser tabs and address bar are visible at the top of the window. On the smartphone, the Tempest browser displays a similar start page with a search bar with placeholder text stating \"Search the web in total privacy.\" Below it are shortcut icons for YouTube, Netflix, Booking, Reddit, Amazon, Dribbble, Wikipedia, and an icon to access more options. At the bottom of the smartphone screen, there are navigation buttons that typically pertain to a mobile browser interface, including buttons for new tabs, bookmarks, a reading list, history, and downloads. The layouts of the browser on both devices suggest a focus on privacy for web searches and easy access to frequently visited sites through shortcuts. The Tempest logo, a stylized purple \"T\" in a speech bubble icon, is also prominently displayed on both screens.`}
                            src={ctaTeaserSmallImageSrc}
                            className={styles['cta-teaser__visual-image']}
                        />
                    </picture>
                )}
            </div>
        </section>
    );
};

export default CtaTeaser;
