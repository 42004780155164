/**
 * # Tempest Routes
 */
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';

export const routesTempest = [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
];
