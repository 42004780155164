/**
 * # Feature Teaser
 */

/**
 * ## Imports
 */
import React from 'react';
import { isMobile, isTablet, osName } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useDownload } from '../../../hooks/useDownload';
import ButtonCta from '../ButtonCta';
import { ctaButtonTypes } from '../../../constants/buttonTypes';
import styles from './FeatureTeaser.module.scss';

/**
 * ## Types / Interfaces
 */
type FeatureTeaserProps = {
    clientName: string;
    title: string;
    description: string;
    imageSrcBig: string;
    imageSrcSmall: string;
    ctaLabel: string;
    index: number;
    activateButton?: boolean;
}

/**
 * ## Component
 */
const FeatureTeaser = ({
    clientName,
    title,
    description,
    imageSrcBig,
    imageSrcSmall,
    ctaLabel,
    index,
    activateButton,
}: FeatureTeaserProps) => {
    /**
     * ### Variables
     */
    const { getTempest } = ctaButtonTypes.downloadButtons;
    const isSonicClient = clientName === 'sonic';
    const location = useLocation();

    /**
     * ### State
     */
    const { isLoading, downloadBrowser } = useDownload(location?.search || '');

    /**
     * ### Handlers
     */
    const navigate = useNavigate();
    const handleGetTempestButton = () => {
        if (isSonicClient) {
            navigate('/download');
        } else {
            downloadBrowser(getTempest);
        }
    };

    /**
     * ### JSX
     */
    return (
        <li className={styles['teaser-item']} key={index}>
            <section className={styles['teaser']}>
                <div className={styles['textbox']}>
                    <h3 className={styles['headline']}>{title}</h3>
                    <p className={styles['paragraph']}>{description}</p>
                    <ButtonCta
                        classList={clsx(styles['button-cta'], { [styles['button-cta__active']]: activateButton })}
                        clientName={clientName}
                        label={
                            (
                                isSonicClient && (
                                    isMobile
                                    || isTablet
                                    || osName !== 'Windows'
                                )
                            )
                                ? 'Coming Soon'
                                : ctaLabel as any
                        }
                        onClick={handleGetTempestButton}
                        layoutBig={false}
                        layoutGlowBottom={isSonicClient}
                        large
                        layoutLofty={false}
                        isButtonDisabled={
                            isLoading
                            || (isSonicClient && (
                                isMobile
                                || isTablet
                                || osName !== 'Windows'
                            ))
                        }
                    />
                </div>
                <picture className={styles['imagebox']}>
                    <source
                        media="(max-width: 759px)"
                        srcSet={imageSrcSmall}
                        className={styles['cta-teaser__visual-source']}
                    />
                    <source
                        media="(min-width: 760px)"
                        srcSet={imageSrcBig}
                        className={styles['cta-teaser__visual-source']}
                    />
                    <img
                        alt={title}
                        src={imageSrcSmall}
                        className={styles['image']}
                    />
                </picture>
            </section>
        </li>
    );
};

export default FeatureTeaser;
