/**
 * # Support
 */

/**
 * ## Imports
 */
import { ClientName } from 'client/clientConfig';
import PageFooter from 'common/components/PageFooter/PageFooter';
import PageHeader from 'common/components/PageHeader/PageHeader';
import ContactBox from 'common/components/ContactBox/ContactBox';
import FAQ from 'common/components/FAQ/faq';

/**
 * ## Component
 */
const SupportPage = () => {
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''} app--support`;

    return (
        <div className={appClassName}>
            <h1 className="sr-only">Contact Page</h1>
            <PageHeader clientName={ClientName} />
            <ContactBox />
            <FAQ clientName={ClientName} />
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default SupportPage;
