/**
 * # Copy Text
 */
/**
 * ## Imports
 */
import React, { FC } from 'react';
import clsx from 'clsx';

/**
 * ## Types / Interfaces
 */
type CopyTextProps = {
    children: React.ReactNode;
    clientName: string;
    classList?: string;
}

/**
 * ## Component
 */
const CopyText: FC<CopyTextProps> = ({ children, clientName, classList }) => {
    const isSonicClient = clientName === 'sonic';
    const isAdblockClient = clientName === 'adblock';

    return (
        <div className={clsx(
            'copy-text',
            {
                'copy-text--sonic': (isSonicClient || isAdblockClient),
            },
            classList,
        )}
        >
            <div className="centerwrapper centerwrapper--very-narrow">
                {children}
            </div>
        </div>
    );
};

/**
 * ## Exports
 */
export default CopyText;
