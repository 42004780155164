import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { config as i18nextConfig } from 'translations';
import { setTitle } from 'client/setTitle';
import { setFavicon } from 'client/setFavicon';
import { ClientName } from 'client/clientConfig';
import { routesAdblock } from 'routesAdblock';
import { MobileMenuProvider } from './context/MobileMenuContext';
import { routesTempest } from './routesTempest';
import { routesSonic } from './routesSonic';
import reportWebVitals from './reportWebVitals';
import './base.scss';

/**
 * ## Translations
 */
i18n.init(i18nextConfig);

/**
 * ## Setting up the document's title and favicons.
 */
if (ClientName === 'tempest') {
    setTitle({ title: 'Download Tempest' });
} else if (ClientName === 'sonic') {
    setTitle({ title: 'Download Sonic Browser' });
} else if (ClientName === 'adblock') {
    setTitle({ title: 'Download AdBlock Browser' });
} else {
    setTitle({ title: '' });
}

setFavicon({ client: ClientName });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let routes = routesTempest;
switch (ClientName) {
case 'sonic':
    routes = routesSonic;
    break;
case 'adblock':
    routes = routesAdblock;
    break;
default:
    routes = routesAdblock;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <MobileMenuProvider>
            <React.StrictMode>
                <I18nextProvider i18n={i18n}>
                    <Routes>
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                element={route.element}
                            />
                        ))}
                    </Routes>
                </I18nextProvider>
            </React.StrictMode>
        </MobileMenuProvider>
    </Router>,
);

/**
 * ## Check if the `prefers-color-scheme` media query is supported.
 */
if (!window.matchMedia('(prefers-color-scheme)').matches && ClientName === 'tempest') {
    const darkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

    const body = document.body;
    body.classList.add('no-prefers-color-scheme');

    if (darkTheme) {
        body.classList.add('no-prefers-color-scheme--dark');
    } else {
        body.classList.add('no-prefers-color-scheme--light');
    }
}
