/**
 * # App
 */

/**
 * ## Imports
 */
import { useLocation, Link } from 'react-router-dom';
import { useEffect } from 'react';
import { ClientName } from 'client/clientConfig';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageFooter from 'common/components/PageFooter/PageFooter';

import { submitMetrics } from '../../lib/submitMetrcis';
import { queryStringToObject } from '../../lib/query';
import styles from './NotFound.module.scss';

/**
 * ## Component
 */
const NotFoundPage = () => {
    const isSonicClient = ClientName === 'sonic';

    // const activeClient = process.env.REACT_APP_ENV;
    const location = useLocation();
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''}`;

    useEffect(() => {
        submitMetrics({
            Category: 'BrowserLandingPage',
            Action: 'view',
            Object: '404',
            ReferalSource: queryStringToObject(location?.search || ''),
        });
    });

    return (
        <div className={appClassName}>
            {isSonicClient && (
                <PageHeader clientName={ClientName} />
            )}
            <section className={`${styles['not-found']} centerwrapper follows-pageheader`}>
                <h1 className={styles['headline']}>Error 404 🚨</h1>
                <h2 className={styles['sub-headline']}>Page Not Found.</h2>
                <p className={styles['description']}>The page you are looking for does not exist.</p>
                <Link to="/" className={styles['link']}>Back to Home</Link>
            </section>
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default NotFoundPage;
