/**
 * # Mobile Menu
 */

/**
 * ## Imports
 */
import React, { useRef, useEffect, useMemo } from 'react';
import clsx, { ClassValue } from 'clsx';
import { Link } from 'react-router-dom';
import { mostLikelyMobileDevice } from 'lib/userAgent';
import { ClientName } from 'client/clientConfig';
import LinkList from '../../../@types/linkList';

import styles from './MobileMenu.module.scss';

/**
 * ## Types / Interfaces
 */
type MobileMenuProps = {
    linkList: LinkList[];
    hidden: boolean;
    onClick: () => void;
}

/**
 * ## Component
 */
const MobileMenu = ({
    linkList,
    hidden,
    onClick,
}: MobileMenuProps) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const isAdblockClient = ClientName === 'adblock';

    const navClassName: ClassValue = clsx(
        styles['mobile-menu'],
        {
            [styles['mobile-menu--hidden']]: hidden,
            [styles['mobile-menu--adblock']]: isAdblockClient,
        },
    );

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            onClick();
        }
    };

    useEffect(() => {
        // Click event listener when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    });

    const menuList = useMemo(() => {
        if (mostLikelyMobileDevice) {
            return linkList.filter((item) => !item.isButton);
        }

        return linkList;
    }, [linkList]);

    return (
        <nav ref={menuRef} className={navClassName}>
            <h2 className="sr-only">Navigation (compact)</h2>
            <ul className={styles['navlist']}>
                {menuList.map((linkItem) => (
                    <li className={styles['list-item']} key={linkItem.label}>
                        {linkItem.route === '' ? (
                            <span
                                className={clsx(
                                    styles['list-link'],
                                    styles['list-link--disabled'],
                                )}
                                title={linkItem.label}
                            >
                                {linkItem.label}
                            </span>
                        ) : (
                            <Link
                                type="button"
                                className={styles['list-link']}
                                to={linkItem.route}
                                onClick={onClick}
                            >
                                {linkItem.label}
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default MobileMenu;
