/**
 * # getCustomizationFeatureTeaserContent
 */

/**
 * ## Imports
 */
import { ReactComponent as VisualPrivacyIcon01 } from 'common/images/sonic_browser/sonic-customization-feature-01.svg';
import { ReactComponent as VisualPrivacyIcon02 } from 'common/images/sonic_browser/sonic-customization-feature-02.svg';
import { ReactComponent as VisualPrivacyIcon03 } from 'common/images/sonic_browser/sonic-customization-feature-03.svg';
import { ReactComponent as VisualPrivacyIcon04 } from 'common/images/sonic_browser/sonic-customization-feature-04.svg';
import { ReactComponent as VisualPrivacyIcon05 } from 'common/images/sonic_browser/sonic-customization-feature-05.svg';
import { ReactComponent as VisualPrivacyIcon06 } from 'common/images/sonic_browser/sonic-customization-feature-06.svg';

/**
 * ## Types / Interfaces
 */
type CustomizationFeature = {
    title: string;
    description: string;
    svgComponent: React.ReactElement;
}
export function getCustomizationFeatureTeaserContent(): CustomizationFeature[] {
    return [
        {
            title: 'Dynamic Backgrounds',
            description: 'Choose from beautiful preloaded backgrounds or upload your own favorite photos and graphics to customize your new tab page with a personal touch.',
            svgComponent: <VisualPrivacyIcon01 className="icon" />,
        },
        {
            title: 'Easy-to-use Widgets',
            description: 'Equip your new tab with useful at-a-glance widgets like a weather ticker, most-visited site shortcuts, to-do lists and more so crucial info is always within reach.',
            svgComponent: <VisualPrivacyIcon02 className="icon" />,
        },
        {
            title: 'Light & Dark Mode',
            description: 'Adjust website themes to match your environment or preference between light and dark modes. Enable auto night mode for automatic adjustments.',
            svgComponent: <VisualPrivacyIcon03 className="icon" />,
        },
        {
            title: 'Instant Data Sync',
            description: 'Securely access bookmarks, browsing history, passwords and other data from all signed-in devices. Switch effortlessly between laptop, phone and tablet picking up exactly where you left off each time.',
            svgComponent: <VisualPrivacyIcon04 className="icon" />,
        },
        {
            title: 'Popular Extensions',
            description: 'Enjoy continued access to beloved Chrome extensions alongside Sonic’s built-in functionalities for the optimal personalized browsing experience tailored to your unique needs.',
            svgComponent: <VisualPrivacyIcon05 className="icon" />,
        },
        {
            title: 'Privacy as you Browse',
            description: 'Intelligent built-in privacy features including cookie, tracker and ad-blockers, fingerprint protection and a browser guardian allows you to browse in complete privacy.',
            svgComponent: <VisualPrivacyIcon06 className="icon" />,
        },
    ];
}
