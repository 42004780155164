/**
 * # Accordion
 */

/**
 * ## Imports
 */
import React, { useMemo, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import useScrollToHash from 'hooks/useScrollToHash';
import styles from './Accordion.module.scss';

/**
 * ## Component output
 */
const Accordion = ({ data, clientName }) => {
    const hiddenContentRef = useRef(null);
    const seeAllButtonRef = useRef(null);
    const location = useLocation();
    const visibleData = useMemo(() => data.slice(0, 3), [data]);
    const hiddenData = useMemo(() => data.slice(3), [data]);
    useScrollToHash();

    useEffect(() => {
        const displayAllFAQ = () => {
            const seeAllButton = seeAllButtonRef.current;
            seeAllButton.style.display = 'none';

            const hiddenContent = hiddenContentRef.current;
            hiddenContent.classList.add(styles['hidden-content__visible']);
        };

        const expandTheSection = (section) => {
            section.classList.add('active');

            const content: any = section.nextElementSibling;
            content.classList.add('active');
            content.style.maxHeight = `${content.scrollHeight + 48}px`;
            content.style.paddingTop = '16px';
            content.style.paddingBottom = '32px';
        };

        const activateSection = () => {
            if (location?.hash) {
                displayAllFAQ();

                const section = document?.querySelector(location?.hash);
                expandTheSection(section);
                if (location?.hash === '#available-os') {
                    const howToInstallSection = document?.querySelector('#how-to-install');
                    expandTheSection(howToInstallSection);
                }

                if (location?.hash === '#shield') {
                    const httpsRedirectSection = document?.querySelector('#https-redirect');
                    const antiFingerprintingSection = document?.querySelector('#anti-fingerprinting');
                    expandTheSection(httpsRedirectSection);
                    expandTheSection(antiFingerprintingSection);
                }

                if (location?.hash === '#extensions') {
                    const howToInstallSection = document?.querySelector('#customize');
                    expandTheSection(howToInstallSection);
                }
            }
        };

        setTimeout(activateSection, 300);
    }, [location]);

    const handleClick = (event) => {
        event.target.classList.toggle('active');
        const content = event.target.nextElementSibling;
        content.classList.toggle('active');
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
            content.style.paddingTop = null;
            content.style.paddingBottom = null;
        } else {
            content.style.paddingTop = '16px';
            content.style.paddingBottom = '32px';
            content.style.maxHeight = `${content.scrollHeight + 48}px`;
        }
    };

    const handleSeeMore = (event) => {
        const button = event.target;
        button.style.display = 'none';
        hiddenContentRef.current.classList.toggle(styles['hidden-content__visible']);
    };

    return (
        <div className={clsx(styles['accordion'], { [styles['adblock']]: clientName === 'adblock' })}>
            {visibleData.map((item) => (
                <React.Fragment key={item.summary}>
                    <button
                        id={item?.id || ''}
                        type="button"
                        className={styles['accordion__summary']}
                        onClick={handleClick}
                    >
                        {item.summary}
                    </button>
                    <div className={styles['accordion__content']}>
                        {item.content}
                    </div>
                </React.Fragment>
            ))}

            <button
                ref={seeAllButtonRef}
                type="button"
                className={styles['seeAll-button']}
                onClick={handleSeeMore}
            >
                See All
            </button>

            <div ref={hiddenContentRef} className={styles['hidden-content']}>
                {hiddenData.map((item) => (
                    <React.Fragment key={item.summary}>
                        <button
                            id={item?.id || ''}
                            type="button"
                            className={styles['accordion__summary']}
                            onClick={handleClick}
                        >
                            {item.summary}
                        </button>
                        <div className={styles['accordion__content']}>
                            {item.content}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Accordion;
