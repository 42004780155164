/**
 * # Illustration With List Teaser
 */

/**
 * ## Imports
 */
import styles from './IllustrationWithListTeaser.module.scss';
import { ReactComponent as SynchIllustration } from '../../images/sonic_browser/sonic-sync-illustration.svg';

/**
 * ## Component
 */
const IllustrationWithListTeaser: React.FC = () => {
    const listContent = [
        {
            headline: 'Sync Your Devices',
            copytext: 'Pick up where you left off or when switching devices with intelligent sync.',
        },
        {
            headline: 'Keep Your Data Private',
            copytext: 'End-to-end encryption keeps your data safe. Plus, we won’t store, sell or share your information.',
        },
        {
            headline: 'Auto-Update With Background Sync',
            copytext: 'Once you sign into your Sonic account, your data magically imports into any inked browser to make the experience familiar.',
        },
        {
            headline: 'Set Up Instantly',
            copytext: 'Join Sonic Browser with a free account and start browsing securely in just a few seconds.',
        },
    ];

    return (
        <section className={`${styles['wrapper']} centerwrapper`}>
            <div className={styles['text-body']}>
                <h2 data-testid="heading-first" className={styles['headline']}>Easy Data Syncing</h2>
                <h3 data-testid="heading-sub" className={styles['subline']}>Continue to access your bookmarks, browsing history and passwords across all your devices for added accessibility, convenience and protection.</h3>
            </div>

            <div className={styles['layout-wrapper']}>
                <ul className={styles['sync-list']}>
                    {listContent.map((item, index) => (
                        <li className={styles['sync-list__item']} key={index}>
                            <h2 className={styles['sync-list__headline']}>{item.headline}</h2>
                            <p className={styles['sync-list__copytext']}>{item.copytext}</p>
                        </li>
                    ))}
                </ul>
                <SynchIllustration className={styles['illustration']} />
            </div>
        </section>
    );
};

/**
 * ## Exports
 */
export default IllustrationWithListTeaser;
