/**
 * # Get Cookie Content
 */
/**
 * ## Imports
 */
import { Link } from 'react-router-dom';
import { ClientName } from 'client/clientConfig';

/* eslint-disable-next-line react/no-unescaped-entities */

/**
 * ## Output
 */
// eslint-disable-next-line
const GetCookieContent = () => {
    const isSonicClient = ClientName === 'sonic';
    const isAdblockClient = ClientName === 'adblock';

    return (isSonicClient || isAdblockClient) ? (
        <>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>This cookie policy (“Policy”) describes what cookies are and how and they're being used on the Sonic Browser web browser (“Sonic Browser”, or “Service”) and any and all related information, resources, tools, products, and services (including  the  www.sonic browser.com website, collectively, “Services”). This Policy is a legally binding agreement between you (“User”, “you” or “your”) and Sonic Browser LLC (“Sonic Browser”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business orother legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Services. You should read this Policy so you can understand the types of cookies we use, the information we collect using cookies   and   how   that   information   is   used.   It   also   describes   the   choices available to you regarding accepting or declining the use of cookies. For further information on how we use, store and keep your personal data secure, see our Privacy Policy at: <Link to="/privacy-policy">www.sonic-browser.com/privacy-policy</Link>.</p>

            <h2 className="headline-exception-secondary">What are Cookies?</h2>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>Cookies are small pieces of data stored in text files that are saved on your computer or other devices when websites are loaded in a browser. They are widely used to remember you and your preferences, either for a single visit (through a "session cookie") or for multiple repeat visits (using a "persistent cookie").Session cookies are temporary cookies that are used during the course of your visit to the Services, and they expire when you close the web browser. Persistent cookies are used to remember your preferences on the Services and remain on your desktop or mobile   device even after you close your browser or restart your computer. They   ensure a consistent and efficient experience for you while visiting the Services. <br />Cookies may be set by the Services ("first-party cookies"), or by third parties, such as those who serve content or provide advertising or analytics services on the Services ("third party cookies"). These third parties can recognize you when you visit our website and also when you visit certain other websites or Services. To learn more about cookies and   how they work visit <a href="https://www.internetcookies.com">https://www.internetcookies.com</a>.</p>

            <div className="copy-list copy-list--box">
                <h2 className="headline-exception-secondary">What Type of Cookies Do We Use?</h2>
                <h3 className="headline-exception-quintary">Necessary cookies</h3>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <p className="copy-list__text">Necessary cookies allow us to offer you the best possible experience when
                    accessing and navigating through our Services and using its features. For
                    example, these cookies let us recognize that you have created an account
                    and have logged into that account to access the content.
                </p>

                <h3 className="headline-exception-quintary">Functionality cookies</h3>
                <p className="copy-list__text">Functionality   cookies   let   us   operate   the   Services   in   accordance   with   thechoices   you   make.   For   example,   we   will   recognize   your   username   andremember how you customized the Services during future visits.
                </p>

                <h3 className="headline-exception-quintary">Analytical cookies</h3>
                <p className="copy-list__text">These cookies enable us and third party services to collect aggregated datafor statistical purposes on how our visitors use the Services. These cookiesdo not contain personal information such as names and email addresses andare used to help us improve your user experience of the Services.
                </p>

                <h3 className="headline-exception-quintary">Advertising cookies</h3>
                <p className="copy-list__text">Advertising cookies allow us and third parties serve relevant ads to you moreeffectively   and   help   us   collect   aggregated   audit   data,   research,   andperformance reporting for advertisers. They also enable us to understandand improve the delivery of ads to you and know when certain ads havebeen shown to you.Your   web   browser   may   request   advertisements   directly   from   ad   networkservers, these networks can view, edit, or set their own cookies, just as if youhad requested a web page from their website.Although we do not use cookies to create a profile of your browsing behavioron third party websites, we do use aggregate data from third parties to showyou   relevant,   interest-based   advertising.   We   do   not   provide   any   personalinformation that we collect to advertisers.
                </p>

                <h3 className="headline-exception-quintary">Social media cookies</h3>
                <p className="copy-list__text">Third party cookies from social media sites (such as Facebook, Twitter, etc) let us track social network users when they visit or use the Services, or sharecontent, by using a tagging mechanism provided by those social networks. These cookies are also used for event tracking and remarketing purposes.Any data collected with these tags will be used in accordance with our andsocial networks’ privacy policies. We will not collect or share any personallyidentifiable information from the user.
                </p>
            </div>

            <h2 className="headline-exception-secondary">Do We Use Web Beacons or Tracking Pixels?</h2>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p className="copy-list__text">Our emails may contain a "web   beacon" (or   "tracking   pixel")   to tell us whether our emails are opened and verify any clicks   through to links or advertisements within the email. We may use this information for purposes including determining which of our emails are more interesting to users and to query whether users who do not open our emails wish to continue receiving them. The pixel will be deleted when you delete the email. If you do not wish the pixel to be downloaded to your device, you should read the email in plaintext view or with images disabled.</p>

            <h2 className="headline-exception-secondary">What Are Your Cookie Options?</h2>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p className="copy-list__text">If you don’t like the idea of cookies or certain types of cookies, you can change your browser’s settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this, visit <a href="https://www.internetcookies.com">https://www.internetcookies.com</a>.</p>

            <h2 className="headline-exception-secondary">Changes and Amendments</h2>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p className="copy-list__text">We reserve the right to modify this Policy or its terms related to the Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided. An   updated version of this Policy will be effective immediately   upon the posting of the revised Policy unless otherwise specified. Your continued use of the Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes.</p>

            <h2 className="headline-exception-secondary">Acceptance of This Policy</h2>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p className="copy-list__text">You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Services.</p>

            <h2 className="headline-exception-secondary">Contacting Us</h2>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p className="copy-list__text">If you have any questions, concerns, or complaints regarding this Policy orthe use of cookies, we encourage you to contact us using the details below:</p>

            <p><a href="mailto:info@sonicbrowser.com">info@sonicbrowser.com</a><br />
                Sonic Browser LLC<br />
                1201 W Peachtree St NW, Suite 2625<br />
                Atlanta, GA 30309<br />
            </p>
        </>
    ) : null;
};

export default GetCookieContent;
