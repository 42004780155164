/**
 * # getPrivacySummaryListContent
 */
/**
 * ## Imports
 */
import { ReactComponent as FingerprintIllustration } from 'common/images/sonic_browser/sonic-fingerprint-illustration.svg';
import { ReactComponent as LockIllustration } from 'common/images/sonic_browser/sonic-lock-illustration.svg';
import { ReactComponent as SyncIllustration } from 'common/images/sonic_browser/sonic-sync-illustration.svg';

/**
 * ## Function
 */
export const getPrivacySummaryListContent = () => {
    return [
        {
            title: <>Be invisible to prying eyes with <strong>private search</strong></>,
            svgComponent: <LockIllustration className="icon" />,
        },
        {
            title: <>Keep your personal, family and medical data secure with <strong>tracker blocking</strong></>,
            svgComponent: <FingerprintIllustration className="icon" />,
        },
        {
            title: <><strong>Sync</strong> across multiple devices for added protection</>,
            svgComponent: <SyncIllustration className="icon" />,
        },
    ];
};
