/**
 * # getWinOSLayout
 */

import { ClientName } from 'client/clientConfig';
import downloadImage01 from 'common/images/sonic_browser/download_pc_01.png';
import downloadImage02 from 'common/images/sonic_browser/download_pc_02.png';
import downloadImage03 from 'common/images/sonic_browser/download_pc_03.png';

import downloadABBImage01 from 'common/images/adblock_browser/download_pc_01.png';
import downloadABBImage02 from 'common/images/adblock_browser/download_pc_02.png';
import downloadABBImage03 from 'common/images/adblock_browser/download_pc_03.png';

export const getWinLayout = () => {
    if (ClientName === 'adblock') {
        return [
            {
                image: downloadABBImage01,
                title: 'Open Installer',
                description: 'Find the Adblock Browser Installer folder from downloads in the top right corner of your window or in your ‘Downloads’ folder.',
            },
            {
                image: downloadABBImage02,
                title: 'Install Adblock Browser',
                description: 'Press the folder icon to bring you to the downloaded Adblock Installer. Double click on the ‘Abbinstaller.exe’ file to begin the install.',
            },
            {
                image: downloadABBImage03,
                title: 'Launch and Set as Default',
                description: 'Once the installer launches, follow the instructions and Adblock Browser as default as your default browser.',
            },
        ];
    }

    return [
        {
            image: downloadImage01,
            title: 'Open Installer',
            description: 'Find the Sonic Browser Installer folder from downloads in the top right corner of your window or in your ‘Downloads’ folder.',
        },
        {
            image: downloadImage02,
            title: 'Install Sonic Browser',
            description: 'Press the folder icon to bring you to the downloaded Sonic Browser Installer. Double click on the ‘SonicBrowser.exe’ file to begin the install.',
        },
        {
            image: downloadImage03,
            title: 'Launch and Set as Default',
            description: 'Once the installer launches, follow the instructions and Sonic Browser as default as your default browser.',
        },
    ];
};
