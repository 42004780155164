import { ClientName } from 'client/clientConfig';
import getStars from './getStars';

const SonicReviews = [
    {
        review: 'Whether it&apos;s productivity tools, ad blockers, or social media integrations, Sonic has a rich library of extensions to choose from. This compatibility significantly enhances the functionality of the browser.',
        name: 'Roxanne',
        stars: getStars(5),
    },
    {
        review: 'The support team is responsive, and the online help resources, including FAQs and community forums, are extensive and informative.',
        name: 'Christopher',
        stars: getStars(5),
    },
    {
        review: 'The customer support for Sonic is prompt and helpful.',
        name: 'Fiona',
        stars: getStars(5),
    },
    {
        review: 'Whether you are a casual user or someone who relies heavily on a web browser for work.',
        name: 'Benicio',
        stars: getStars(5),
    },
];

const AdblockReviews = [
    {
        review: 'Very good app for blocking ads 🙏 It even works for youtube if you make sure to go watch a video on the website by staying on the AdBlock Browser. There is a glitch if you put the full screen and then press home button on your phone.',
        name: 'Jorge',
        stars: getStars(5),
    },
    {
        review: 'I have used adblock extensions before, but always there were some ads and pop ups that could make it through. Although, for the time I have used this browser, I have not encountered a single and or pop up. It\'s the only one so far that fulfils my expectations and even exceeds them.',
        name: 'Marianna',
        stars: getStars(4.5),
    },
    {
        review: 'One of the best adblocking apps ive found. unlike most apps ive tried its just a straight up browser, it doesnt have any ads in the app itself, and you dont need to pay to use it. makes reading and watching stuff online a lot easier for me.',
        name: 'Danny',
        stars: getStars(5),
    },
    {
        review: 'Works and does exactly as stated. I use it mostly to read books and other things that have pop ups and redirect links. This app has completely taken care of those pesky issues with out a pay wall. So far perfect 5.',
        name: 'Jensen',
        stars: getStars(5),
    },
    {
        review: 'Exceptional application for everyone to enjoy a advertising gauntlet FREE browser it\'s a lite and outstandingly powerful tool full of options that are in your own hands. So far I have been only using the free version and without a Doubt I\'ll be purchasing the premium version in the very near future.',
        name: 'Peter',
        stars: getStars(5),
    },
];

const getReviews = () => {
    const isSonicClient = ClientName === 'sonic';
    const isAdblockClient = ClientName === 'adblock';

    if (isSonicClient) return SonicReviews;
    if (isAdblockClient) return AdblockReviews;

    return SonicReviews;
};

export default getReviews;
