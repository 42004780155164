/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/**
 * # Page Footer
 */

/**
 * ## Imports
 */
import React, { useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ReactComponent as AdblockLogo } from 'common/images/adblock_browser/icons/logo_icon.svg';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import { ReactComponent as HomepageLogoTempest } from '../../images/homepage_rebrand/logo-tempest.svg';
import styles from './PageFooter.module.scss';

/**
 * ## Component
 */
const LogoPlaceholder = (props) => <div className="logo-placeholder" {...props} />;

const PageFooter = ({ clientName }: { clientName: string }) => {
    const { t } = useTranslation('websiteV2');
    const isTempestClient = clientName === 'tempest';
    const isSonicClient = clientName === 'sonic';
    const isAdblockClient = clientName === 'adblock';

    const pageFooterClassName = clsx('pagefooter', styles['pagefooter'], {
        [styles['pagefooter--sonic']]: isSonicClient,
        [styles['pagefooter--adblock']]: isAdblockClient,
    });

    const Logo = useMemo(() => {
        if (isSonicClient) {
            return LogoPlaceholder;
        }

        if (isAdblockClient) {
            return AdblockLogo;
        }

        return HomepageLogoTempest;
    }, [isAdblockClient, isSonicClient]);

    let imprintLabel;
    switch (clientName) {
    case 'sonic':
        imprintLabel = '© 2024 Sonic Browser';
        break;
    case 'adblock':
        imprintLabel = '© 2024 Adblock Browser';
        break;
    default:
        imprintLabel = '© 2023 Tempest';
    }
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const handleOpenMenu = (event) => {
        const menu = event.target.parentNode;
        menu.classList.toggle(styles['footer-menu__column-active']);
    };

    return (
        <footer className={pageFooterClassName}>
            <div className={styles['pagefooter__boilerplate']}>

                {(isSonicClient || isAdblockClient) && (
                    <div className={clsx(styles['footer-menu'], {
                        [styles['footer-menu--adblock']]: isAdblockClient,
                        [styles['footer-menu--sonic']]: isSonicClient,
                    })}
                    >
                        <div className={styles['footer-menu__column']}>
                            <p onClick={handleOpenMenu} className={styles['footer-menu__column-title']}>Product</p>
                            {isSonicClient && (
                                <div className={styles['footer-menu__column-items']}>
                                    <a href="/support#why-faster" className={styles['footer-menu__column-item']}>
                                        Why is Sonic faster?
                                    </a>
                                    <a href="/support#shield" className={styles['footer-menu__column-item']}>
                                        Why is Sonic more secure ?
                                    </a>
                                    <a href="/features" className={styles['footer-menu__column-item']}>
                                        What are Sonic`s features?
                                    </a>
                                </div>
                            )}

                            {isAdblockClient && (
                                <div className={styles['footer-menu__column-items']}>
                                    <a href="/support#security" className={styles['footer-menu__column-item']}>
                                        What are Adblock Browser’s features?
                                    </a>
                                    <a href="/support#security" className={styles['footer-menu__column-item']}>
                                        Privacy & Security
                                    </a>
                                    <a href="/support#customize" className={styles['footer-menu__column-item']}>
                                        Customization
                                    </a>
                                    <a href="/support#extensions" className={styles['footer-menu__column-item']}>
                                        Widgets & Extensions
                                    </a>
                                </div>
                            )}
                        </div>

                        {isSonicClient && (
                            <div className={styles['footer-menu__column']}>
                                <p onClick={handleOpenMenu} className={styles['footer-menu__column-title']}>Features</p>
                                <div className={styles['footer-menu__column-items']}>
                                    <a href="/privacy" className={styles['footer-menu__column-item']}>
                                        Privacy & Security
                                    </a>
                                    <a href="/customize" className={styles['footer-menu__column-item']}>
                                        Customization
                                    </a>
                                    <a href="/features#customize-widgets" className={styles['footer-menu__column-item']}>
                                        Widgets & Extensions
                                    </a>
                                </div>
                            </div>
                        )}

                        <div className={styles['footer-menu__column']}>
                            {isSonicClient && <p onClick={handleOpenMenu} className={styles['footer-menu__column-title']}>Help</p>}
                            {isAdblockClient && (
                                <a href="https://help.adblockplus.org/hc/en-us" rel="noopener noreferrer" target="_blank">
                                    <p className={styles['footer-menu__column-title']}>Help</p>
                                </a>
                            )}
                            <div className={styles['footer-menu__column-items']}>
                                <a href="/support#available-os" className={styles['footer-menu__column-item']}>
                                    Switch to {isSonicClient ? 'Sonic' : 'Adblock Browser'}
                                </a>
                                <a href="/support#faq" className={styles['footer-menu__column-item']}>
                                    FAQ
                                </a>
                            </div>
                        </div>

                        <div className={styles['footer-menu__column']}>
                            <p onClick={handleOpenMenu} className={styles['footer-menu__column-title']}>Company</p>
                            <div className={styles['footer-menu__column-items']}>
                                <a
                                    href={isSonicClient ? '/about' : 'https://accounts.adblockplus.org/en/about'}
                                    rel={isSonicClient ? '' : 'noopener noreferrer'}
                                    target={isSonicClient ? '_self' : '_blank'}
                                    className={styles['footer-menu__column-item']}
                                >
                                    About Us
                                </a>
                                <a href="/support#contact-us" className={styles['footer-menu__column-item']}>
                                    Contact Us
                                </a>
                            </div>
                        </div>

                        {isSonicClient ? (
                            <div className={clsx(styles['footer-menu__column'], styles['social'])}>
                                <p className={styles['footer-menu__column-title']}>Follow us</p>
                                <div className={styles['footer-menu__column-items']}>
                                    <div className={styles['footer-menu__column-item']}>
                                        <a href="https://x.com/sonic_browser" target="_blank" rel="noopener noreferrer" className={styles['social-links']}>
                                            <i className="fa-brands fa-x-twitter fa-l" />
                                        </a>
                                        <a href="https://www.youtube.com/channel/UCz65JFXtkXtVmqzw75CeKvw" target="_blank" rel="noopener noreferrer" className={styles['social-links']}>
                                            <i className="fa-brands fa-youtube fa-l" />
                                        </a>
                                        <a href="https://www.reddit.com/user/sonic_browser/" target="_blank" rel="noopener noreferrer" className={styles['social-links']}>
                                            <i className="fa-brands fa-reddit fa-l" />
                                        </a>
                                    </div>
                                    <div className={styles['footer-menu__column-item']}>
                                        <a href="https://www.tiktok.com/@sonicbrowser" target="_blank" rel="noopener noreferrer" className={styles['social-links']}>
                                            <i className="fa-brands fa-tiktok fa-l" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={clsx(styles['footer-menu__column'], styles['social'])}>
                                <p className={styles['footer-menu__column-title']}>Follow us</p>
                                <div className={styles['footer-menu__column-items']}>
                                    <div className={styles['footer-menu__column-item']}>
                                        <a href="https://www.facebook.com/adblockplus" target="_blank" rel="noopener noreferrer" className={styles['social-links']}>
                                            <i className="fa-brands fa-facebook-square fa-l" />
                                        </a>
                                        <a href="https://twitter.com/AdblockPlus" target="_blank" rel="noopener noreferrer" className={styles['social-links']}>
                                            <i className="fa-brands fa-twitter fa-l" />
                                        </a>
                                        <a href="https://www.youtube.com/user/AdblockPlusOfficial" target="_blank" rel="noopener noreferrer" className={styles['social-links']}>
                                            <i className="fa-brands fa-youtube fa-l" />
                                        </a>
                                        <a href="https://www.instagram.com/adblockplus/" target="_blank" rel="noopener noreferrer" className={clsx(styles['social-links'], styles['social-links--mobile'])}>
                                            <i className="fa-brands fa-instagram fa-l" />
                                        </a>
                                    </div>
                                    <div className={clsx(styles['footer-menu__column-item'], styles['footer-menu__column-item--desktop'])}>
                                        <a href="https://www.instagram.com/adblockplus/" target="_blank" rel="noopener noreferrer" className={styles['social-links']}>
                                            <i className="fa-brands fa-instagram fa-l" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div className={`${styles['pagefooter__boilerplate-body']} centerwrapper`}>
                    <CompanyLogo
                        classList={styles['pagefooter__boilerplate-logo']}
                        svgComponent={<Logo />}
                        width={137}
                        height={32}
                        link={(isSonicClient || isAdblockClient) ? '/' : 'https://www.tempest.com'}
                        clientName={clientName}
                    />

                    <ul className={styles['pagefooter__boilerplate-imprint']}>
                        {/** 🚨 Todo: not a beauty - Find a better way for this (e.g. `.map()`...): */}
                        {isTempestClient && (
                            <>
                                <li className={styles['pagefooter__boilerplate-item']}>
                                    <a href="https://www.tempest.com/privacy-policy" className={styles['pagefooter__boilerplate-link']}>
                                        {t(
                                            'homepage-shared-footer-privacy',
                                            'Privacy Policy',
                                        )}
                                    </a>
                                </li>
                                <li className={styles['pagefooter__boilerplate-item']}>
                                    <a href="https://www.tempest.com/terms-of-service" className={styles['pagefooter__boilerplate-link']}>
                                        {t(
                                            'homepage-shared-footer-terms',
                                            'Terms of Service',
                                        )}
                                    </a>
                                </li>
                            </>
                        )}
                        {(isSonicClient || isAdblockClient) && (
                            <>
                                {isSonicClient && (
                                    <li className={styles['pagefooter__boilerplate-item']}>
                                        <Link to="/cookie-policy" className={styles['pagefooter__boilerplate-link']}>
                                            {/* 🚨 Todo: implement translation string, if wanted.
                                          * I don't have access to lokalise anymore. */}
                                            Cookie Policy
                                        </Link>
                                    </li>
                                )}
                                <li className={styles['pagefooter__boilerplate-item']}>
                                    <Link to="/user-license-agreement" className={styles['pagefooter__boilerplate-link']}>
                                        {/* 🚨 Todo: implement translation string, if wanted.
                                          * I don't have access to lokalise anymore. */}
                                        User License Agreement
                                    </Link>
                                </li>
                                <li className={styles['pagefooter__boilerplate-item']}>
                                    <Link
                                        to="/privacy-policy"
                                        className={styles['pagefooter__boilerplate-link']}
                                    >
                                        {t(
                                            'homepage-shared-footer-privacy',
                                            'Privacy Policy',
                                        )}
                                    </Link>
                                </li>
                                <li className={styles['pagefooter__boilerplate-item']}>
                                    <Link
                                        to="/terms-of-use"
                                        className={styles['pagefooter__boilerplate-link']}
                                    >
                                        {/* 🚨 Todo: implement translation string, if wanted.
                                          * I don't have access to lokalise anymore. */}
                                        Terms of Use
                                    </Link>
                                </li>
                                <li className={styles['pagefooter__boilerplate-item']}>
                                    <Link to="/contact" className={styles['pagefooter__boilerplate-link']}>
                                        {/* 🚨 Todo: implement translation string, if wanted.
                                          * I don't have access to lokalise anymore. */}
                                        Contact
                                    </Link>
                                </li>
                                <li className={styles['pagefooter__boilerplate-item']}>
                                    <Link to="/support" className={styles['pagefooter__boilerplate-link']}>
                                        {/* 🚨 Todo: implement translation string, if wanted.
                                          * I don't have access to lokalise anymore. */}
                                        Support
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>

                    <small className={styles['pagefooter__imprint']}>{imprintLabel}</small>
                </div>
            </div>
        </footer>
    );
};

export default PageFooter;
