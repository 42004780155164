/**
 * # Client
 */

/**
 * ## Imports
 */
import { HttpClient } from './httpClient';

/**
 * ## Exports
 */
export const http = new HttpClient({
    baseURL: process.env.REACT_APP_HOUDINI_URL,
});

// eslint-disable-next-line no-promise-executor-return
export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
