/**
 * # Contact box
 */

/**
 * ## Imports
 */
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import ButtonCta from 'common/components/ButtonCta';
import { ClientName } from 'client/clientConfig';
import styles from './ContactBox.module.scss';

/**
 * ## Component
 */
type ContactBoxProps = {
    title?: string;
    text?: string;
    buttonLabel?: string;
    onClick?: () => void;
}
const ContactBox = ({ title, text, buttonLabel, onClick }: ContactBoxProps) => {
    const location = useLocation();
    const isSonicClient = ClientName === 'sonic';
    const siteName = isSonicClient ? 'Sonic' : 'Adblock Browser';

    /**
     * ### Handlers
     */
    const buttonClickHandler = (): void => {
        if (onClick) {
            onClick();
            return;
        }

        window.location.href = isSonicClient ? 'mailto:info@sonicbrowser.com' : 'mailto:support@adblockplus.org';
    };

    /**
     * ### Returned JSX
     */
    return (
        <section
            id="contact-us"
            className={
                clsx(
                    styles['wrapper'],
                    'centerwrapper follows-pageheader',
                    {
                        [styles['space-bottom']]: location.pathname === '/contact',
                    },
                )
            }
        >
            <h2 className={styles['headline']}>{title || `Contact ${siteName}`}</h2>
            <div className={styles['box']}>
                <p className={styles['copytext']}>{text || 'Reach out to us with any questions or suggestions via email.'}</p>
                <ButtonCta
                    clientName={ClientName}
                    label={buttonLabel || 'Get in touch'}
                    // eslint-disable-next-line
                    layoutBig={true}
                    // eslint-disable-next-line
                    layoutGlowBottom={true}
                    // eslint-disable-next-line
                    arrowIndicator={false}
                    onClick={buttonClickHandler}
                />
            </div>
        </section>
    );
};

export default ContactBox;
