import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToHash = () => {
    const location = useLocation();

    const scrollToSection = useCallback(() => {
        if (location?.hash) {
            const section = document?.querySelector(location?.hash);
            if (section) {
                const sectionTopPosition = section.getBoundingClientRect().top;
                const yOffset = -160;
                const yPosition = sectionTopPosition + window.scrollY + yOffset;
                window.scrollTo({ top: yPosition, behavior: 'smooth' });
            }
        }
    }, [location]);

    useEffect(() => {
        setTimeout(scrollToSection, 300);

        const handleLinkClick = (event) => {
            const target = event.target.closest('a[href*="#"]');
            if (target) {
                setTimeout(scrollToSection, 0);
            }
        };

        document.addEventListener('click', handleLinkClick);

        return () => {
            document.removeEventListener('click', handleLinkClick);
        };
    }, [scrollToSection]);
};

export default useScrollToHash;
