export function submitMetrics(data) {
    const { Category, Action, Object, ReferalSource, testPage } = data;

    const dataToSend = {
        Category,
        Action,
        Object,
        ReferalSource,
        Source: 'frontend',
        Origin: window.location.origin,
        testPage,
    };

    const jsonString = JSON.stringify(dataToSend);

    return navigator.sendBeacon(
        process.env.REACT_APP_ANALYTICS_PATH,
        jsonString,
    );
}
